import React, { useState } from "react";
import DefaultLayout from "../../../layouts/DefaultLayout";
import MainContentLayout from "../../../layouts/MainContentLayout";
import { useNavigate } from "react-router-dom";
import Path from "../../../route/Path";
import { getSalonDetail } from "../../../services/api/18";
import CommonDialog from "../../../components/CommonDialog";
import ManagementMenu from "../../../components/ManagementMenu";
import { MENUS } from "./const";
function SystemManagementTopPage() {
  const navigate = useNavigate();
  const [checkSalonRegister, setCheckSalonRegister] = useState(false);

  const handleCheckSalonRegister = () => {
    getSalonDetail().then((res) => {
      if (res.data._id) {
        setCheckSalonRegister(false);
        navigate(Path.systemManagementEdit(res.data._id));
      } else {
        setCheckSalonRegister(true);
      }
    });
  };

  const onNavigate = (path) => {
    if (path === Path.systemManagementEdit()) {
      handleCheckSalonRegister();
    } else {
      navigate(path);
    }
  };

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>システム</p>
          </>
        }
        onClick={() => navigate(Path.home)}
      >
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
          <ManagementMenu menus={MENUS} onNavigate={onNavigate} />
        </div>
        <CommonDialog
          open={checkSalonRegister}
          handleClose={() => setCheckSalonRegister(false)}
          title="店舗情報が登録されていません。"
          actions={
            <div className="w-full flex items-center justify-end text-white text-base font-semibold">
              <button
                onClick={() => navigate(Path.systemManagement)}
                type="button"
                className="button-size bg-gray-btn-secondary mr-4 lg:mr-6"
              >
                戻る
              </button>
              <button
                onClick={() => navigate(Path.systemManagementRegisterSalon)}
                type="button"
                className="button-size bg-blue-btn-primary"
              >
                登録
              </button>
            </div>
          }
        />
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default SystemManagementTopPage;
