import { GENDER } from "../../../constants";

const getCustomerGenderText = (gender) => {
  switch (gender) {
    case GENDER.MALE:
      return "男性";
    case GENDER.FEMALE:
      return "女性";
    default:
      return "未回答";
  }
};

export { getCustomerGenderText };
