import React from "react";
import DefaultLayout from "../../../layouts/DefaultLayout";
import MainContentLayout from "../../../layouts/MainContentLayout";
import { useNavigate } from "react-router-dom";
import Path from "../../../route/Path";
import { MENUS } from "./const";
import ManagementMenu from "../../../components/ManagementMenu";

function ScalpLabManagementTopPage() {
  const navigate = useNavigate();

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>頭皮ラボ</p>
          </>
        }
        onClick={() => navigate(Path.home)}
        containerClass="overflow-hidden"
      >
        <div className="w-full h-full pb-8 lg:pb-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
            <h2 className="text-base lg:text-xl font-semibold">
              頭皮ラボについて
            </h2>
          </div>

          <ManagementMenu menus={MENUS} />
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default ScalpLabManagementTopPage;
