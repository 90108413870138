import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import moment from "moment";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Path from "../../../route/Path";
import {
  createNewOrderKit,
  receiveNewOrderKit,
  updateInspectionKitAmount,
} from "../../../services/api/1C";
import { getSalonDetail } from "../../../services/api/18";
import GA, { EVENT_NAMES } from "../../../services/googleAnalytics";
import DefaultLayout from "../../../layouts/DefaultLayout";
import MainContentLayout from "../../../layouts/MainContentLayout";
import InputField from "../../../components/commonUI/InputField";
import CommonDialog from "../../../components/CommonDialog";
import LoadingProgress from "../../../components/commonUI/LoadingProgress";
import LoadInitError from "../../../components/commonUI/LoadInitError";
import NoData from "../../../components/commonUI/NoData";

const CONFIRM_STEPS = {
  CONFIRM: "CONFIRM",
  CONFIRM_AGAIN: "CONFIRM_AGAIN",
};

function OrderKits() {
  const navigate = useNavigate();
  const [orderAmount, setOrderAmount] = useState("");
  const [open, setOpen] = useState(false);
  const [isEditKitAmount, setIsEditKitAmount] = useState(false);
  const [showStep, setShowStep] = useState(CONFIRM_STEPS.CONFIRM);
  const [fetchAllKitOfScalpLab, setFetchAllKitOfScalpLab] = useState({
    isLoading: false,
    data: null,
    error: null,
  });
  const [isUpdatingStock, setIsUpdatingStock] = useState(false);

  const inspectionKitAmount =
    fetchAllKitOfScalpLab.data?.inspectionKit?.inStockAmount;

  const loadAllKitOfScalpLab = () => {
    setFetchAllKitOfScalpLab({
      isLoading: true,
      data: null,
      error: null,
    });

    getSalonDetail()
      .then((res) => {
        setFetchAllKitOfScalpLab({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchAllKitOfScalpLab({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  };

  const handleConfirm = () => {
    if (showStep === CONFIRM_STEPS.CONFIRM) {
      GA.tracking(EVENT_NAMES.ORDER_KIT.SUBMIT, {
        amount: values.orderAmount,
      });
      setShowStep(CONFIRM_STEPS.CONFIRM_AGAIN);
      handleSubmit();
    } else if (showStep === CONFIRM_STEPS.CONFIRM_AGAIN) {
      setOpen(false);
      setOrderAmount("");
      navigate(Path.scalpLabManagement);
    }
  };

  const validationSchema = Yup.object().shape({
    orderAmount: Yup.string().required("注文個数"),
  });

  const onSubmit = async (values) => {
    createNewOrderKit(values)
      .then((res) => {
        loadAllKitOfScalpLab();
      })
      .catch((e) => {
        alert(e);
      })
      .finally(() => {});
  };

  const registerNewOrderKit = async (values) => {
    const input = {
      orderAmount: values.orderAmount,
    };
    receiveNewOrderKit(values._id, input)
      .then((res) => {
        loadAllKitOfScalpLab();
      })
      .catch((e) => {
        alert(e);
      })
      .finally(() => {});
  };

  const {
    handleSubmit,
    errors,
    values,
    setFieldValue,
    touched,
    handleBlur,
  } = useFormik({
    initialValues: {
      orderAmount: orderAmount || 0,
    },
    onSubmit,
    validationSchema,
  });

  const changeAmountOnSubmit = async () => {
    setIsUpdatingStock(true);
    updateInspectionKitAmount(changeAmountValues.inspectionKitAmount)
      .then(({ data }) => {
        if (data) {
          toast.success("更新しました");
          setFetchAllKitOfScalpLab({
            ...fetchAllKitOfScalpLab,
            data: {
              ...fetchAllKitOfScalpLab.data,
              inspectionKit: {
                ...fetchAllKitOfScalpLab.data.inspectionKit,
                inStockAmount: changeAmountValues.inspectionKitAmount,
              },
            },
          });
        } else {
          toast.error("更新エラー");
        }
      })
      .catch(() => {
        toast.error("更新エラー");
      })
      .finally(() => {
        setIsUpdatingStock(false);
      });
  };

  const {
    handleSubmit: changeAmountHandleSubmit,
    errors: changeAmountErrors,
    values: changeAmountValues,
    setFieldValue: changeAmountSetFieldValue,
    touched: changeAmountTouched,
    handleBlur: changeAmountHandleBlur,
  } = useFormik({
    initialValues: {
      inspectionKitAmount: inspectionKitAmount || 0,
    },
    onSubmit: changeAmountOnSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    loadAllKitOfScalpLab();
  }, []);

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>頭皮ラボ</p>
          </>
        }
      >
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
            <h2 className="text-base lg:text-xl font-semibold">
              検査キット
            </h2>
          </div>

          <div className="w-full h-full py-3">
            <div className="w-full h-full pr-3 border border-gray-400 rounded-2xl p-4">
              <div className="w-full h-full overflow-auto">
                <div className="w-full h-full flex flex-col gap-6">
                  <div className="w-full h-2/3 flex gap-6">
                    <div className="w-1/2 flex justify-center items-center">
                      <div className="w-full lg:w-1/2 h-1/2 border-gray-800 border-4 rounded-lg relative">
                        <div className="absolute bg-gray-btn-secondary text-sm font-semibold text-white rounded-full px-4 py-2 top-[-8%] left-[-2%] lg:left-[-12%]">
                          現在の在庫数
                        </div>
                        <div className="absolute text-sm font-semibold text-white top-[5%] right-[5%]">
                          <FontAwesomeIcon
                            icon={faEdit}
                            onClick={() => {
                              if (
                                isEditKitAmount &&
                                changeAmountValues?.inspectionKitAmount !==
                                  fetchAllKitOfScalpLab.data
                                    ?.inspectionKit?.inStockAmount
                              ) {
                                changeAmountHandleSubmit();
                              }
                              setIsEditKitAmount(!isEditKitAmount);
                            }}
                            className="text-black !w-5 !h-5 hover:text-[#6670C8] cursor-pointer"
                          />
                        </div>
                        <div className=" h-full justify-center items-center">
                          {fetchAllKitOfScalpLab.isLoading ? (
                            <LoadingProgress />
                          ) : null}

                          {!fetchAllKitOfScalpLab.isLoading &&
                          fetchAllKitOfScalpLab.error ? (
                            <LoadInitError
                              error={fetchAllKitOfScalpLab.error}
                            />
                          ) : null}
                          {!fetchAllKitOfScalpLab.isLoading &&
                          !fetchAllKitOfScalpLab.error ? (
                            <div className="flex h-full justify-center items-center">
                              {isEditKitAmount ? (
                                <div className="!w-3/5 h-full flex justify-center items-center">
                                  <InputField
                                    name="inspectionKitAmount"
                                    value={
                                      changeAmountValues.inspectionKitAmount
                                    }
                                    onChange={(value) => {
                                      changeAmountSetFieldValue(
                                        "inspectionKitAmount",
                                        Number(
                                          value.replace(
                                            /[^0-9.]/g,
                                            ""
                                          )
                                        )
                                      );
                                    }}
                                    handleBlur={
                                      changeAmountHandleBlur
                                    }
                                    touched={changeAmountTouched}
                                    errors={
                                      changeAmountErrors.inspectionKitAmount
                                    }
                                    inputClasses="h-[50px] font-bold !text-4xl text-center"
                                  />
                                </div>
                              ) : (
                                <div className="font-bold text-4xl">
                                  {!isUpdatingStock &&
                                    fetchAllKitOfScalpLab.data
                                      ?.inspectionKit?.inStockAmount}
                                </div>
                              )}
                            </div>
                          ) : (
                            <NoData />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="w-1/2 flex flex-col justify-center items-center">
                      <form>
                        <div className="flex items-center gap-2">
                          <InputField
                            name="orderAmount"
                            value={values.orderAmount}
                            type="text"
                            onChange={(newValue) => {
                              setFieldValue(
                                "orderAmount",
                                Number(
                                  newValue.replace(/[^0-9.]/g, "")
                                )
                              );
                              setOrderAmount(newValue);
                            }}
                            handleBlur={handleBlur}
                            touched={touched.orderAmount}
                            errors={errors.orderAmount}
                            labelClasses="!w-24"
                          />
                          <div className="font-semibold text-base">
                            個
                          </div>
                        </div>
                        <div className="flex justify-end mt-4">
                          <button
                            onClick={() => {
                              if (Number(orderAmount) <= 0) {
                                toast.error(
                                  "０以上の数字を入力してください"
                                );
                              } else {
                                setOpen(true);
                              }
                            }}
                            type="button"
                            className="button-size bg-blue-btn-primary"
                            disabled={
                              orderAmount === "" ? true : false
                            }
                          >
                            発注する
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="w-full h-1/3 flex gap-6">
                    <div className="w-full h-1/12">
                      <div className="w-full h-full relative min-w-[900px]">
                        {/* header */}
                        <div className="sticky top-0 left-0 w-full h-14 flex justify-center bg-gray-100 border-b z-10">
                          <div className="w-1/6 flex text-sm items-center justify-center shrink-0 truncate">
                            発注日時
                          </div>
                          <div className="w-1/6 flex text-sm items-center justify-center shrink-0 truncate">
                            検体数
                          </div>
                          <div className="w-1/6 flex text-sm items-center justify-center shrink-0 truncate">
                            発注状況
                          </div>
                          <div className="w-1/6 flex text-sm items-center justify-center shrink-0 truncate">
                            発送日時
                          </div>
                          <div className="w-1/6 flex text-sm items-center justify-center shrink-0 truncate"></div>
                        </div>

                        {/* body */}
                        {fetchAllKitOfScalpLab?.data?.inspectionKitSalonOrders?.map(
                          (row, index) => (
                            // bg-blue-100
                            <div
                              className={`w-full h-14 flex justify-center 
                              ${
                                row.orderStatus === "waiting"
                                  ? "bg-red-100"
                                  : row.orderStatus === "confirmed"
                                  ? "bg-yellow-100"
                                  : row.orderStatus === "delivering"
                                  ? "bg-green-100"
                                  : row.orderStatus === "completed"
                                  ? "bg-blue-100"
                                  : "bg-[#F0F1EF] bg-opacity-50"
                              } 
                              border-b`}
                            >
                              <div className="w-1/6 flex text-sm items-center justify-center">
                                {row.orderDate
                                  ? moment(row.orderDate).format(
                                      "YYYY-MM-DD HH:mm"
                                    )
                                  : ""}
                              </div>
                              <div className="w-1/6 flex text-sm items-center justify-center">
                                {row.orderAmount}
                              </div>
                              <div className="w-1/6 flex text-sm items-center justify-center">
                                {row.orderStatus === "waiting"
                                  ? "頭皮ラボ承認待ち"
                                  : row.orderStatus === "confirmed"
                                  ? "頭皮ラボ承認済み"
                                  : row.orderStatus === "delivering"
                                  ? "頭皮ラボ発送済み"
                                  : row.orderStatus === "completed"
                                  ? "在庫登録済み"
                                  : "未知"}
                              </div>
                              <div className="w-1/6 flex text-sm items-center justify-center">
                                {row.deliveryDate
                                  ? moment(row.deliveryDate).format(
                                      "YYYY-MM-DD HH:mm"
                                    )
                                  : ""}
                              </div>
                              <div className="w-1/6 flex text-sm items-center justify-center">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  disabled={
                                    row.orderStatus !== "delivering"
                                  }
                                  onClick={() => {
                                    registerNewOrderKit(row);
                                  }}
                                >
                                  在庫に登録
                                </Button>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
            <Link
              to={Path.scalpLabManagement}
              className="button-size bg-gray-btn-secondary"
            >
              戻る
            </Link>
          </div>
        </div>
        <CommonDialog
          open={open}
          handleClose={() => {
            if (!CONFIRM_STEPS.CONFIRM_AGAIN) {
              setOpen(false);
            }
            setOpen(false);
          }}
          title={
            showStep === CONFIRM_STEPS.CONFIRM
              ? "検査キットを発注しますか？"
              : ""
          }
          actions={
            <div className="w-full flex gap-6 items-center justify-end text-white text-base font-semibold">
              <button
                onClick={handleConfirm}
                type="button"
                className="button-size bg-blue-btn-primary"
              >
                {showStep === CONFIRM_STEPS.CONFIRM ? "発注" : "終了"}
              </button>
            </div>
          }
        >
          <div className="flex justify-center text-lg font-semibold">
            {showStep === CONFIRM_STEPS.CONFIRM
              ? `${orderAmount} 個`
              : "検査キットを発注ました"}
          </div>
        </CommonDialog>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default OrderKits;
