import { useMemo } from "react";
import {
  IMAGE_URL_BY_CLUSTER,
  THUMB_BACKGROUND_BY_CLUSTER,
} from "../constants";
import TypesReferenceInfoItem from "./Item";
import { TYPE_DATAS } from "./constants";

const TypesReferenceInfo = ({ type, factorCluster, isTablet }) => {
  const typeData = TYPE_DATAS.filter(
    (item) => item.typeName === type
  );

  const { imageUrl, thumbBackground } = useMemo(() => {
    const imageUrl = IMAGE_URL_BY_CLUSTER[factorCluster];
    const thumbBackground =
      THUMB_BACKGROUND_BY_CLUSTER[factorCluster];
    return { imageUrl, thumbBackground };
  }, [factorCluster]);

  if (isTablet) {
    return (
      <div className="w-full h-auto flex flex-col">
        {typeData.map((item, index) => (
          <TypesReferenceInfoItem
            key={index}
            typeName={item.typeName}
            title={item.title}
            description={item.description}
            backgroundClass={item.backgroundClass}
            isTablet
            imageUrl={imageUrl}
            thumbBackground={thumbBackground}
          />
        ))}
      </div>
    );
  }
  return (
    <div className="mb-4">
      <div className="w-full font-extrabold self-start text-[20px] min-[400px]:text-[22px] min-[500px]:text-[28px] min-[630px]:text-[32px] mb-4">
        TYPE
      </div>
      <div className="w-full h-auto flex flex-col gap-1 ">
        {typeData.map((item, index) => (
          <TypesReferenceInfoItem
            key={index}
            typeName={item.typeName}
            title={item.title}
            description={item.description}
            backgroundClass={item.backgroundClass}
          />
        ))}
      </div>
    </div>
  );
};

export default TypesReferenceInfo;
