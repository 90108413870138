import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import {
  faTrashCan,
  faEdit,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Path from "../../../route/Path";
import {
  deleteInventory,
  getInventoryAll,
  updateInventory,
  updateProduct,
} from "../../../services/api/18";
import usePagination from "../../../hooks/usePagination";
import ConfirmDeleteDialog from "../../../components/CommonDialog/ConfirmDeleteDialog";
import DefaultLayout from "../../../layouts/DefaultLayout";
import MainContentLayout from "../../../layouts/MainContentLayout";
import NoData from "../../../components/commonUI/NoData";
import Pagination from "../../../components/common/Pagination";
import LoadingProgress from "../../../components/commonUI/LoadingProgress";
import LoadInitError from "../../../components/commonUI/LoadInitError";
import { handleClassesToText } from "./helpers";

function InventoryManagementTopPage() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [load, setLoad] = useState(false);
  const [name, setName] = useState("");
  const [nameSearch, setNameSearch] = useState("");
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [productItem, setProductItem] = useState("");
  const [updateData, setUpdateData] = useState([]);
  const [fetchInventory, setFetchInventory] = useState({
    isLoading: false,
    data: null,
    error: null,
  });
  const [isShowUpdateButton, setIsShowUpdateButton] = useState(false);

  const { data, totalPage, changeCurrentPage } = usePagination({
    totalData: fetchInventory.data,
  });

  const loadInventory = () => {
    setFetchInventory({
      isLoading: true,
      data: null,
      error: null,
    });

    getInventoryAll(name)
      .then((res) => {
        setFetchInventory({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchInventory({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  };

  const handleShowConfirmDelete = (productItem) => {
    setShowConfirmDelete(true);
    setProductItem(productItem);
  };

  const handleDeleteProductItem = (deleteData) => {
    deleteInventory(deleteData.product._id)
      .then((res) => {
        toast.success("削除成功");
        setLoad(!load);
        setShowConfirmDelete(false);
      })
      .catch((error) => {
        toast.error("削除が成功しない");
      });
  };

  const handleOpenUpdate = (index, productItem) => {
    setProductItem(productItem);
    setOpen(!open);
  };

  const handleUpdate = () => {
    updateData.forEach((data) => {
      if (data?.inventory) {
        updateInventory(data.id, data.inventory);
      }
      if (data?.description) {
        updateProduct(data.id, {
          description: data.description,
        });
      }
    });
    setOpen(false);
    setIsShowUpdateButton(false);
    setLoad(!load);
  };

  const handleChangeProduct = (index, productData, id) => {
    const newUpdateData = [...updateData];
    newUpdateData[index] = {
      ...newUpdateData[index],
      id: id,
    };
    if (productData.inventory) {
      newUpdateData[index].inventory = Number(productData.inventory);
    }
    if (productData.description) {
      newUpdateData[index].description = productData.description;
    }
    setUpdateData(newUpdateData);
  };

  useEffect(() => {
    loadInventory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load, name]);

  useEffect(() => {
    if (updateData.length > 0) {
      setIsShowUpdateButton(true);
    }
  }, [updateData]);

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>在庫</p>
          </>
        }
      >
        <div className="w-full h-full pt-20 lg:py-12 relative">
          <div className="w-full h-20 lg:h-12 pl-20 lg:pl-0 flex items-center justify-between absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
            <h2 className="w-24 text-base lg:text-xl font-semibold">
              在庫一覧
            </h2>
            <div className="flex flex-col md:flex md:flex-row gap-2 mr-2">
              <button
                onClick={() =>
                  navigate(Path.inventoryManagementCheckStock)
                }
                className="w-28 h-8 flex items-center justify-center text-white text-base font-semibold rounded bg-blue-btn-primary "
              >
                在庫チェック
              </button>
              {/* <button
                className="w-28 h-8 flex items-center justify-center text-white text-base font-semibold rounded bg-blue-btn-primary "
                onClick={() =>
                  navigate(Path.inventoryManagementSearch)
                }
              >
                履歴
              </button> */}
            </div>
          </div>
          <div className="flex items-center gap-2 mt-2">
            <div className="flex items-center border border-black rounded-md">
              <button
                type="button"
                className="button-size text-black hover:bg-blue-btn-primary hover:text-white"
                onClick={() =>
                  navigate(Path.inventoryManagementRegister)
                }
              >
                <div>
                  <FontAwesomeIcon icon={faPlus} />
                </div>
                <div className="pl-2">登録</div>
              </button>
            </div>
            <div>
              <div className="lg:w-[460px] h-8 flex items-center justify-between">
                <input
                  value={nameSearch}
                  onChange={(e) => setNameSearch(e.target.value)}
                  className=" w-full h-full px-4 py-1 border border-gray-300 focus:shadow-md focus:border-gray-500 rounded mr-4"
                />
                <button
                  onClick={() => setName(nameSearch)}
                  className=" button-size break-keep bg-blue-btn-primary "
                >
                  フィルター
                </button>
              </div>
            </div>
          </div>
          <div className="w-full h-[calc(90%-40px)] sm:h-[90%] py-3">
            <div className="w-full h-full pr-3 border border-gray-400 rounded-2xl p-4">
              <div className="w-full h-full overflow-auto">
                {fetchInventory.isLoading ? (
                  <LoadingProgress />
                ) : null}
                {!fetchInventory.isLoading && fetchInventory.error ? (
                  <LoadInitError error={fetchInventory.error} />
                ) : null}
                {!fetchInventory.isLoading &&
                !fetchInventory.error ? (
                  Array.isArray(fetchInventory.data) &&
                  fetchInventory.data.length > 0 ? (
                    <div className="w-full h-full relative overflow-auto min-w-[900px]">
                      <ConfirmDeleteDialog
                        open={showConfirmDelete}
                        handleClose={() =>
                          setShowConfirmDelete(false)
                        }
                        confirmTitle="在庫情報を削除しますか？"
                        confirmMessage=""
                        confirmTitleAgain="本当に在庫情報を削除しますか?"
                        confirmMessageAgain=""
                        deleteCompleteTitle="在庫情報を削除しました"
                        deleteCompleteBtnLabel="完了"
                        deleteCompleteAction={() => {
                          setShowConfirmDelete(false);
                        }}
                        deleteAction={() => {
                          handleDeleteProductItem(productItem);
                        }}
                        confirmCancleLabel="キャンセル"
                        confirmCancleAgainLabel="キャンセル"
                        confirmLabel="削除する"
                        confirmAgainLabel="削除する"
                      />
                      {/* header */}
                      <div className="sticky top-0 left-0 w-full h-14 flex bg-gray-100 border-b z-10">
                        <div className="w-[14%] flex items-center justify-center shrink-0 truncate">
                          品番
                        </div>
                        <div className="w-[12%] flex items-center justify-center shrink-0 truncate">
                          メーカー
                        </div>
                        <div className="w-[10%] flex items-center justify-center shrink-0 truncate">
                          シリーズ
                        </div>
                        <div className="w-[12%] flex items-center justify-center shrink-0 truncate">
                          品名
                        </div>
                        <div className="w-[8%] flex items-center justify-center shrink-0 truncate">
                          サイズ
                        </div>
                        {/* <div className="w-[8%] flex items-center justify-center shrink-0 truncate">
                          税込価格
                        </div> */}
                        <div className="w-[10%] flex items-center justify-center shrink-0 truncate">
                          クラス
                        </div>
                        <div className="w-[13%] flex items-center justify-center shrink-0 truncate">
                          コメント
                        </div>
                        <div className="w-[8%] flex items-center justify-center shrink-0 truncate">
                          在庫数
                        </div>
                        <div className="w-[10%] flex items-center justify-center shrink-0 truncate"></div>
                        <div className="w-[3%] flex items-center justify-center shrink-0 truncate"></div>
                      </div>
                      {/* body */}
                      {data.map((row, index) => (
                        <div
                          key={index}
                          className="w-full h-16 flex bg-[#F0F1EF] bg-opacity-50 border-b"
                        >
                          <div className="w-[14%] flex items-center justify-center">
                            {row.product.productNumber}
                          </div>
                          <div className="w-[12%] flex items-center justify-center">
                            {row.product.maker}
                          </div>
                          <div className="w-[10%] flex items-center justify-center">
                            {row.product.series}
                          </div>
                          <div className="w-[12%] flex items-center text-[14px]">
                            <p className="line-clamp-3">
                              {row.product?.name}
                            </p>
                          </div>
                          <div className="w-[8%] flex items-center justify-center">
                            {row.product?.size}
                          </div>
                          {/* <div className="w-[8%] flex items-center justify-center">
                            {row.product?.priceTaxInc}
                          </div> */}
                          <div className="w-[10%] flex items-center justify-center">
                            {handleClassesToText(
                              row.product?.classes
                            )}
                          </div>
                          <div className="w-[13%] flex items-center text-[14px]">
                            {productItem._id === row._id && open ? (
                              <textarea
                                key={row._id}
                                placeholder={`${row.product.description}`}
                                value={
                                  updateData[index]?.description ||
                                  row.product.description
                                }
                                onChange={(e) =>
                                  handleChangeProduct(
                                    index,
                                    {
                                      description:
                                        e.currentTarget.value,
                                    },
                                    row.product._id
                                  )
                                }
                                type="text"
                                className="w-full h-[75%] p-1 border rounded-md"
                              />
                            ) : (
                              <p className="line-clamp-3">
                                {updateData[index]?.description ||
                                  row.product.description}
                              </p>
                            )}
                          </div>
                          <div className="w-[8%] flex items-center justify-center">
                            {productItem._id === row._id && open ? (
                              <input
                                key={row._id}
                                placeholder={`${row.inventory}`}
                                value={updateData[index]?.inventory}
                                onChange={(e) =>
                                  handleChangeProduct(
                                    index,
                                    {
                                      inventory:
                                        e.currentTarget.value.replace(
                                          /[^0-9.]/g,
                                          ""
                                        ),
                                    },
                                    row.product._id
                                  )
                                }
                                type="text"
                                className="w-14 p-2 h-[75%] text-center border rounded-md"
                              />
                            ) : (
                              updateData[index]?.inventory ||
                              row.inventory
                            )}
                          </div>
                          <div className="w-[10%] flex items-end justify-center">
                            <Button
                              onClick={() => {
                                handleOpenUpdate(index, row);
                              }}
                              className=" !text-black "
                            >
                              <FontAwesomeIcon
                                icon={faEdit}
                                className="!w-5 !h-5"
                              />
                            </Button>
                            <Button
                              onClick={() =>
                                handleShowConfirmDelete(row)
                              }
                              className="!text-red-500"
                            >
                              <FontAwesomeIcon
                                icon={faTrashCan}
                                className="!w-5 !h-5"
                              />
                            </Button>
                          </div>
                          <div className="w-[3%] flex items-end justify-center" />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <NoData />
                  )
                ) : null}
              </div>
            </div>
          </div>

          <div className="w-full h-8 flex flex-col gap-1 items-center justify-between text-white text-base font-semibold absolute inset-x-0 bottom-auto sm:flex-row sm:bottom-0">
            <Pagination
              className="self-start"
              totalPage={totalPage}
              onPageChange={changeCurrentPage}
            />
            <div className="self-end flex gap-4">
              <button
                onClick={() => navigate(Path.home)}
                className="button-size bg-gray-btn-secondary"
              >
                戻る
              </button>
              {isShowUpdateButton && (
                <button
                  onClick={handleUpdate}
                  className="button-size bg-blue-btn-primary"
                  // disabled={open}
                >
                  変更する
                </button>
              )}
            </div>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default InventoryManagementTopPage;
