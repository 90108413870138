import React, { useRef } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import InputField from "app/components/commonUI/InputField";
import RadioComponent from "./RadioComponent";
import validationSchema from "./validator";
import {
  PRODUCT_SCOPE,
  PRODUCT_CATEGORY,
  PRODUCT_CLASSES,
} from "../../../constants";
import SurveyButton from "../../../components/Customer1StImpressionAndFactCheckPage/SurveyButton";
import BarcodeScannerModal from "../../1C/OrderScalpLab/BarcodeScannerModal";
import { uploadProductImage } from "../../../services/api/18";

function CreateInventoryForm({
  isReadMode,
  handleCreate,
  confirmMenuData,
  handleBackClick,
}) {
  const { errors, values, touched, setFieldValue, handleBlur, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        productNumber: "",
        maker: "",
        series: "",
        name: "",
        size: "",
        imageUrl: "",
        category: "",
        productScope: "",
        price: "",
        priceTaxInc: "",
        priceTaxExc: "",
        description: "",
        classes: [],
        inventory: "",
      },
      onSubmit: confirmMenuData,
      validationSchema,
    });

  const fileInputRef = useRef(null);

  const handleCodeDetected = (value) => {
    setFieldValue("productNumber", value);
  };

  return (
    <form className="w-full h-full overflow-hidden">
      <div className="w-full h-full flex flex-col gap-6 border border-gray-400 rounded-2xl p-4 overflow-auto">
        <div className="w-full h-[200px] flex justify-center">
          <div className="w-[200px] h-[200px] flex justify-center border border-black rounded-md  hover:border-blue-300 hover:bg-blue-500 hover:bg-opacity-10 overflow-hidden">
            <button
              type="button"
              className="w-[100%] h-[100%]"
              onClick={() => fileInputRef.current.click()}
            >
              {values.imageUrl ? (
                <img
                  className=" w-[100%] h-[100%] object-cover"
                  src={values.imageUrl}
                  alt="product-image"
                />
              ) : (
                <span> 写真を選択</span>
              )}
            </button>
            <input
              id="fileInput"
              type="file"
              accept="image/jpeg, image/png"
              className="absolute -top-full opacity-0"
              aria-hidden="true"
              tabIndex="-1"
              ref={fileInputRef}
              onChange={async (event) => {
                const selectFile = event.target.files[0];
                if (selectFile) {
                  const res = await uploadProductImage(selectFile);
                  if (res.error) {
                    toast.error(
                      res.error?.errorCode === "E900.9.1"
                        ? "無効なファイルタイプです。許可されているタイプは：jpg、jpeg、pngです。"
                        : "失敗"
                    );
                  } else {
                    setFieldValue("imageUrl", res.result.imageUrl);
                  }
                }
              }}
            />
          </div>
        </div>
        <div className="flex">
          <InputField
            label="品番"
            name="productNumber"
            value={values.productNumber}
            type="text"
            onChange={(newValue) => setFieldValue("productNumber", newValue)}
            handleBlur={handleBlur}
            touched={touched.productNumber}
            errors={errors.productNumber}
            disabled={isReadMode}
            labelClasses="!w-[80px] md:!w-[100px] lg:!w-[130px]"
          />
          <BarcodeScannerModal
            onCodeDetected={handleCodeDetected}
          ></BarcodeScannerModal>
        </div>
        <InputField
          label="メーカー"
          name="maker"
          values={values.maker}
          type="text"
          onChange={(newValue) => setFieldValue("maker", newValue)}
          handleBlur={handleBlur}
          touched={touched.maker}
          errors={errors.maker}
          disabled={isReadMode}
          labelClasses="!w-[80px] md:!w-[100px] lg:!w-[130px]"
        />
        <InputField
          label="シリーズ"
          name="series"
          values={values.series}
          type="text"
          onChange={(newValue) => setFieldValue("series", newValue)}
          handleBlur={handleBlur}
          touched={touched.series}
          errors={errors.series}
          disabled={isReadMode}
          labelClasses="!w-[80px] md:!w-[100px] lg:!w-[130px]"
        />
        <InputField
          label="品名"
          name="name"
          values={values.name}
          type="text"
          onChange={(newValue) => setFieldValue("name", newValue)}
          handleBlur={handleBlur}
          touched={touched.name}
          errors={errors.name}
          disabled={isReadMode}
          labelClasses="!w-[80px] md:!w-[100px] lg:!w-[130px]"
        />
        <InputField
          label="サイズ"
          name="size"
          values={values.size}
          type="text"
          onChange={(newValue) => setFieldValue("size", newValue)}
          handleBlur={handleBlur}
          touched={touched.size}
          errors={errors.size}
          disabled={isReadMode}
          labelClasses="!w-[80px] md:!w-[100px] lg:!w-[130px]"
        />
        <div className="flex">
          <label className="block shrink-0 font-semibold leading-9 !w-[80px] md:!w-[100px] lg:!w-[130px]">
            カテゴリ
          </label>
          <RadioComponent
            value={values.category}
            handleSelect={(newValue) => setFieldValue("category", newValue)}
            errors={errors.category}
            touched={touched.category}
            options={[
              {
                value: PRODUCT_CATEGORY.SHAMPOO,
                label: "シャンプー",
              },
              {
                value: PRODUCT_CATEGORY.TREATMENT,
                label: "処理",
              },
              {
                value: PRODUCT_CATEGORY.RINSE,
                label: "リンス",
              },
              {
                value: PRODUCT_CATEGORY.OTHER,
                label: "他の",
              },
            ]}
          />
        </div>
        <div className="flex">
          <label className="block shrink-0 font-semibold leading-9 !w-[80px] md:!w-[100px] lg:!w-[130px]">
            クラス
          </label>
          <div className="flex gap-2">
            {PRODUCT_CLASSES.map((item) => (
              <SurveyButton
                key={item.text}
                text={item.text}
                name={item.text}
                active={values.classes.includes(item.value)}
                handleClick={() => {
                  const preClasses = values.classes;
                  if (preClasses.includes(item.value)) {
                    const nextClasses = preClasses.filter(
                      (cl) => cl !== item.value
                    );
                    setFieldValue("classes", nextClasses);
                  } else {
                    setFieldValue("classes", [...preClasses, item.value]);
                  }
                }}
              />
            ))}
          </div>
        </div>
        <InputField
          label="説明"
          name="description"
          values={values.description}
          type="text"
          onChange={(newValue) => setFieldValue("description", newValue)}
          handleBlur={handleBlur}
          touched={touched.description}
          errors={errors.description}
          disabled={isReadMode}
          labelClasses="!w-[80px] md:!w-[100px] lg:!w-[130px]"
        />
        <InputField
          label="価格"
          name="price"
          value={values.price}
          type="number"
          onChange={(newValue) => {
            setFieldValue(
              "price",
              newValue === "" ? null : Number(newValue.replace(/[^0-9.]/g, ""))
            );
          }}
          handleBlur={handleBlur}
          touched={touched.price}
          errors={errors.price}
          disabled={isReadMode}
          labelClasses="!w-[80px] md:!w-[100px] lg:!w-[130px]"
        />
        <InputField
          label="未税価格"
          name="priceTaxExc"
          value={values.priceTaxExc}
          type="number"
          onChange={(newValue) =>
            setFieldValue(
              "priceTaxExc",
              newValue === "" ? null : Number(newValue.replace(/[^0-9.]/g, ""))
            )
          }
          handleBlur={handleBlur}
          touched={touched.priceTaxExc}
          errors={errors.priceTaxExc}
          disabled={isReadMode}
          labelClasses="!w-[80px] md:!w-[100px] lg:!w-[130px]"
        />
        <InputField
          label="税込価格"
          name="priceTaxInc"
          value={values.priceTaxInc}
          type="number"
          min="0"
          onChange={(newValue) => {
            setFieldValue(
              "priceTaxInc",
              newValue === "" ? null : Number(newValue.replace(/[^0-9.]/g, ""))
            );
          }}
          handleBlur={handleBlur}
          touched={touched.priceTaxInc}
          errors={errors.priceTaxInc}
          disabled={isReadMode}
          labelClasses="!w-[80px] md:!w-[100px] lg:!w-[130px]"
        />
        <InputField
          label="在庫数"
          name="inventory"
          value={values.inventory}
          type="text"
          onChange={(newValue) => {
            setFieldValue(
              "inventory",
              Number(newValue.replace(/[^0-9.]/g, ""))
            );
          }}
          handleBlur={handleBlur}
          touched={touched.inventory}
          errors={errors.inventory}
          disabled={isReadMode}
          labelClasses="!w-[80px] md:!w-[100px] lg:!w-[130px]"
        />
        <div className="flex">
          <label className="block shrink-0 font-semibold leading-9 !w-[80px] md:!w-[100px] lg:!w-[130px]">
            専売品
          </label>
          <RadioComponent
            value={values.productScope}
            handleSelect={(newValue) => setFieldValue("productScope", newValue)}
            errors={errors.productScope}
            touched={touched.productScope}
            options={[
              {
                value: PRODUCT_SCOPE.MONOPOLY,
                label: "登録する",
              },
              {
                value: PRODUCT_SCOPE.GENERAL,
                label: "登録しない",
              },
            ]}
          />
        </div>
      </div>

      <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
        <button
          onClick={handleBackClick}
          type="button"
          className="button-size bg-gray-btn-secondary mr-4 lg:mr-6"
        >
          終了
        </button>
        <button
          onClick={() => {
            if (handleCreate) {
              handleCreate();
            } else {
              handleSubmit();
            }
          }}
          type="button"
          className="button-size bg-blue-btn-primary"
        >
          登録
        </button>
      </div>
    </form>
  );
}

export default CreateInventoryForm;
