import Path from "../../../route/Path";
import { EVENT_NAMES } from "../../../services/googleAnalytics";

export const MENUS = [
  {
    title: "店舗情報",
    children: [
      {
        title: "確認",
        path: Path.systemManagementEdit(),
        eventName: EVENT_NAMES.SYSTEM_MANAGEMENT.CLICK_SYSTEM_EDIT,
      },
      {
        title: "スタッフ情報",
        path: Path.staffManagementList,
        eventName: EVENT_NAMES.SYSTEM_MANAGEMENT.CLICK_STAFF_LIST,
      },
    ],
  },
  {
    title: "メニュー",
    children: [
      {
        title: "確認",
        path: Path.systemManagementAllMenu,
        eventName: EVENT_NAMES.SYSTEM_MANAGEMENT.CLICK_MENU_LIST,
      },
      {
        title: "登録",
        path: Path.systemManagementRegisterMenu,
        eventName: EVENT_NAMES.SYSTEM_MANAGEMENT.CLICK_MENU_REGISTER,
      },
    ],
  },
  {
    title: "アクセス権",
    children: [
      {
        title: "設定",
        path: Path.systemManagementSettingAccessRight,
        eventName: EVENT_NAMES.SYSTEM_MANAGEMENT.CLICK_ACCESS,
      },
    ],
  },
  // {
  //   title: "レジ承認",
  //   children: [
  //     {
  //       title: "設定",
  //       path: Path.systemManagementConnectDevice,
  //     },
  //   ],
  // },
];
