import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { Switch } from "@mui/material";

import LoadingProgress from "../../../components/commonUI/LoadingProgress";
import LoadInitError from "../../../components/commonUI/LoadInitError";
import CommonDialog from "../../../components/CommonDialog";
import CreateCompleteDialog from "../../../components/CommonDialog/CreateCompleteDialog";
import {
  createAccessRightForStaff,
  getAccessRight,
} from "../../../services/api/1D";
import GA, { EVENT_NAMES } from "../../../services/googleAnalytics";

function SettingItem(props) {
  const { setting, handleChangeSetting } = props;

  const handleSwitch = (event) => {
    handleChangeSetting({
      ...setting,
      canAccess: event.target.checked,
    });
  };

  return (
    <div className="w-full h-auto rounded-md bg-slate-100 mb-2">
      <div className="w-full h-10 flex items-center justify-between px-3">
        <div className="font-semibold">{setting.functionName}</div>

        <Switch checked={setting.canAccess} onChange={handleSwitch} />
      </div>
    </div>
  );
}

function HasChildrensSettingItem(props) {
  const { setting, handleChangeSetting } = props;
  const [expand, setExpand] = useState(false);

  const handleChangeSubSetting = (functionCode, value) => {
    const newChildrens = [...setting.childrens];
    const changeSubSettingIndex = newChildrens.findIndex(
      (item) => item.functionCode === functionCode
    );

    if (changeSubSettingIndex >= 0) {
      newChildrens[changeSubSettingIndex] = {
        ...newChildrens[changeSubSettingIndex],
        canAccess: value,
      };
      const newSetting = {
        ...setting,
        childrens: newChildrens,
      };
      handleChangeSetting(newSetting);
    }
  };

  return (
    <div className="w-full h-auto rounded-md bg-slate-100 mb-2">
      <div className="w-full h-10 flex items-center justify-between px-3">
        <div className="font-semibold">{setting.functionName}</div>

        <button
          className={`w-8 h-8 flex items-center justify-center transition-all ${
            expand ? "rotate-180" : ""
          }`}
          onClick={() => setExpand((oldState) => !oldState)}
        >
          <FontAwesomeIcon
            icon={faAngleDown}
            size="xl"
            color="gray"
          />
        </button>
      </div>

      <div
        className={`w-full pl-6 overflow-hidden transition-all ${
          expand ? "h-min" : "h-0"
        }`}
        style={{
          height: expand ? 40 * setting.childrens.length : 0,
        }}
      >
        {setting.childrens.map((subSetting) => (
          <div
            key={subSetting.functionCode}
            className="w-full h-10 flex items-center justify-between px-4"
          >
            <div>{subSetting.functionName}</div>

            <Switch
              checked={subSetting.canAccess}
              onChange={(event) => {
                handleChangeSubSetting(
                  subSetting.functionCode,
                  event.target.checked
                );
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

function StaffAccessSettingForm({
  staffId,
  initSetting,
  handleBack,
  staffName,
}) {
  const HIDDEN_PERMISSIONS = [
    "予約管理機能",
    "売上管理",
    "レジ機能",
    "勤怠管理機能",
    "DM配信配",
  ];
  const [updateSetting, setUpdateSetting] = useState(
    initSetting.filter(
      (item) => !HIDDEN_PERMISSIONS.includes(item.functionName)
    )
  );
  const [saveChangeStatus, setSaveChangeStatus] = useState("idle");

  const handleChangeSetting = (index, newSettingItem) => {
    const newUpdateSetting = [...updateSetting];
    newUpdateSetting[index] = newSettingItem;
    setUpdateSetting(newUpdateSetting);
  };

  const handleSavaChangeSetting = () => {
    if (saveChangeStatus === "loading") return;
    setSaveChangeStatus("loading");

    const data = [];

    Array.isArray(updateSetting) &&
      updateSetting.forEach((setting) => {
        if (Array.isArray(setting.childrens)) {
          setting.childrens.forEach((subSetting) => {
            data.push({
              functionName: subSetting.functionCode,
              canAccess: subSetting.canAccess,
            });
          });
        } else {
          data.push({
            functionName: setting.functionCode,
            canAccess: setting.canAccess,
          });
        }
      });

    createAccessRightForStaff(staffId, data)
      .then((res) => {
        GA.tracking(EVENT_NAMES.ACCESS.CHANGE, {
          staffId,
        });
        setSaveChangeStatus("success");
      })
      .catch((error) => {
        toast.error("アクセス権限の変更に失敗しました");
        console.log("error: ", error);
        setSaveChangeStatus("idle");
      });
  };

  return (
    <>
      <div className="w-full h-full pt-5 pb-10 md:pb-12 relative">
        <div className="text-center font-bold mb-4">
          スタッフ名：{staffName}
        </div>
        <div className="w-full h-full overflow-auto">
          {updateSetting.map((setting, index) =>
            Array.isArray(setting.childrens) ? (
              <HasChildrensSettingItem
                key={index}
                setting={setting}
                handleChangeSetting={(newSettingItem) =>
                  handleChangeSetting(index, newSettingItem)
                }
              />
            ) : (
              <SettingItem
                key={index}
                setting={setting}
                handleChangeSetting={(newSettingItem) =>
                  handleChangeSetting(index, newSettingItem)
                }
              />
            )
          )}
        </div>

        <div className="w-full h-10 md:h-12 absolute inset-x-0 bottom-0 flex items-end justify-end">
          <button
            onClick={handleSavaChangeSetting}
            type="button"
            className="button-size bg-blue-btn-primary"
          >
            変更する
          </button>
        </div>
      </div>

      <CreateCompleteDialog
        open={saveChangeStatus === "success"}
        createCompleteTitle="アクセス権変更成功"
        createCompleteBtnLabel2="終了"
        createCompleteAction2={() => {
          handleBack();
        }}
      />
    </>
  );
}

function StaffAccessSettingDialogContent(props) {
  const { staffInfo, handleBack } = props;

  const [loadInitSettingState, setLoadInitSettingState] = useState({
    isLoading: true,
    data: null,
    error: null,
  });

  const loadStaffAccessRight = useCallback(() => {
    if (!staffInfo || !staffInfo.id) return;

    setLoadInitSettingState({
      isLoading: true,
      data: null,
      error: null,
    });

    getAccessRight(staffInfo.id)
      .then((res) => {
        setLoadInitSettingState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        toast.error("アクセス権限のリストの取得に失敗しました");
        setLoadInitSettingState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [staffInfo]);

  useEffect(() => {
    loadStaffAccessRight();
  }, [loadStaffAccessRight]);

  return (
    <div className="w-full h-full">
      {loadInitSettingState.isLoading ? <LoadingProgress /> : null}

      {!loadInitSettingState.isLoading &&
      loadInitSettingState.error ? (
        <LoadInitError error={loadInitSettingState.error} />
      ) : null}

      {!loadInitSettingState.isLoading &&
      !loadInitSettingState.error &&
      loadInitSettingState.data ? (
        <StaffAccessSettingForm
          staffId={staffInfo.id}
          staffName={
            staffInfo.lastNameKanji + staffInfo.firstNameKanji
          }
          initSetting={loadInitSettingState.data}
          handleBack={handleBack}
        />
      ) : null}
    </div>
  );
}

export default function StaffAccessSettingDialog(props) {
  const { staffInfo, handleClose } = props;

  return (
    <CommonDialog
      open={!!staffInfo}
      handleClose={handleClose}
      title=""
      actions={<div />}
    >
      <StaffAccessSettingDialogContent
        staffInfo={staffInfo}
        handleBack={handleClose}
      />
    </CommonDialog>
  );
}
