import { useNavigate } from "react-router-dom";
import { ResponsiveMenuItem } from "../../pages/Home";
import GA from "../../services/googleAnalytics";

const ManagementMenuMobile = ({ menus = [], onNavigate }) => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    if (onNavigate) {
      onNavigate(path);
    } else {
      navigate(path);
    }
  };

  return (
    <div className="w-full h-[calc(100%-4rem)] py-3 px-4">
      <div className="w-full h-full">
        <div className="w-full h-full flex flex-col gap-4 overflow-auto text-lg text-white ">
          {menus.map((menu, index) => (
            <div
              key={index}
              className="flex flex-col gap-2 justify-center items-center"
            >
              <div className="w-full flex justify-end">
                <button
                  disabled
                  className="w-full h-9 flex items-center justify-center px-4 py-1 rounded-full bg-gray-btn-secondary font-semibold"
                >
                  <span>{menu.title}</span>
                </button>
              </div>
              <div className="w-full flex flex-row gap-2">
                {menu.children.map((childMenu, childIndex) => (
                  <ResponsiveMenuItem
                    key={childIndex}
                    isMobile
                    itemTitle={
                      <p>{childMenu.title.replace("\n", "")}</p>
                    }
                    onClick={() => {
                      if (childMenu.eventName) {
                        GA.tracking(childMenu.eventName);
                      }
                      handleNavigate(childMenu.path);
                    }}
                    size="100%"
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ManagementMenuMobile;
