const AgreementContent = () => {
  return (
    <div className="text-justifys">
      <h1 className="text-lg font-bold text-center">
        頭皮解析サービスに関する同意書
      </h1>
      <br />
      <br />
      <p>
        株式会社頭皮Labo
        <br />
        代表取締役　西尾　義弘　殿
      </p>
      <br />
      <p>
        この度、本頭皮解析サービスを利用するにあたり、担当理美容室において、頭皮の角層及び毛髪の採取を受け、採取された検体その他必要な情報を貴社に提供すること及び株式会社頭皮
        Labo
        のプライバシーポリシーの内容について、説明を受けました。つきましては、
      </p>
      <br />
      <div>
        <div className="grid grid-cols-[30px_1fr] gap-y-2">
          <div>□</div>
          <div>
            理美容師から頭皮の角層及び毛髪の採取を受け、
            採取された検体を株式会社頭皮Labo（本店所在地：大阪市北区紅梅町
            2-3。以下同じ。） 及び同社の委託先であるDRC
            株式会社（本店所在地：大阪府大阪市北区東天満 2-10-31 第 9
            田渕ビル 3F。以下同じ。） に提供すること
          </div>
        </div>
        <br />
        <div className="grid grid-cols-[30px_1fr] gap-y-2">
          <div>□</div>
          <div>
            氏名、性別、年齢、頭皮・頭髪についての自己評価、生活習慣その他本頭皮解析サービスの提供にあたり必要な情報を、株式会社頭皮
            Labo 及び同社の委託先である DRC 株式会社に提供すること
          </div>
        </div>
        <br />
        <div className="grid grid-cols-[30px_1fr] gap-y-2">
          <div>□</div>
          <div>
            株式会社頭皮 Labo
            に提供した情報及び本頭皮解析サービスによる解析結果を、株式会社頭皮
            Labo が担当理美容室の理美容師に提供すること
          </div>
        </div>{" "}
        <br />
        <div className="grid grid-cols-[30px_1fr] gap-y-2">
          <div>□</div>
          <div>
            当社、DRC株式会社及び担当理美容室が、私が提供した個人情報を共同で利用すること
          </div>
        </div>{" "}
        <br />
        <div className="grid grid-cols-[30px_1fr] gap-y-2">
          <div>□</div>
          <div>
            株式会社頭皮 Labo
            は、私が情報を匿名化して、匿名加工情報（以下「本件匿名加工情報」という。）を作成し、本件匿名加工情報を自由に利用（加工、販売及び使用許諾を含むが、これらに限られない。
            以下同じ。 ）すること
          </div>
        </div>{" "}
        <br />
        <div className="grid grid-cols-[30px_1fr] gap-y-2">
          <div>□</div>{" "}
          <div>
            本件匿名加工情報は、原始的に株式会社頭皮 Labo
            に帰属すること
          </div>
        </div>{" "}
        <br />
        <div className="grid grid-cols-[30px_1fr] gap-y-2">
          <div>□</div>
          <div>
            株式会社頭皮 Labo は、本件匿名加工情報を、 DRC
            株式会社その他の第三者に提供し、当該第三者は、本件匿名加工情報を自由に利用すること
          </div>
        </div>
      </div>
      <br />
      <p>に同意いたします。</p>
    </div>
  );
};

export default AgreementContent;
