const convertClusterToClass = (cluster) => {
  switch (cluster) {
    case 1:
      return "A";
    case 2:
      return "B";
    case 3:
      return "C";
    case 4:
      return "D";
    case 5:
      return "E";
    default:
      return "";
  }
};

export { convertClusterToClass };
