import React from "react";
import { Item } from "./item";

export const SelectItem = ({ products = [], isTablet }) => {
  return (
    <div className={`w-full ${!isTablet ? "md:w-1/2" : ""}`}>
      <div className="w-full p-5 bg-white shadow-2xl mb-3">
        <h2 className="text-lg font-semibold text-center">LINEUP</h2>
        <p className="text-center">当店の商品ラインナップ</p>
      </div>
      {products.map((item, index) => (
        <Item key={index} data={item} />
      ))}
      <div className="mt-4">
        <p className="text-xs">
          ※本画面は、細胞面積・カルボニルタンパク数量・多重剥離度について、平均値とご自身の数値を比較していただくこと
          により、ご自身の頭皮の状態の把握に資することを目的としてご提供する、参考情報です。本画面中の記述は、理容師又
          は美容師が、法令に基づく資格の範囲内で、理容又は美容に関する事項としてお伝えする内容です。本画面中の一切の記
          述は、医学的判断に基づくものではなく、疾患の名称、原因、現在の病状、罹患可能性、今後の病状の予測、治療方針、
          予防方針等について、判断若しくは伝達し又はこれらに関する決定を支援するものではありません。
        </p>
      </div>
    </div>
  );
};
