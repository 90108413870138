import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { Pagination as MPagination, PaginationItem } from "@mui/material";

function Pagination(props) {
  const { totalPage, onPageChange, ...paginationProps } = props;

  const handleChange = (e, page) => {
    onPageChange?.(page);
  };

  return (
    <MPagination
      count={totalPage}
      onChange={handleChange}
      size="small"
      shape="rounded"
      variant="outlined"
      renderItem={(item) => (
        <PaginationItem
          slots={{
            previous: PrevButton,
            next: NextButton,
          }}
          {...item}
        />
      )}
      {...paginationProps}
    />
  );
}

const PrevButton = () => {
  return (
    <div>
      <NavigateBefore />
      <span>前ヘ</span>
    </div>
  );
};

const NextButton = () => {
  return (
    <div>
      <span>次ヘ</span>
      <NavigateNext />
    </div>
  );
};

export default Pagination;
