// import { useMediaQuery } from "@mui/material";
// import ManagementMenuDesktop from "./Desktop";
import ManagementMenuMobile from "./Mobile";

const ManagementMenu = ({ menus, onNavigate }) => {
  // const isMobile = useMediaQuery("(max-width:680px)");
  return (
    <ManagementMenuMobile menus={menus} onNavigate={onNavigate} />
  );
};
export default ManagementMenu;
