import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DefaultLayout from "app/layouts/DefaultLayout";
import CommonConfirmDialog from "app/components/CommonDialog";
import Path from "app/route/Path";
// import CustomerVisitMenuItem from "./CustomerVisitMenuItem";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ConfirmLoginDialog from "../../components/CommonDialog/ConfirmLoginDialog";
import { setPolicy } from "../../redux/authReducer";
import GA, { EVENT_NAMES } from "../../services/googleAnalytics";

export function ResponsiveMenuItem(props) {
  const {
    onClick,
    itemTitle,
    isMobile,
    isItemImgMode,
    // itemImgUrl,
    isColorDiff,
  } = props;

  // ? "!border-[#666FC8]"
  // : "!border-[#726966]"
  let imgUrl = "https://placehold.jp/1D7874/ffffff/150x150.png";

  if (isItemImgMode) {
    //imgUrl = itemImgUrl;
    return (
      <img
        onClick={onClick}
        alt="touhilab"
        src={imgUrl}
        className="w-full h-20 rounded-md relative border-[#726966] group group-hover:border-[#666FC8] hover:border-[#666FC8] cursor-pointer"
      />
      // <button
      //   style={{
      //     backgroundImage: `url("${imgUrl}")`,
      //     // backgroundImage: `url(${process.env.PUBLIC_URL + '/image.png'})`
      //     backgroundSize: "cover",
      //     backgroundRepeat: "no-repeat",
      //     backgroundPosition: "center ",
      //   }}
      //   onClick={onClick}
      //   className="w-full h-20 rounded-md relative border-[#726966] group group-hover:border-[#666FC8] hover:border-[#666FC8]"
      // >
      //   {/* <div className="w-full h-16 absolute inset-x-0 bottom-0 flex items-center justify-center bg-[#726966] group group-hover:bg-[#666FC8] hover:bg-[#666FC8] text-white">
      //     <div>{itemTitle}</div>
      //   </div> */}
      // </button>
    );
  }

  if (isMobile)
    return (
      <button
        onClick={onClick}
        //className="w-20 h-20 rounded-full border-4 relative border-[#646160] bg-[#646160] group group-hover:border-[#666FC8] hover:border-[#666FC8]"
        className={`w-20 h-20 min-[400px]:w-24 min-[400px]:h-24 
                    min-[475px]:w-28 min-[475px]:h-28 sm:w-32 sm:h-32 min-[680px]:w-36 min-[680px]:h-36 md:w-40 md:h-40 rounded-full relative 
                    ${
                      isColorDiff
                        ? "border-[#FFFFFF] bg-[#FFFFFF] bg-opacity-25"
                        : "border-[#646160] bg-[#646160]"
                    } 
                    group group-hover:border-[#666FC8] hover:border-[#666FC8]`}
      >
        <div
          //className="w-full h-full rounded-full absolute inset-x-0 bottom-0 flex items-center justify-center bg-[#646160] group group-hover:bg-[#666FC8] hover:bg-[#666FC8] text-white text-sm">
          className={`w-full h-full rounded-full absolute inset-x-0 bottom-0 flex items-center justify-center ${
            isColorDiff
              ? "border-[#FFFFFF] bg-[#FFFFFF] bg-opacity-25 text-black"
              : "border-[#646160] bg-[#646160] text-white"
          } group group-hover:bg-[#666FC8] hover:bg-[#666FC8] text-xs min-[400px]:text-sm font-semibold`}
        >
          <div>{itemTitle}</div>
        </div>
      </button>
    );
  return (
    // <MainMenuItem
    //   itemTitle={
    //     <p className="text-xs font-semibold md:text-sm lg:text-base">
    //       {itemTitle}
    //     </p>
    //   }
    //   onClick={onClick}
    //   size="100%"
    // />
    <button
      onClick={onClick}
      className={`w-60 h-20 xl:w-80 xl:h-24 rounded-full relative 
                  ${
                    isColorDiff
                      ? "border-[#FFFFFF] bg-[#FFFFFF] bg-opacity-25"
                      : "border-[#646160] bg-[#646160]"
                  } 
                  group group-hover:border-[#666FC8] hover:border-[#666FC8]`}
    >
      <div
        className={`w-full h-full rounded-full absolute inset-x-0 bottom-0 flex items-center justify-center ${
          isColorDiff
            ? "border-[#FFFFFF] bg-[#FFFFFF] bg-opacity-25 text-black"
            : "border-[#646160] bg-[#646160] text-white"
        } group group-hover:bg-[#666FC8] hover:bg-[#666FC8] text-xs min-[400px]:text-sm font-semibold`}
      >
        <div>{itemTitle}</div>
      </div>
    </button>
  );
}

function Home() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth?.user);
  const showPolicy = useSelector((state) => state.auth?.showPolicy);
  const isMobile = useMediaQuery("(max-width:1024px)");
  // const isItemImgMode = false;
  const dispatch = useDispatch();

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "",
      }}
    >
      <div className="w-full h-full flex flex-col overflow-auto justify-between">
        <div>
          <div className="flex flex-row items-start justify-center text-white mx-5">
            <h2
              className={
                "text-base lg:text-xl font-semibold whitespace-nowrap mr-2"
              }
            >
              店舗名:
            </h2>
            <h2 className="text-base lg:text-xl font-semibold">
              {user?.salonName}
            </h2>
          </div>
          <div
            className={
              "w-full grid px-4 justify-items-center xl:gap-y-10 " +
              (!isMobile ? "grid-cols-3" : "")
            }
            //style={{ rowGap: "0%" , }}
          >
            {/*<div className="flex items-center justify-center sm:aspect-square w-full h-full mb-6 sm:mb-0 sm:p-[10%] lg:aspect-[3/1] lg:p-0">*/}
            {/*  <ResponsiveMenuItem*/}
            {/*    isMobile={isMobile}*/}
            {/*    isItemImgMode={isItemImgMode}*/}
            {/*    itemTitle={*/}
            {/*      <>*/}
            {/*        <p>予約を</p>*/}
            {/*        <p>確認する</p>*/}
            {/*      </>*/}
            {/*    }*/}
            {/*    onClick={() => navigate(Path.reservationManagement)}*/}
            {/*    size="100%"*/}
            {/*  />*/}
            {/*</div>*/}
            <div className="flex items-center justify-center w-full h-full mb-6 sm:mb-0 sm:p-[10%] lg:aspect-[3/1] lg:p-0">
              <ResponsiveMenuItem
                isMobile={false}
                isColorDiff={false}
                itemTitle={
                  <>
                    <p>お客様情報</p>
                  </>
                }
                onClick={() => {
                  GA.tracking(EVENT_NAMES.HOME.CLICK_CUSTOMER);
                  navigate(Path.customerManagement);
                }}
                size="100%"
              />
            </div>

            <div className="flex items-center justify-center w-full h-full mb-6 sm:mb-0 lg:aspect-[3/1] lg:p-0">
              <ResponsiveMenuItem
                isColorDiff={true}
                isMobile={false}
                itemTitle={<p>頭皮ラボ</p>}
                onClick={() => {
                  GA.tracking(EVENT_NAMES.HOME.CLICK_SCALP_LAB);
                  navigate(Path.scalpLabManagement);
                }}
                size="100%"
              />
            </div>
            {/*<div className="flex items-center justify-center sm:aspect-square w-full h-full mb-6 sm:mb-0 sm:p-[10%] lg:aspect-[3/1] lg:p-0">*/}
            {/*  <CustomerVisitMenuItem isMobile={isMobile} />*/}
            {/*</div>*/}

            {/*<div className="flex items-center justify-center sm:aspect-square w-full h-full mb-6 sm:mb-0 sm:p-[10%] lg:aspect-[3/1] lg:p-0">*/}
            {/*  <ResponsiveMenuItem*/}
            {/*    isMobile={isMobile}*/}
            {/*    isColorDiff={true}*/}
            {/*    itemTitle={*/}
            {/*      <>*/}
            {/*        <p>本日の</p>*/}
            {/*        <p>施術内容</p>*/}
            {/*      </>*/}
            {/*    }*/}
            {/*    onClick={() => navigate(Path.customersUndergoingTreatment)}*/}
            {/*    size="100%"*/}
            {/*  />*/}
            {/*</div>*/}
            {/*<div className="flex items-center justify-center sm:aspect-square w-full h-full mb-6 sm:mb-0 sm:p-[10%] lg:aspect-[3/1] lg:p-0">*/}
            {/*  <ResponsiveMenuItem*/}
            {/*    isMobile={isMobile}*/}
            {/*    itemTitle={*/}
            {/*      <>*/}
            {/*        <p>お客様の</p>*/}
            {/*        <p>お帰り</p>*/}
            {/*      </>*/}
            {/*    }*/}
            {/*    onClick={() => navigate(Path.toDayVisitList)}*/}
            {/*    size="100%"*/}
            {/*  />*/}
            {/*</div>*/}
            {/*<div className="flex items-center justify-center sm:aspect-square w-full h-full mb-6 sm:mb-0 sm:p-[10%] lg:aspect-[3/1] lg:p-0">*/}
            {/*  <ResponsiveMenuItem*/}
            {/*    isMobile={isMobile}*/}
            {/*    itemTitle={<p>レジ機能</p>}*/}
            {/*    onClick={() => navigate(Path.cashierManagement)}*/}
            {/*    size="100%"*/}
            {/*  />*/}
            {/*</div>*/}
            <div className="flex items-center justify-center w-full h-full mb-6 sm:mb-0 sm:p-[10%] lg:aspect-[3/1] lg:p-0">
              <ResponsiveMenuItem
                isMobile={false}
                isColorDiff={false}
                itemTitle={
                  <>
                    <p>在庫確認</p>
                  </>
                }
                onClick={() => {
                  GA.tracking(EVENT_NAMES.HOME.CLICK_INVENTORY);
                  navigate(Path.inventoryManagement);
                }}
                size="100%"
              />
            </div>
            {/*<div className="flex items-center justify-center sm:aspect-square w-full h-full mb-6 sm:mb-0 sm:p-[10%] lg:aspect-[3/1] lg:p-0">*/}
            {/*  <ResponsiveMenuItem*/}
            {/*    isMobile={isMobile}*/}
            {/*    itemTitle={*/}
            {/*      <>*/}
            {/*        <p>ダイレクト</p>*/}
            {/*        <p>メール</p>*/}
            {/*      </>*/}
            {/*    }*/}
            {/*    onClick={() => navigate(Path.dmsManagement)}*/}
            {/*    size="100%"*/}
            {/*  />*/}
            {/*</div>*/}
            {/*<div className="flex items-center justify-center sm:aspect-square w-full h-full mb-6 sm:mb-0 sm:p-[10%] lg:aspect-[3/1] lg:p-0">*/}
            {/*  <ResponsiveMenuItem*/}
            {/*    isMobile={isMobile}*/}
            {/*    itemTitle={*/}
            {/*      <>*/}
            {/*        <p>勤怠を</p>*/}
            {/*        <p>入力する</p>*/}
            {/*      </>*/}
            {/*    }*/}
            {/*    onClick={() => navigate(Path.staffManagement)}*/}
            {/*    size="100%"*/}
            {/*  />*/}
            {/*</div>*/}
            {/*<div className="flex items-center justify-center sm:aspect-square w-full h-full mb-6 sm:mb-0 sm:p-[10%] lg:aspect-[3/1] lg:p-0">*/}
            {/*  <ResponsiveMenuItem*/}
            {/*    isMobile={isMobile}*/}
            {/*    isColorDiff={true}*/}
            {/*    itemTitle={*/}
            {/*      <>*/}
            {/*        <p>売上を</p>*/}
            {/*        <p>入力する</p>*/}
            {/*      </>*/}
            {/*    }*/}
            {/*    onClick={() => navigate(Path.saleManagement)}*/}
            {/*    size="100%"*/}
            {/*  />*/}
            {/*</div>*/}
            {/*<div className="flex items-center justify-center sm:aspect-square w-full h-full mb-6 sm:mb-0 sm:p-[10%] lg:aspect-[3/1] lg:p-0">*/}
            {/*  <ResponsiveMenuItem*/}
            {/*    isMobile={isMobile}*/}
            {/*    itemTitle={*/}
            {/*      <>*/}
            {/*        <p>広告を</p>*/}
            {/*        <p>管理する</p>*/}
            {/*      </>*/}
            {/*    }*/}
            {/*    onClick={() => console.log("test........")}*/}
            {/*    size="100%"*/}
            {/*  />*/}
            {/*</div>*/}
            {/*<div className="flex items-center justify-center sm:aspect-square w-full h-full mb-6 sm:mb-0 sm:p-[10%] lg:aspect-[3/1] lg:p-0">*/}
            {/*  <ResponsiveMenuItem*/}
            {/*    isMobile={isMobile}*/}
            {/*    itemTitle={<p>経営分析</p>}*/}
            {/*    onClick={() => navigate(Path.analyzeManagement)}*/}
            {/*    size="100%"*/}
            {/*  />*/}
            {/*</div>*/}
            <div className="flex items-center justify-center w-full h-full mb-6 sm:mb-0 lg:aspect-[3/1] lg:p-0">
              <ResponsiveMenuItem
                isMobile={false}
                isColorDiff={true}
                itemTitle={
                  <>
                    <p>システム設定</p>
                  </>
                }
                onClick={() => {
                  GA.tracking(EVENT_NAMES.HOME.CLICK_SYSTEM);
                  navigate(Path.systemManagement);
                }}
                size="100%"
              />
            </div>
            <div className="flex items-center justify-center w-full h-full mb-6 sm:mb-0 sm:p-[10%] lg:aspect-[3/1] lg:p-0">
              <ResponsiveMenuItem
                isMobile={false}
                isColorDiff={false}
                itemTitle={
                  <>
                    <p>事前導入（開発中）</p>
                  </>
                }
                onClick={() => {}}
                size="100%"
              />
            </div>
            <div className="flex items-center justify-center w-full h-full mb-6 sm:mb-0 sm:p-[10%] lg:aspect-[3/1] lg:p-0"></div>
          </div>
        </div>

        <div className="justify-self-end flex justify-center gap-8 underline">
          <Link
            onClick={() => {
              GA.tracking(EVENT_NAMES.HOME.CLICK_TERMS);
            }}
            to="/act-of-commercial-transactions"
          >
            特定商取引法
          </Link>
          <Link
            onClick={() => {
              GA.tracking(EVENT_NAMES.HOME.CLICK_PRIVACY_POLICY);
            }}
            to="/privacy-policy"
          >
            プライバシーポリシー
          </Link>
        </div>
      </div>

      <CommonConfirmDialog
        open={open}
        handleClose={() => setOpen(false)}
        title="Q. お客様は頭皮ラボの会員ですか？"
      />
      <ConfirmLoginDialog
        open={showPolicy}
        handleClose={() => dispatch(setPolicy(false))}
      />
    </DefaultLayout>
  );
}

export default Home;
