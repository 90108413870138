const handleClassesToText = (classes) => {
  const adjustedClasses = classes.map((item) => {
    switch (item) {
      case "CL1":
        return "A";
      case "CL2":
        return "B";
      case "CL3":
        return "C";
      case "CL4":
        return "D";
      case "CL5":
        return "E";
      default:
        return "";
    }
  });
  return adjustedClasses.join(", ");
};

export { handleClassesToText };
