import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Path from "app/route/Path";
import DefaultLayout from "app/layouts/DefaultLayout";
import MainContentLayout from "app/layouts/MainContentLayout";
import CreateCompleteDialog from "../../../components/CommonDialog/CreateCompleteDialog";
import LoadingBackdrop from "../../../components/commonUI/LoadingBackdrop";
import LoadingProgress from "../../../components/commonUI/LoadingProgress";
import LoadInitError from "../../../components/commonUI/LoadInitError";
import { createNewInventory } from "../../../services/api/18";
import { fake_menu_list } from "../../../services/api/fake_services/fake_data";
import GA, { EVENT_NAMES } from "../../../services/googleAnalytics";
import CreateInventoryForm from "./CreateInventoryForm";

function RegisterInventoryFormPage({ menuId }) {
  const navigate = useNavigate();
  const [creatingInventoryState, setCreatingInventoryState] = useState({
    confirmedData: null,
    isCreating: false,
    error: null,
    res: null,
  });
  const [fetchInventoryDetail, setFetchInventoryDetail] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const loadInventoryDetail = () => {
    setFetchInventoryDetail({
      isLoading: true,
      data: null,
      error: null,
    });
    const getMenuDetail = (id) => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            data: fake_menu_list[Number(id) - 1],
          });
        }, 1000);
      });
    };
    getMenuDetail(menuId)
      .then((res) => {
        setFetchInventoryDetail({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchInventoryDetail({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  };

  const handleCreateNewInventory = () => {
    setCreatingInventoryState((oldState) => ({
      ...oldState,
      isCreating: true,
      error: null,
      res: null,
    }));

    createNewInventory(creatingInventoryState.confirmedData)
      .then((res) => {
        console.log(
          "creatingInventoryState.confirmedDat",
          creatingInventoryState.confirmedData
        );
        GA.tracking(EVENT_NAMES.INVENTORY_REGISTER.SUBMIT, {
          productNo: creatingInventoryState.confirmedData.productNumber,
        });
        setCreatingInventoryState((oldState) => ({
          ...oldState,
          isCreating: false,
          error: null,
          res: res,
        }));
        toast.success("インベントリの作成が成功しました");
      })
      .catch((error) => {
        setCreatingInventoryState((oldState) => ({
          ...oldState,
          isCreating: false,
          error: error,
          res: null,
        }));
        toast.error("製品番号が重複しているため、在庫の作成に失敗しました");
      });
  };

  const confirmMenuData = (confirmedData) => {
    setCreatingInventoryState({
      confirmedData,
      isCreating: false,
      error: null,
      res: null,
    });
  };

  const handleBackClick = () => {
    if (!creatingInventoryState.confirmedData) {
      return navigate(Path.inventoryManagement);
    }

    setCreatingInventoryState({
      confirmedData: false,
      isCreating: false,
      error: null,
      res: null,
    });
  };

  useEffect(() => {
    if (menuId) {
      loadInventoryDetail();
    }
  }, []);

  return (
    <DefaultLayout>
      <MainContentLayout pageTitle={<p>システム</p>}>
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
            <h2 className="text-base lg:text-xl font-semibold">在庫登録</h2>
          </div>
          <div className="w-full h-full py-3">
            <div className="w-full h-full pr-3 ">
              <div className="w-full h-full overflow-auto">
                {fetchInventoryDetail.isLoading ? <LoadingProgress /> : null}

                {!fetchInventoryDetail.isLoading &&
                fetchInventoryDetail.error ? (
                  <LoadInitError error={fetchInventoryDetail.error} />
                ) : null}
                {!fetchInventoryDetail.isLoading &&
                !fetchInventoryDetail.error &&
                menuId !== "" &&
                !!fetchInventoryDetail.data ? (
                  <CreateInventoryForm
                    menuId={menuId}
                    fetchInventoryDetail={fetchInventoryDetail.data}
                    isReadMode={creatingInventoryState.confirmedData}
                    handleCreate={
                      creatingInventoryState.confirmedData
                        ? handleCreateNewInventory
                        : null
                    }
                    confirmMenuData={confirmMenuData}
                    handleBackClick={handleBackClick}
                  />
                ) : (
                  <CreateInventoryForm
                    isReadMode={creatingInventoryState.confirmedData}
                    handleCreate={
                      creatingInventoryState.confirmedData
                        ? handleCreateNewInventory
                        : null
                    }
                    confirmMenuData={confirmMenuData}
                    handleBackClick={handleBackClick}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <CreateCompleteDialog
          open={creatingInventoryState.res}
          createCompleteTitle="在庫を登録しました"
          createCompleteBtnLabel1="在庫管理へ戻る"
          createCompleteAction1={() => {
            navigate(Path.inventoryManagement);
          }}
          createCompleteBtnLabel2="終了"
          createCompleteAction2={() => {
            navigate(Path.inventoryManagement);
          }}
        />

        <LoadingBackdrop isOpen={creatingInventoryState.isCreating} />
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default RegisterInventoryFormPage;
