import React, { useState } from "react";
import { setAuthUser, setAuthToken } from "app/redux/authReducer";
import { useDispatch } from "react-redux";
import LOGIN_LOGO from "app/assets/images/tohilabo-logo.svg";

import { login } from "../../services/api";
import { ToastContainer, toast } from "react-toastify";
import jwtDecode from "jwt-decode";
import { accountTypes } from "../../constants";
import GA, { EVENT_NAMES } from "../../services/googleAnalytics";

function Login() {
  const dispatch = useDispatch();
  const [loginName, setLoginName] = useState("");
  const [password, setPassword] = useState("");

  // パスワード表示制御ようのstate
  const [isRevealPassword, setIsRevealPassword] = useState(false);

  const togglePassword = () => {
    setIsRevealPassword((prevState) => !prevState);
  };

  const autofillStyles = {
    WebkitTransition: "background-color 5000s ease-in-out 0s",
    "&:-webkit-autofill": {
      WebkitTransition: "background-color 5000s ease-in-out 0s",
    },
    "&:-webkit-autofill:hover": {
      WebkitTransition: "background-color 5000s ease-in-out 0s",
    },
    "&:-webkit-autofill:focus": {
      WebkitTransition: "background-color 5000s ease-in-out 0s",
    },
    "&:-webkit-autofill:active": {
      WebkitTransition: "background-color 5000s ease-in-out 0s",
    },
    fontSize: "16px",
  };

  const handleLogin = () => {
    login(loginName, password)
      .then((res) => {
        const tokenData = jwtDecode(res.data.result.accessToken);
        const userId = res.data.result.id;
        GA.login(userId);
        GA.tracking(EVENT_NAMES.CLICK_LOGIN);
        dispatch(
          setAuthUser({
            id: res.data.result.id,
            email: res.data.result.email,
            permissions: tokenData?.permissions || [],
            accountType: tokenData?.accountType || accountTypes.STAFF,
            loginName,
            salonName: res.data?.result?.salonName,
          })
        );
        dispatch(
          setAuthToken({
            accessToken: res.data.result.accessToken,
          })
        );
      })
      .catch((err) => {
        console.log("login error");
        toast.error("ログイン情報が間違っています");
      });
  };

  return (
    <>
      <div className="bg-[url('app/assets/images/back.png')] bg-cover bg-center">
        {/* <section className="flex items-center justify-center h-screen backdrop-blur-lg"> */}
        {/* Add 'backdrop-blur-lg' for bluring  */}
        <section className="flex justify-center h-screen">
          <div className="w-full p-6 m-4 mt-[80px] bg-transparent rounded-lg sm:max-w-md">
            <img
              src={LOGIN_LOGO}
              alt="tohilabo_logo"
              class="w-[220px] mx-auto mb-[80px]"
            />
            <form
              className="flex flex-col justify-center space-y-4 mt-8"
              action="#"
            >
              <div>
                <label
                  for="loginName"
                  className="block mb-1 ml-2 text-lg font-medium text-gray-900"
                >
                  ログイン名
                </label>
                <input
                  type="text"
                  name="loginName"
                  id="loginName"
                  className="block w-full px-6 py-4 mb-2 text-lg text-gray-500 bg-gray-50 bg-opacity-30 rounded-lg focus:border-blue-500 focus:ring-blue-500 placeholder-gray-500"
                  style={{ ...autofillStyles }}
                  placeholder="account"
                  autocomplete="off"
                  required=""
                  value={loginName}
                  onChange={(e) => setLoginName(e.currentTarget.value)}
                />
              </div>
              <div>
                <label
                  for="password"
                  className="block mb-1 ml-2 text-lg  font-medium text-gray-900"
                >
                  パスワード
                </label>
                <div className="relative">
                  <input
                    type={isRevealPassword ? "text" : "password"}
                    name="password"
                    id="password"
                    className="block w-full px-6 py-4 mb-16 text-lg text-gray-500 bg-gray-50 bg-opacity-30  rounded-lg focus:border-blue-500 focus:ring-blue-500 placeholder-gray-500"
                    placeholder="••••••••"
                    autocomplete="off"
                    style={{ ...autofillStyles }}
                    required=""
                    value={password}
                    onChange={(e) => setPassword(e.currentTarget.value)}
                  />
                  {/*  spanタグにrole="presentation"をつけないと、onClickつけたらeslintの内容によっては怒られます。 */}
                  <span
                    onClick={togglePassword}
                    role="presentation"
                    className={
                      "absolute inset-y-0 right-0 pr-3 flex items-center text-lg leading-5 cursor-pointer text-gray-500"
                    }
                  >
                    {isRevealPassword ? (
                      <i className="fa fa-eye" />
                    ) : (
                      <i className="fa fa-eye-slash" />
                    )}
                  </span>
                </div>
              </div>

              <button
                type="button"
                onClick={handleLogin}
                className="block w-3/4 py-4 text-lg font-medium text-white bg-black rounded-lg self-center"
              >
                ログイン
              </button>
            </form>
          </div>
        </section>
      </div>

      <ToastContainer
        position="bottom-left"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        limit={3}
      />
    </>
  );
}

export default Login;
