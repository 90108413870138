const SurveyButton = ({ handleClick, active, text, disabled }) => {
  return (
    <button
      type="button"
      onClick={handleClick}
      disabled={disabled}
      className={`
      button-size font-semibold rounded-full h-10 text-sm sm:text-base 
      ${
        active
          ? "bg-[#666FC8] text-white shadow-md border-4 border-[#666FC8]"
          : "bg-[#F0F1EF] bg-opacity-50-btn-primary border-4 border-stone-300 text-black"
      }`}
    >
      {text}
    </button>
  );
};

export default SurveyButton;
