import React, { useState } from "react";
import DefaultLayout from "../../../layouts/DefaultLayout";
import MainContentLayout from "../../../layouts/MainContentLayout";
import { useNavigate, useLocation } from "react-router-dom";
import Path from "../../../route/Path";
import { toast } from "react-toastify";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { updateSalonCustomer } from "../../../services/api/1C";
import CommonDialog from "../../../components/CommonDialog";
import AgreementContent from "../../../components/AgreementContent";
import GA, { EVENT_NAMES } from "../../../services/googleAnalytics";

function ConfirmApplicationForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const values = location.state?.values;
  const isRegistrationFlow =
    values && Object.keys(values).length !== 0;

  const confirmSteps = {
    CONFIRM: "CONFIRM",
    CONFIRM_AGAIN: "CONFIRM_AGAIN",
  };

  const [showStep, setShowStep] = useState(confirmSteps.CONFIRM);

  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isOpenRegisterDialog, setIsOpenRegisterDialog] =
    useState(false);

  const handleSubmit = async (values) => {
    updateSalonCustomer(values.salonCustomerId, {
      customerNo: values.customerNo,
      isConfirmAgreement: values.isConfirmAgreement,
    })
      .then((res) => {
        setShowStep(confirmSteps.CONFIRM_AGAIN);
        toast.success("頭皮データの注文が成功しました");
      })
      .catch((error) => {
        if (error) {
          setIsOpenRegisterDialog(false);
        }
        if (error.response.data.message === "duplicate.kitId") {
          toast.error("キットIDが重複しました");
        } else {
          toast.error("正しい情報を入力してください");
        }
      });
  };

  const handleConfirm = () => {
    if (showStep === confirmSteps.CONFIRM) {
      GA.tracking(EVENT_NAMES.APPLICATION_FORM.CONFIRM_AGREEMENT, {
        customerNo: values.customerNo,
      });
      handleSubmit(values);
    } else if (showStep === confirmSteps.CONFIRM_AGAIN) {
      setIsOpenRegisterDialog(false);
      setShowStep(confirmSteps.CONFIRM);
      navigate(Path.scalpLabManagement);
    }
  };

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>頭皮ラボ</p>
          </>
        }
      >
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
            <h2 className="text-base lg:text-xl font-semibold">
              同意書の確認
            </h2>
          </div>
          <div className="w-full h-full py-3">
            <div className="w-full flex justify-center h-full pr-3 border border-gray-400 rounded-2xl p-4">
              <div className="w-[1000px] h-full overflow-auto pr-4">
                {" "}
                <AgreementContent />
              </div>
            </div>
          </div>
          <div className="w-5/6 h-8 flex items-center justify-start   text-base font-semibold absolute left-4 bottom-2">
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={true} />}
                label="同意します"
              />
            </FormGroup>
          </div>
          <div className="w-full h-8 flex gap-6 items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
            <button
              onClick={() =>
                navigate(Path.scalpLabManagementApplicationForm, {
                  state: { values },
                })
              }
              type="button"
              className="button-size bg-gray-btn-secondary"
            >
              戻る
            </button>
            <button
              type="button"
              className="button-size bg-blue-btn-primary"
              onClick={() => {
                if (isRegistrationFlow) {
                  setIsOpenRegisterDialog(true);
                } else {
                  setIsOpenDialog(true);
                }
              }}
            >
              申込
            </button>
          </div>
        </div>

        <CommonDialog
          open={isOpenDialog}
          handleClose={() => setIsOpenDialog(false)}
          title="頭皮ラボの申込が完了しました"
          actions={
            <div className="w-full flex gap-6 items-center justify-end text-white text-base font-semibold">
              <button
                onClick={() => navigate(Path.scalpLabManagement)}
                type="button"
                className="button-size bg-gray-btn-secondary"
              >
                頭皮ラボへ戻る
              </button>
              <button
                onClick={() => navigate(Path.scalpLabManagement)}
                type="button"
                className="button-size bg-blue-btn-primary"
              >
                終了
              </button>
            </div>
          }
        />

        <CommonDialog
          open={isOpenRegisterDialog}
          handleClose={() => {
            if (!confirmSteps.CONFIRM_AGAIN) {
              setIsOpenRegisterDialog(false);
            }
            setIsOpenRegisterDialog(false);
          }}
          title={
            showStep === confirmSteps.CONFIRM
              ? "頭皮ラボを登録しますか？"
              : ""
          }
          actions={
            <div className="w-full flex gap-6 items-center justify-end text-white text-base font-semibold">
              <button
                onClick={handleConfirm}
                type="button"
                className="button-size bg-blue-btn-primary"
              >
                {showStep === confirmSteps.CONFIRM ? "登録" : "終了"}
              </button>
            </div>
          }
        >
          <div className="flex justify-center text-lg font-semibold">
            {showStep === confirmSteps.CONFIRM
              ? ` お客様名 ${values.customerName}`
              : "頭皮データを登録しました"}
          </div>
        </CommonDialog>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default ConfirmApplicationForm;
