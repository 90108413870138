import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/ja";
import { CSVLink } from "react-csv";

import { Avatar, CircularProgress, Skeleton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

import DefaultLayout from "app/layouts/DefaultLayout";
import MainContentLayout from "app/layouts/MainContentLayout";
import CustomDatePicker from "app/components/commonUI/CustomDatePicker";
import {
  getAllStaffWithShiftPlanByDate,
  getAllStaffWithShiftPlanByMonth,
} from "app/services/api";
import { defaultDateFormat, jpDateFormat } from "app/constants";
import { getNameFromFirstLast } from "app/utils";
import Path from "app/route/Path";
import defaultAvatar from "app/assets/svg/default_avatar.svg";
import ShiftPlanDateForm from "./ShiftPlanDateForm";
import NoData from "app/components/commonUI/NoData";
import { toast } from "react-toastify";

function StaffCard(props) {
  const { isLoading, staffInfo, handleSelectStaff } = props;
  return (
    <button
      type="button"
      onClick={() => handleSelectStaff(staffInfo)}
      className="w-20 h-full shrink-0 rounded-lg hover:bg-slate-200"
    >
      <div className="w-full h-full flex flex-col items-center justify-center">
        {isLoading ? (
          <div className="w-10 h-10 shrink-0">
            <Skeleton variant="circular" width={40} height={40} />
          </div>
        ) : (
          <Avatar
            alt=""
            src={staffInfo.imageUrl || defaultAvatar}
            sx={{
              width: 40,
              height: 40,
              border: "1px solid black",
            }}
          />
        )}
        {isLoading ? (
          <div className="h-5 w-3/4 shrink-0 flex items-center">
            <Skeleton variant="rounded" width={60} height={14} />
          </div>
        ) : (
          <h3 className="w-full truncate text-sm font-semibold text-center">
            {getNameFromFirstLast(staffInfo)}
          </h3>
        )}
      </div>
    </button>
  );
}

function StaffListBox(props) {
  const {
    title,
    isLoading,
    staffList,
    handleSelectStaff,
    noStaffMessage,
    numberOfPlaceholder,
  } = props;

  const listBoxRef = useRef(null);

  useEffect(() => {
    const staffListEl = listBoxRef.current;

    const handleStaffListWheel = (event) => {
      event.preventDefault();

      staffListEl.scrollBy({
        left: event.deltaY < 0 ? -30 : 30,
      });
    };

    if (staffListEl) {
      staffListEl.addEventListener("wheel", handleStaffListWheel);
    }

    return () => {
      if (staffListEl) {
        staffListEl.removeEventListener("wheel", handleStaffListWheel);
      }
    };
  }, []);

  return (
    <div className="w-full h-36 rounded-lg relative">
      <div className="absolute w-auto h-6 flex items-center justify-center px-3 left-4 -top-3 bg-[#F0F1EF] bg-opacity-50 z-20">
        <h3 className="text-lg leading-6 font-semibold">{title}</h3>
      </div>
      <div
        ref={listBoxRef}
        className="absolute inset-2 py-2 w-[calc(100%-16px)] h-[calc(100%-16px)] z-10 overflow-auto small-scrollbar"
      >
        <div className="w-auto h-full flex items-center flex-nowrap gap-2">
          {isLoading
            ? new Array(Number(numberOfPlaceholder) || 3)
                .fill(1)
                .map((_, index) => <StaffCard key={index} isLoading={true} />)
            : Array.isArray(staffList) && staffList.length > 0
            ? staffList.map((staff) => (
                <StaffCard
                  key={staff._id}
                  staffInfo={staff}
                  handleSelectStaff={handleSelectStaff}
                />
              ))
            : noStaffMessage}
        </div>
      </div>
    </div>
  );
}

function DownloadCSVBtn(props) {
  const { month } = props;

  const [loadExportDataState, setLoadExportDataState] = useState({
    isLoading: false,
    data: null,
    error: null,
  });
  const btnRef = useRef();

  const loadExportData = useCallback(() => {
    if (loadExportDataState.isLoading || !month) return;
    setLoadExportDataState({
      isLoading: true,
      data: null,
      error: null,
    });

    getAllStaffWithShiftPlanByMonth(month)
      .then((res) => {
        setLoadExportDataState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setLoadExportDataState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [month]);

  useEffect(() => {
    loadExportData();
  }, [loadExportData]);

  const csvData = useMemo(() => {
    if (loadExportDataState.isLoading === true) return null;
    let data = [["申請者", "日付", "開始", "開始"]];

    if (Array.isArray(loadExportDataState.data)) {
      loadExportDataState.data.forEach((staff) => {
        if (Array.isArray(staff.shiftplans) && staff.shiftplans.length > 0) {
          staff.shiftplans.forEach((shiftplan) => {
            data.push([
              getNameFromFirstLast(staff),
              moment(shiftplan.workDate, defaultDateFormat).format(
                jpDateFormat
              ),
              shiftplan.startTime,
              shiftplan.finishTime,
            ]);
          });
        }
      });
    }

    return data;
  }, [loadExportDataState.data, loadExportDataState.isLoading]);

  if (!csvData)
    return (
      <div className="button-size bg-blue-btn-primary mr-4 lg:mr-6 text-white">
        <CircularProgress size={24} color="inherit" />
        <span className="ml-3 hidden md:block">エクスポート</span>
      </div>
    );

  return (
    <CSVLink
      filename={`all_staff_shift_plan-${month}`}
      data={csvData}
      className="button-size bg-blue-btn-primary mr-4"
    >
      <FontAwesomeIcon icon={faDownload} />
      <span className="ml-3 hidden md:block">エクスポート</span>
    </CSVLink>
  );
}

function StaffManagementCalendarPageContent(props) {
  const {
    fetchAllStaffState,
    selectDate,
    setSelectedDate,
    handleShowDetailStaffShiftPlan,
    handleShowRegisterStaffShiftPlan,
  } = props;

  const onStaffList = useMemo(() => {
    if (Array.isArray(fetchAllStaffState.data))
      return fetchAllStaffState.data.filter(
        (staff) =>
          Array.isArray(staff?.shiftplans) && staff.shiftplans.length > 0
      );
  }, [fetchAllStaffState.data]);

  const offStaffList = useMemo(() => {
    if (Array.isArray(fetchAllStaffState.data))
      return fetchAllStaffState.data.filter(
        (staff) =>
          !(Array.isArray(staff?.shiftplans) && staff.shiftplans.length > 0)
      );
  }, [fetchAllStaffState.data]);

  return (
    <>
      <div className="w-full h-full pt-16 lg:pt-12 relative">
        <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-between absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
          <h2 className="text-base lg:text-xl font-semibold">出勤スタッフ</h2>
          <div className="w-auto flex items-center justify-end">
            <DownloadCSVBtn
              month={selectDate ? selectDate.format("YYYY-MM") : ""}
            />

            <Link
              to={Path.staffManagementSelectStaffRegisterShiftPlan}
              className="button-size bg-blue-btn-primary mr-3"
            >
              シフトを作成
            </Link>
          </div>
        </div>

        <div className="w-full h-full py-3">
          <div className="w-full h-full md:flex overflow-auto">
            <div className="w-full h-auto py-4 md:py-0 md:w-7/12 md:h-full md:pr-4 flex justify-center">
              <CustomDatePicker value={selectDate} onChange={setSelectedDate} />
            </div>
            <div className="w-full h-auto md:w-5/12 md:h-full md:overflow-auto">
              <div className="w-full h-auto border border-gray-400 rounded-xl p-4 pt-8 flex flex-col justify-center">
                {/* List On Staff */}
                <StaffListBox
                  title="出勤"
                  isLoading={fetchAllStaffState.isLoading}
                  staffList={onStaffList}
                  handleSelectStaff={handleShowDetailStaffShiftPlan}
                  noStaffMessage={<NoData />}
                  numberOfPlaceholder={5}
                />

                <div className="w-full h-12 shrink-0" />

                {/* List Off Staff */}
                <StaffListBox
                  title="休暇"
                  isLoading={fetchAllStaffState.isLoading}
                  staffList={offStaffList}
                  handleSelectStaff={handleShowRegisterStaffShiftPlan}
                  noStaffMessage={<NoData />}
                  numberOfPlaceholder={2}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function StaffManagementCalendarPage() {
  const [selectedDate, setSelectedDate] = useState(() => moment());
  const [showDetailShiftPlan, setShowDetailShiftPlan] = useState(null);
  const [registerShiftForStaff, setRegisterShiftForStaff] = useState(null);

  const [fetchAllStaffState, setFetchAllStaffState] = useState({
    isLoading: true,
    data: null,
    error: null,
  });

  const loadAllStaffWithShiftPlan = useCallback(() => {
    if (!selectedDate) return;
    setFetchAllStaffState({
      isLoading: true,
      data: null,
      error: null,
    });

    getAllStaffWithShiftPlanByDate(selectedDate.format(defaultDateFormat))
      .then((res) => {
        setFetchAllStaffState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchAllStaffState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [selectedDate]);

  useEffect(() => {
    loadAllStaffWithShiftPlan();
  }, [loadAllStaffWithShiftPlan]);

  const handleShowDetailStaffShiftPlan = (staff) => {
    setShowDetailShiftPlan(staff);
    setRegisterShiftForStaff(null);
  };

  const handleShowRegisterStaffShiftPlan = (staff) => {
    setShowDetailShiftPlan(null);
    setRegisterShiftForStaff(staff);
  };

  const backToCalendar = () => {
    setShowDetailShiftPlan(null);
    setRegisterShiftForStaff(null);
  };

  return (
    <DefaultLayout>
      <MainContentLayout pageTitle={<p>勤怠</p>}>
        {showDetailShiftPlan &&
        Array.isArray(showDetailShiftPlan.shiftplans) &&
        showDetailShiftPlan.shiftplans.length > 0 ? (
          <ShiftPlanDateForm
            detailStaffShiftPlan={showDetailShiftPlan}
            workDate={selectedDate}
            backToCalendar={backToCalendar}
            loadAllStaffWithShiftPlan={loadAllStaffWithShiftPlan}
          />
        ) : null}

        {selectedDate && registerShiftForStaff ? (
          <ShiftPlanDateForm
            registerStaffShiftPlan={registerShiftForStaff}
            workDate={selectedDate}
            backToCalendar={backToCalendar}
            loadAllStaffWithShiftPlan={loadAllStaffWithShiftPlan}
          />
        ) : null}

        {!showDetailShiftPlan && !registerShiftForStaff ? (
          <StaffManagementCalendarPageContent
            fetchAllStaffState={fetchAllStaffState}
            selectDate={selectedDate}
            setSelectedDate={setSelectedDate}
            handleShowDetailStaffShiftPlan={handleShowDetailStaffShiftPlan}
            handleShowRegisterStaffShiftPlan={handleShowRegisterStaffShiftPlan}
          />
        ) : null}
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default StaffManagementCalendarPage;
