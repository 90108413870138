import React from "react";
import { FormControl, InputLabel } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import BottomButtons from "../../../components/Customer1StImpressionAndFactCheckPage/BottomButtons";
import SurveyButton from "../../../components/Customer1StImpressionAndFactCheckPage/SurveyButton";
import {
  questions,
  sections,
} from "../../../pages/14/Customer1StImpressionAndFactCheckPage/constants";

const SelectingBlock = ({
  stepId,
  selectedValue,
  selectedButtons,
  isDisableFlag = false,
  inputData,
  handleChange,
  handleInputChange,
  handleButtonClick,
  handleNextSection,
  handleSubmit,
}) => {
  return (
    <div className="w-full h-full mx-auto relative max-h-full flex flex-wrap pt-16 pb-8 lg:py-12">
      <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
        <FormControl
          sx={{ minWidth: 150, maxWidth: 200 }}
          size="small"
        >
          <InputLabel id="demo-select-small-label"></InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            //value={member}
            label="メンバー"
            onChange={handleChange}
            //disabled={isChangeButton}
            style={{
              borderRadius: "3rem",
              backgroundColor: "#868686",
            }}
            sx={{
              color: "white",
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(228, 219, 233, 0.25)",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(228, 219, 233, 0.25)",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(228, 219, 233, 0.25)",
              },
              ".MuiSvgIcon-root ": {
                fill: "white !important",
              },
            }}
            className="!w-72"
            value={selectedValue}
          >
            {/* {listCustomer?.map((customer) => (
        <MenuItem value={customer.code}>{customer.describe}</MenuItem>
      ))} */}
            {sections.map((section) => (
              <MenuItem value={section.id}>{section.title}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="w-full h-full py-3">
        {questions[stepId - 1].map((question) => (
          <div className="w-full py-5">
            <div key={question.id} className="w-full">
              <div className="w-full h-10">{question.text}</div>
              {question.type === "options" ? (
                <div className="w-full pl-16 pr-16 py-3 grid grid-cols-2 sm:grid-cols-4 gap-x-4 gap-y-2">
                  {question.options.map((option) => (
                    <SurveyButton
                      key={option}
                      text={option}
                      name={question.id}
                      active={
                        question.isMultiSelect
                          ? selectedButtons[
                              question.id
                            ]?.answers.includes(option) // 多选情况
                          : selectedButtons[question.id]?.answer ===
                            option // 单选情况
                      }
                      disabled={isDisableFlag}
                      handleClick={() =>
                        handleButtonClick(question.id, option)
                      }
                    />
                  ))}
                </div>
              ) : (
                <div className="w-full py-8 flex justify-center">
                  <input
                    type="text"
                    id=""
                    name={question.id}
                    className="focus:outline-[#1976d2] w-1/2 border border-[#cbcbcb] hover:border-gray-800 text-base px-3 py-[5px] rounded-2xl"
                    placeholder="入力してください"
                    value={inputData[question.id]?.answer}
                    disabled={isDisableFlag}
                    onChange={(e) =>
                      handleInputChange(e, question.id)
                    }
                  ></input>
                </div>
              )}
            </div>
          </div>
        ))}
        <BottomButtons
          step={stepId}
          handleNextSection={handleNextSection}
          handleSubmit={handleSubmit}
        />
      </div>
    </div>
  );
};

export default SelectingBlock;
