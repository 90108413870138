import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Avatar } from "@mui/material";
import Path from "app/route/Path";
import { getAllSalonStaffs } from "app/services/api";
import defaultAvatar from "app/assets/svg/default_avatar.svg";
import DefaultLayout from "../../../layouts/DefaultLayout";
import MainContentLayout from "../../../layouts/MainContentLayout";
import LoadingProgress from "../../../components/commonUI/LoadingProgress";
import LoadInitError from "../../../components/commonUI/LoadInitError";
import NoData from "app/components/commonUI/NoData";
import { getNameFromFirstLast } from "../../../utils";
import StaffAccessSettingDialog from "./StaffAccessSettingDialog";
import GA, { EVENT_NAMES } from "../../../services/googleAnalytics";

function StaffItem({ staffInfo, handleSelectStaff }) {
  return (
    <button
      onClick={() => {
        GA.tracking(EVENT_NAMES.ACCESS.CLICK_DETAIL, {
          staffId: staffInfo.id,
        });
        handleSelectStaff(staffInfo);
      }}
      className="w-[100px] h-[120px] flex flex-col gap-4 items-center justify-center rounded-md bg-slate-100 hover:bg-slate-200"
    >
      <Avatar
        alt={getNameFromFirstLast(staffInfo) || ""}
        src={staffInfo?.imageUrl || defaultAvatar}
        sx={{ width: 56, height: 56 }}
      />
      <div className="w-full truncate text-sm text-center font-semibold px-2">
        {staffInfo.lastNameKanji + staffInfo.firstNameKanji}
      </div>
    </button>
  );
}

function AccessSettingPageContent(props) {
  const [showStaffSetting, setShowStaffSetting] = useState(null);
  const { allSalonStaff } = props;
  const handleSelectStaff = (staff) => setShowStaffSetting(staff);

  return (
    <>
      <div className="w-full h-full overflow-auto">
        <div className="w-full h-auto flex items-center justify-center flex-wrap gap-4">
          {allSalonStaff.map((staff) => (
            <StaffItem
              key={staff.id}
              staffInfo={staff}
              handleSelectStaff={handleSelectStaff}
            />
          ))}
        </div>
      </div>

      <StaffAccessSettingDialog
        staffInfo={showStaffSetting}
        handleClose={() => setShowStaffSetting(false)}
      />
    </>
  );
}

function AccessSettingPage() {
  const [fetchAllStaffState, setFetchAllStaffState] = useState({
    isLoading: true,
    data: null,
    error: null,
  });

  const loadAllSalonStaff = useCallback(() => {
    setFetchAllStaffState({
      isLoading: true,
      data: null,
      error: null,
    });

    getAllSalonStaffs()
      .then((res) => {
        setFetchAllStaffState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchAllStaffState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, []);

  useEffect(() => {
    loadAllSalonStaff();
  }, [loadAllSalonStaff]);

  return (
    <DefaultLayout>
      <MainContentLayout pageTitle={<p>システム</p>}>
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
            <h2 className="text-base lg:text-xl font-semibold">
              アクセス権
            </h2>
          </div>

          <div className="w-full h-full py-3">
            <div className="w-full h-full pr-3 border border-gray-400 rounded-2xl p-4 relative">
              <div className="w-full h-full overflow-auto">
                {fetchAllStaffState.isLoading ? (
                  <LoadingProgress />
                ) : null}

                {!fetchAllStaffState.isLoading &&
                fetchAllStaffState.error ? (
                  <LoadInitError error={fetchAllStaffState.error} />
                ) : null}

                {!fetchAllStaffState.isLoading &&
                !fetchAllStaffState.error ? (
                  Array.isArray(fetchAllStaffState.data) &&
                  fetchAllStaffState.data.length > 0 ? (
                    <AccessSettingPageContent
                      allSalonStaff={fetchAllStaffState.data}
                    />
                  ) : (
                    <NoData />
                  )
                ) : null}
              </div>
            </div>
          </div>

          <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
            <Link
              to={Path.systemManagement}
              className="button-size bg-gray-btn-secondary"
            >
              戻る
            </Link>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default AccessSettingPage;
