import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

const RadioComponent = ({
  value,
  handleSelect,
  errors,
  touched,
  options,
}) => {
  return (
    <div className="!mr-0">
      <RadioGroup
        value={`${value}`}
        onChange={(event) => {
          handleSelect(event.target.value);
        }}
        disabled={value !== null ? true : false}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={option.label}
            className="!mr-0"
          />
        ))}
      </RadioGroup>
      {touched && errors ? (
        <p className="w-full !text-red-600 !text-[12px]">
          <span className="w-4 h-4 mr-1 text-base leading-4 shrink-0">
            <ErrorIcon
              fontSize="inherit"
              className="text-xs text-red-600 font-thin"
            />
          </span>
          <span>{errors}</span>
        </p>
      ) : null}
    </div>
  );
};

export default RadioComponent;
