import client from "../client";

export const createNewMenuSalon = (newMenuSalonData) => {
  return client
    .post("/salon/salon-menu/create", newMenuSalonData)
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result,
        };
      }
      return { data: {} };
    });
};

export const updateMenuSalon = (id, newMenuSalonData) => {
  return client
    .put(`/salon/salon-menu/update?id=${id}`, newMenuSalonData)
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result,
        };
      }
      return { data: {} };
    });
};

export const getSalonMenu = (id) => {
  return client
    .get("/salon/salon-menu/get", {
      params: { id: id },
    })
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result,
        };
      }
      return { data: [] };
    });
};

export const createNewSalon = (newSalonData) => {
  return client.post("/salon/create", newSalonData).then((res) => {
    if (res.data.success) {
      return {
        data: res.data.result,
      };
    }
    return { data: {} };
  });
};

export const deleteMenu = (menuId) => {
  return client.delete(`/salon/salon-menu/delete?id=${menuId}`);
};
export const updateSalon = (id, newSalonData) => {
  return client
    .put(`/salon/update?id=${id}`, newSalonData)
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result,
        };
      }
      return { data: {} };
    })
    .catch((error) => {
      throw error;
    });
};

export const getSalon = (id) => {
  return client
    .get("/salon/get", {
      params: { id: id },
    })
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result,
        };
      }
      return { data: [] };
    })
    .catch((error) => {
      throw error;
    });
};
export const getAccessRight = (salonStaffId) => {
  return client
    .get("/access-right/get", {
      params: { salonStaffId: salonStaffId },
    })
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result,
        };
      }
      return { data: [] };
    });
};

export const getAllSalonStaff = () => {
  return client.get("/salon/salon-staff/all").then((res) => {
    if (res.data.success) {
      return {
        data: res.data.result,
      };
    }
    return { data: [] };
  });
};

export const createAccessRightForStaff = (
  salonStaffId,
  accessData
) => {
  return client
    .post(`/access-right/create`, {
      salonStaffId: salonStaffId,
      lstAccess: accessData,
    })
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result,
        };
      }
      return { data: [] };
    });
};
