const UserPointItem = ({
  point = 0,
  textLeft = "",
  textRight = "",
  isTablet
}) => {
  if (isTablet) {
    return (
      <div className="mb-1 w-full h-auto grid grid-rows-3 grid-cols-12 grid-flow-col gap-0">
        <div className="row-start-2 row-span-2 h-[25px]">
          <div className="text-center">{point}</div>
        </div>
        <div className="col-span-11 text-xs sm:text-sm flex justify-between ">
          <span className="font-bold">{textLeft}</span>
          <span className="">{textRight}</span>
        </div>
        <div className="row-span-2 col-span-11 h-[25px]">
          <div className="w-full h-full flex gap-1">
            {[...Array(10)].map((_, index) => (
              <div
                key={index}
                className={`w-full h-full ${
                  index + 1 > point ? "bg-[#D9D9D9]" : "bg-[#AAD5FD]"
                }`}
              ></div>
            ))}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="mb-5 w-full h-auto grid grid-rows-3 grid-cols-12 grid-flow-col gap-0">
      <div className="row-start-2 row-span-2 self-center">
        <div className="text-center">{point}</div>
      </div>
      <div className="col-span-11 text-xs sm:text-sm flex justify-between ">
        <span className="font-bold">{textLeft}</span>
        <span className="">{textRight}</span>
      </div>
      <div className="row-span-2 col-span-11">
        <div className="w-full h-full flex gap-1">
          {[...Array(10)].map((_, index) => (
            <div
              key={index}
              className={`w-full h-full ${
                index + 1 > point ? "bg-[#D9D9D9]" : "bg-[#AAD5FD]"
              }`}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UserPointItem;
