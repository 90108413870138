import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import DefaultLayout from "app/layouts/DefaultLayout";
import ProductOrder from "app/components/Product/ProductOrder";
import { labProducts, labProductSalonOrder } from "app/services/api";
// import BackBtn from "app/components/common/BackButton";
import InputField from "app/components/commonUI/InputField";
import Input from "app/components/common/Input";
import { useFormik } from "formik";
import * as Yup from "yup";
import Path from "app/route/Path";
import GA, {
  EVENT_NAMES,
} from "../../../../services/googleAnalytics";

function LabProductOrderProductDetailPage(props) {
  const { productId } = useParams();
  const [orderAmount, setOrderAmount] = useState("");
  const [orderMemo, setOrderMemo] = useState("");
  const [productItem, setProductItem] = useState();

  const navigate = useNavigate();

  const getProductById = async (productId) => {
    const response = await labProducts.find(productId);
    setProductItem(response.data);

    await labProductSalonOrder.all();
  };

  useEffect(() => {
    getProductById(productId);
  }, [productId]);

  const validationSchema = Yup.object().shape({
    orderAmount: Yup.string().required("注文個数"),
  });

  const onSubmit = async (values) => {
    const isConfirmed = window.confirm(
      `商品名 : ${productItem.name} \n\n${JSON.stringify(
        values.orderAmount
      )}個を注文しますか`
    );

    if (isConfirmed) {
      await labProductSalonOrder
        .create(values)
        .then(() => {
          GA.tracking(EVENT_NAMES.PRODUCT_DETAIL.ORDER, {
            amount: values.orderAmount,
            productId: values.scalpLabProductId,
          });
          navigate(Path.labProductOrder("orderInfo"));
        })
        .catch((e) => {
          alert(e);
        })
        .finally(() => {});

      console.log("注文しました");
    } else {
      console.log("注文を中止しました");
    }
  };

  const {
    handleSubmit,
    errors,
    values,
    setFieldValue,
    // resetForm,
    touched,
    handleBlur,
  } = useFormik({
    initialValues: {
      orderAmount: orderAmount || "",
      orderStatus: "waiting",
      scalpLabProductId: productItem?._id || "",
      memo: orderMemo || "",
    },
    onSubmit,
    validationSchema,
  });

  return (
    productItem && (
      <DefaultLayout
        headerProps={{
          headerClassName: "md:!block",
          pageTitle: productItem.name,
        }}
      >
        <ProductOrder>{productItem}</ProductOrder>
        <div className="w-full h-auto pl-3 pr-3 mb-5 flex py-5">
          <InputField
            label="注文個数"
            name="orderAmount"
            value={values.orderAmount}
            type="text"
            onChange={(newValue) => {
              setFieldValue(
                "orderAmount",
                Number(newValue.replace(/[^0-9.]/g, ""))
              );
              setFieldValue("scalpLabProductId", productItem?._id);
              setOrderAmount(newValue);
            }}
            handleBlur={handleBlur}
            touched={touched.orderAmount}
            errors={errors.orderAmount}
            labelClasses="!w-24"
          />
        </div>

        <div className="w-full h-auto pl-3 pr-3 mb-5 flex py-5">
          <label
            for="memo"
            className="block shrink-0 font-semibold leading-9 !w-24"
          >
            備考
          </label>
          <Input.MultilineField
            name="memo"
            className="focus:outline-[#1976d2] w-full border border-[#cbcbcb] hover:border-gray-800 rounded text-base px-3 py-[5px]"
            value={values.memo}
            setValue={(newValue) => {
              console.log(newValue);
              setFieldValue("memo", newValue);
              setFieldValue("scalpLabProductId", productItem?._id);
              setOrderMemo(newValue);
            }}
          ></Input.MultilineField>
        </div>
        <div className="w-full h-auto pr-3 mb-5 flex justify-end">
          <Link
            to={Path.home}
            className="button-size bg-gray-btn-secondary mr-2"
          >
            戻る
          </Link>
          <button
            onClick={() => {
              handleSubmit();
            }}
            type="button"
            className="button-size bg-blue-btn-primary"
          >
            発注
          </button>
        </div>
      </DefaultLayout>
    )
  );
}

export default LabProductOrderProductDetailPage;
