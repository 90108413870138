import scalpDataClassABImage from "app/assets/images/Class_A_B.png";
import scalpDataClassCDImage from "app/assets/images/Class_C_D.png";
import scalpDataClassEFImage from "app/assets/images/Class_E_F.png";
import touhiClassAImage from "app/assets/images/touhiimage-a.png";
import touhiClassBImage from "app/assets/images/touhiimage-b.png";
import touhiClassCImage from "app/assets/images/touhiimage-c.png";
import touhiClassDImage from "app/assets/images/touhiimage-d.png";
import touhiClassEImage from "app/assets/images/touhiimage-e.png";

export const CLUSTER = {
  A_CLASS: "1",
  B_CLASS: "2",
  C_CLASS: "3",
  D_CLASS: "4",
  E_CLASS: "5",
  F_CLASS: "6",
};

export const BACKGROUND_COLOR_BY_CLUSTER = {
  [CLUSTER.A_CLASS]: "#ADCCDEB3",
  [CLUSTER.B_CLASS]: "#BCD3D1B3",
  [CLUSTER.C_CLASS]: "#E8E2B7B3",
  [CLUSTER.D_CLASS]: "#E8CEA5B3",
  [CLUSTER.E_CLASS]: "#E3C1B2B3",
  [CLUSTER.F_CLASS]: "#E28E88B3",
};

export const BORDER_COLOR_BY_CLUSTER = {
  [CLUSTER.A_CLASS]: "#1B81BD",
  [CLUSTER.B_CLASS]: "#20957E",
  [CLUSTER.C_CLASS]: "#B09A2F",
  [CLUSTER.D_CLASS]: "#CD8B2B",
  [CLUSTER.E_CLASS]: "#A1593B",
  [CLUSTER.F_CLASS]: "#C53125",
};

export const AVERAGE_VALUE_BY_CLUSTER = {
  [CLUSTER.A_CLASS]: [6.51, 7.43, 7.39],
  [CLUSTER.B_CLASS]: [5.13, 5.37, 5.65],
  [CLUSTER.C_CLASS]: [4.92, 3.02, 7.53],
  [CLUSTER.D_CLASS]: [4.74, 2.51, 4.08],
  [CLUSTER.E_CLASS]: [2.74, 4.97, 7.85],
  [CLUSTER.F_CLASS]: [2.48, 2.96, 5.11],
};

export const IMAGE_URL_BY_CLUSTER = {
  [CLUSTER.A_CLASS]: scalpDataClassABImage,
  [CLUSTER.B_CLASS]: scalpDataClassABImage,
  [CLUSTER.C_CLASS]: scalpDataClassCDImage,
  [CLUSTER.D_CLASS]: scalpDataClassCDImage,
  [CLUSTER.E_CLASS]: scalpDataClassEFImage,
  [CLUSTER.F_CLASS]: scalpDataClassEFImage,
};

export const IMAGE_URL_TOUHI_CLASS = {
  [CLUSTER.A_CLASS]: touhiClassAImage,
  [CLUSTER.B_CLASS]: touhiClassBImage,
  [CLUSTER.C_CLASS]: touhiClassCImage,
  [CLUSTER.D_CLASS]: touhiClassDImage,
  [CLUSTER.E_CLASS]: touhiClassEImage,
};

export const THUMB_BACKGROUND_BY_CLUSTER = {
  [CLUSTER.A_CLASS]: "#D8EAFF",
  [CLUSTER.B_CLASS]: "#D8FFEF",
  [CLUSTER.C_CLASS]: "#F0FFD8",
  [CLUSTER.D_CLASS]: "#FFF2D8",
  [CLUSTER.E_CLASS]: "#FFDAD8",
  [CLUSTER.F_CLASS]: "#E28E88",
};

export const TEXT_BY_CLUSTER = {
  [CLUSTER.A_CLASS]: "A",
  [CLUSTER.B_CLASS]: "B",
  [CLUSTER.C_CLASS]: "C",
  [CLUSTER.D_CLASS]: "D",
  [CLUSTER.E_CLASS]: "E",
  [CLUSTER.F_CLASS]: "F",
};

export const DEFAULT_INFO = {
  THUMB_BACKGROUND: "#000",
  IMAGE_URL: scalpDataClassEFImage,
  TEXT: "?",
};

export const DEFAULT_RADAR_DATA = {
  labels: ["細胞面積", "多重剥離", "カルボニル"],
  datasets: [
    {
      label: "# of Votes",
      data: [0, 0, 0],
      backgroundColor: "rgba(157, 193, 226, 0.7)",
    },
  ],
};

export const EXPECTED_HEIGHT = 500;
export const EXPECTED_RATIO = 4 / 3;
