import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Path from "../../../route/Path";
import moment from "moment";
import GA, { EVENT_NAMES } from "../../../services/googleAnalytics";

export default function TableBatchDelivery(props) {
  const navigate = useNavigate();
  const { allHistoryOrdering } = props;

  const DELIVERY_STATUS = {
    PENDING: "未発送",
    DELIVEING: "発送中",
    DONE: "発送完了",
    UNKNOWN: "未知",
  };

  return (
    <div className="w-full h-full relative overflow-auto min-w-[900px]">
      {/* header */}
      <div className="sticky top-0 left-0 w-full h-14 flex bg-gray-100 border-b z-10">
        <div className="w-1/6 flex items-center justify-center shrink-0 truncate">
          管理番号
        </div>
        <div className="w-1/6 flex items-center justify-center shrink-0 truncate">
          検体数
        </div>
        <div className="w-1/6 flex items-center justify-center shrink-0 truncate">
          発送状況
        </div>
        <div className="w-1/6 flex items-center justify-center shrink-0 truncate">
          発送日時
        </div>
        <div className="w-1/6 flex items-center justify-center shrink-0 truncate"></div>
      </div>

      {/* body */}
      {allHistoryOrdering.map((row, index) => (
        <div className="w-full h-14 flex bg-[#F0F1EF] bg-opacity-50 border-b">
          <div className="w-1/6 flex items-center justify-center">
            {row.manageCode}
          </div>
          <div className="w-1/6 flex items-center justify-center">
            {row.inspectionKitIds.length}
          </div>
          <div className="w-1/6 flex items-center justify-center">
            {row.salonDeliverStatus === "pending"
              ? DELIVERY_STATUS.PENDING
              : row.salonDeliverStatus === "delivering"
              ? DELIVERY_STATUS.DELIVEING
              : row.salonDeliverStatus === "done"
              ? DELIVERY_STATUS.DONE
              : DELIVERY_STATUS.UNKNOWN}
          </div>
          <div className="w-1/6 flex items-center justify-center">
            {row.salonDeliverDate
              ? moment(row.salonDeliverDate).format(
                  "YYYY-MM-DD HH:mm"
                )
              : "-"}
          </div>
          <div className="w-1/6 flex items-center justify-center">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                GA.tracking(EVENT_NAMES.BATCH_DELIVER.CLICK_DETAIL);
                navigate(Path.batchDeliverScalpLabDetail(row?._id));
              }}
            >
              管理
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
}
