export const TYPE_DATAS = [
  {
    typeName: "A",
    title: "健常な頭皮の状態",
    description: `優先ケア：頭皮のエイジングケア、美髪ケア
    豊かな毛髪を育む土台が整っていている状態。
    健康的な頭皮を維持するケアに加えて、なりたい髪に向けた積極的なケアがおすすめ。`,
    backgroundClass: "bg-[#D8EAFF]",
  },
  {
    typeName: "B",
    title: "頭皮に酸化ストレスがある状態",
    description: `優先ケア：酸化ダメージを抑えるケア、美髪ケア
    酸化ダメージを抑える事で、豊かな毛髪を育む土台が整う状態。
    紫外線を浴びてダメージをうけた後は、丁寧な酸化ダメージ抑制ケアがおすすめ。`,
    backgroundClass: "bg-[#D8FFEF]",
  },
  {
    typeName: "C",
    title: "頭皮に乾燥がある状態",
    description: `優先ケア：保湿ケア、美髪ケア
    頭皮にうるおいを与える事で、豊かな毛髪を育む土台が整う状態。
    オイルなどで頭皮を保護し、強い洗浄力のシャンプーを控える。乾燥しやすい季節は、より丁寧な保湿ケアがおすすめ。`,
    backgroundClass: "bg-[#F0FFD8]",
  },
  {
    typeName: "D",
    title: "頭皮に酸化ストレスと乾燥がある状態",
    description: `優先ケア：酸化ダメージを抑えるケア、保湿ケア
    酸化ダメージを抑えて、適切な保湿ケアをする事で、健やかな頭皮に近づく。
    紫外線を浴びてダメージをうけた後は、丁寧な酸化ダメージ抑制ケアがおすすめ。
    オイルなどで頭皮を保護し、強い洗浄力のシャンプーを控える。毛髪が乾燥しやすい季節は、より丁寧な保湿ケアがおすすめ。`,
    backgroundClass: "bg-[#FFF2D8]",
  },
  {
    typeName: "E",
    title: "頭皮にターンオーバーの乱れ、乾燥、酸化ストレスがある状態",
    description: `優先ケア：頭皮環境を整えるケア
    炎症している頭皮を保護しながら、健やかな頭皮が生まれる環境を整える。
    ヘアカラーや紫外線などの酸化ダメージを避け、炎症を抑えながら、ターンオーバーを正常化する事で、豊かな毛髪が育まれやすい土壌を目指します。`,
    backgroundClass: "bg-[#FFDAD8]",
  },
];
