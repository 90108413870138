import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Popover from "@mui/material/Popover";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
// import SearchIcon from "@mui/icons-material/Search";
import { setAuthUser, setAuthToken } from "app/redux/authReducer";
import GA, { EVENT_NAMES } from "../../../services/googleAnalytics";
import defaultAvatar from "app/assets/svg/default_avatar.svg";
// import logo from "app/assets/images/logo.png";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBell } from "@fortawesome/free-solid-svg-icons";

function Header({
  headerClassName,
  // setShowMobileNoti
}) {
  const [loggedInMenuAnchorEl, setLoggedInMenuAnchorEl] =
    useState(null);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth?.user);
  const openLoggedInMenu = (e) => {
    setLoggedInMenuAnchorEl(e.currentTarget);
  };

  const closeLoggedInMenu = () => {
    setLoggedInMenuAnchorEl(null);
  };

  const handleLogout = () => {
    closeLoggedInMenu();
    const token = {
      accessToken: null,
      refreshToken: null,
    };
    GA.logout();
    GA.tracking(EVENT_NAMES.HEADER.CLICK_LOGOUT);
    dispatch(setAuthUser(null));
    dispatch(setAuthToken(token));
  };

  return (
    <>
      <div
        className={`sticky top-0 inset-x-0 z-20 w-full h-20 lg:h-20 border-gray-200 bg-transparent ${
          headerClassName || ""
        }`}
      >
        <div className="w-full h-full p-[10%] py-3 flex items-end justify-between">
          <div className="flex-grow flex items-center justify-start">
            <Link
              onClick={() => GA.tracking(EVENT_NAMES.HEADER.NAV_HOME)}
              to="/"
              className="flex items-center"
            >
              {/* <img className="h-7 lg:h-8 mr-4" src={logo} alt="Logo" /> */}

              <h2 className="text-base sm:text-lg md:text-xl font-semibold">
                <span className="text-white font-sans">TOUHI</span>
                <span>LABO</span>
              </h2>
            </Link>
          </div>
          {/* <div className="w-1/3 h-12 border border-[#dddddd] rounded-full shadow-[0_4px_12px_4px_rgba(0,0,0,0.088)] relative py-1 pl-12 pr-3 hidden md:block">
            <div className="absolute top-1/2 left-1 -translate-y-1/2 w-10 h-10 p-1 rounded-full bg-gray-200">
              <SearchIcon classes={{ root: "!text-[32px]" }} />
            </div>
            <input
              className="w-full h-full !outline-none text px-1"
              placeholder="検索窓..."
            />
          </div> */}
          <div className="flex flex-1 items-center justify-end min-w-0">
            <button
              // type="button"
              className="ml-6 flex items-center border rounded-full p-1 hover:shadow-md"
              // style={{ "-webkit-appearance": "none" }}
              onClick={openLoggedInMenu}
            >
              <div className="relative w-[30px] h-[30px] rounded-full bg-[#F0F1EF] bg-opacity-50">
                <img
                  src={defaultAvatar}
                  alt="no-avatar"
                  className="w-full h-full rounded-full overflow-hidden"
                  onError={(event) => {
                    event.target.src = defaultAvatar;
                  }}
                />
              </div>
            </button>

            {/* <button
              type="button"
              className="ml-4 flex items-center border rounded-full p-1 hover:shadow-md"
              onClick={() => setShowMobileNoti(true)}
            >
              <div className="relative w-[30px] h-[30px] rounded-full bg-[#F0F1EF] bg-opacity-50 flex items-center justify-center col">
                <FontAwesomeIcon icon={faBell} size="xl" color="#717171" />
              </div>
            </button> */}
          </div>
        </div>
      </div>

      <Popover
        open={Boolean(loggedInMenuAnchorEl)}
        anchorEl={loggedInMenuAnchorEl}
        onClose={closeLoggedInMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          classes: {
            root: "w-[210px] md:w-[220px] !rounded-xl !translate-y-2",
          },
        }}
      >
        <MenuList>
          <MenuItem classes={{ root: "!py-1" }}>
            <p className="">{user?.loginName}</p>
          </MenuItem>
          <MenuItem
            classes={{ root: "!py-1" }}
            onClick={() => handleLogout()}
          >
            <p className="">ログアウト</p>
          </MenuItem>
        </MenuList>
      </Popover>
    </>
  );
}

export default Header;
