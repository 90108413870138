import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { searchAllCustomer } from "app/services/api";
import { FormControl } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import DefaultLayout from "../../../layouts/DefaultLayout";
import { useNavigate } from "react-router-dom";
import MainContentLayout from "../../../layouts/MainContentLayout";
import Path from "../../../route/Path";
import InputField from "../../../components/commonUI/InputField";
import {
  getAllSalonCustomer,
  getSalonCustomer,
} from "../../../services/api/1C";

function SuggestCustomerPage() {
  const navigate = useNavigate();
  const [listCustomer, setListCustomer] = useState();
  const [, setCustomer] = useState();
  const [, setCustomerName] = useState();
  const [, setSearchState] = useState({
    isSearching: false,
    searchConditions: {},
    data: null,
    error: null,
  });

  const loadAllSalonCustomer = async () => {
    getAllSalonCustomer().then((res) => {
      setListCustomer(res.data);
    });
  };

  const loadSalonCustomer = (customerNo) => {
    getSalonCustomer(customerNo).then((res) => {
      if (res.data !== null) {
        setCustomer(res.data);
        setListCustomer([{ customer: res.data, customerNo }]);
        setFieldValue(
          "customerName",
          res.data?.lastNameKanji + res.data?.firstNameKanji
        );
        setFieldValue("salonCustomerId", res.data?.salonCustomer._id);
        setCustomerName(
          res.data?.lastNameKanji + res.data?.firstNameKanji
        );
      }
    });
  };

  const [searchInput, setSearchInput] = useState("");

  const handleSearchInputChange = (event, value) => {
    setSearchInput(value);
  };

  useEffect(() => {
    if (searchInput !== "") {
      searchSalonCustomerByName(searchInput);
    }
  }, [searchInput]);

  const searchSalonCustomerByName = (customerName) => {
    searchAllCustomer({ fullName: customerName })
      .then((res) => {
        const transformedArray = res.data.map((item) => {
          return {
            customer: { ...item },
            customerNo: item.salonCustomers
              ? item.salonCustomers[0].customerNo
              : undefined,
          };
        });

        setListCustomer(transformedArray);

        setSearchState((oldState) => ({
          isSearching: false,
          searchConditions: oldState.customerName,
          data: res.data,
          error: null,
        }));
      })
      .catch((error) => {
        setSearchState((oldState) => ({
          isSearching: false,
          searchConditions: oldState.customerName,
          data: null,
          error: error,
        }));
      });
  };

  useEffect(() => {
    loadAllSalonCustomer();
  }, []);

  const handleAutoCompleteChange = (event, value, reason) => {
    if (value === null) {
      return loadAllSalonCustomer();
    }
    setFieldValue(
      "customerName",
      value?.customer.lastNameKanji + value?.customer.firstNameKanji
    );
    setFieldValue("customerNo", value?.customerNo);
    const salonCustomerId =
      value?.customer?.salonCustomers?.[0]._id ||
      value?.customer?.salonCustomerIds[0];
    setFieldValue("salonCustomerId", salonCustomerId);
  };

  const validationSchema = Yup.object().shape({
    customerName: Yup.string().required("お客様名は必須です。"),
    customerNo: Yup.string().required("お客様No.は必須です。"),
  });

  const formik = useFormik({
    initialValues: {
      customerNo: "",
      customerName: "",
      isConfirmAgreement: true,
      salonCustomerId: "",
    },
    validationSchema,
  });

  const {
    errors,
    values,
    setFieldValue,
    // resetForm,
    touched,
    handleBlur,
  } = formik;

  const handleRegister = () => {
    navigate(Path.scalpLabManagementIntroduction, {
      state: { values },
    });
  };

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>頭皮ラボ</p>
          </>
        }
      >
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
            <h2 className="text-base lg:text-xl font-semibold">
              顧客を選択
            </h2>
          </div>
          <div className="w-full h-full py-3">
            <div className="w-full h-full pr-3 border border-gray-400 rounded-2xl p-4">
              <form className="w-full h-full flex flex-col  pr-3 ">
                <div className="w-full md:w-2/3">
                  <div className="w-full flex h-auto pr-3 mb-5">
                    <label className="block shrink-0 font-semibold !w-24">
                      お客様名
                    </label>
                    <FormControl sx={{ width: "100%" }} size="small">
                      {/* <InputLabel id="demo-select-small-label">
                        メンバー
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={values.customerName}
                        label="メンバー"
                        onChange={handleChange}
                      >
                        {listCustomer?.map((customer) => (
                          <MenuItem
                            key={customer._id}
                            value={
                              customerNameValue ||
                              customer.customer.firstNameKata +
                                customer.customer.lastNameKata
                            }
                            name={customer.customerNo}
                          >
                            {customerNameValue ||
                              customer.customer.firstNameKata +
                                customer.customer.lastNameKata}
                          </MenuItem>
                        ))}
                      </Select> */}
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        // value={customerNameValue? customerNameValue : ""}
                        // inputValue={values.customerName}
                        options={
                          listCustomer
                            ? listCustomer
                            : { label: "default" }
                        }
                        //sx={{ width: 300 }}
                        //getOptionLabel={option => option.customer?.lastNameKanji + option.customer?.firstNameKanji}
                        getOptionLabel={(option) => {
                          const label =
                            option.customer?.lastNameKanji +
                            option.customer?.firstNameKanji;
                          return label;
                        }}
                        renderInput={(params) => {
                          handleSearchInputChange(
                            null,
                            params.inputProps.value
                          );
                          if (listCustomer) {
                            return (
                              <TextField
                                {...params}
                                label="お客様フルネーム"
                              />
                            );
                          } else {
                            return (
                              <TextField
                                {...params}
                                label="読み込み中"
                              />
                            );
                          }
                          //return <TextField {...params} label="お客様フルネーム" />
                        }}
                        onChange={handleAutoCompleteChange}
                        // loading={listCustomer? true : true}
                        // endDecorator={
                        //   listCustomer ? (
                        //     <CircularProgress size="sm" sx={{ bgcolor: 'background.surface' }} />
                        //   ) : null
                        // }
                        disabled={listCustomer ? false : true}
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="flex flex-col md:flex-row gap-0 md:gap-6">
                  <div className="w-full md:w-2/3">
                    <div className="w-full h-auto pr-3 mb-5">
                      <InputField
                        label="お客様No."
                        name="customerNo"
                        value={values.customerNo}
                        type="text"
                        onChange={(newValue) => {
                          setFieldValue("customerNo", newValue);
                          if (newValue) {
                            loadSalonCustomer(newValue);
                          }
                        }}
                        handleBlur={handleBlur}
                        touched={touched.customerNo}
                        errors={errors.customerNo}
                        labelClasses="!w-24"
                      />
                    </div>
                  </div>
                  <div className="w-full justify-end flex items-end md:w-auto md:items-baseline">
                    <button
                      onClick={() => {
                        if (
                          values.customerName !== "" &&
                          values.customerNo !== ""
                        ) {
                          handleRegister();
                        }
                      }}
                      type="button"
                      className={`button-size   ${
                        values.customerName === "" ||
                        values.customerNo === ""
                          ? "bg-gray-btn-secondary"
                          : "bg-blue-btn-primary"
                      }`}
                      disabled={
                        values.customerName === "" ||
                        values.customerNo === ""
                          ? true
                          : false
                      }
                    >
                      登録
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
            <button
              onClick={() => navigate(Path.scalpLabManagement)}
              type="button"
              className="button-size bg-gray-btn-secondary"
            >
              戻る
            </button>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default SuggestCustomerPage;
