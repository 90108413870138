import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";

import {
  getHistoryOfOrderingDetail,
  getScalpProducts,
} from "../../../../services/api/1C";
import DefaultLayout from "../../../../layouts/DefaultLayout";
import MainContentLayout from "../../../../layouts/MainContentLayout";
import LoadingProgress from "../../../../components/commonUI/LoadingProgress";
import LoadInitError from "../../../../components/commonUI/LoadInitError";
import NoData from "../../../../components/commonUI/NoData";
import { SelectItem } from "./SelectItem";
import useDimension from "../../../../hooks/useDimension";
import UserCluster from "./UserCluster";
import UserPoint from "./UserPoint";

import TypesReferenceInfo from "./TypesReferenceInfo";
import { ANALYSE_STATUS } from "../../../../constants";
import { convertClusterToClass } from "./helpers";
import {
  AVERAGE_VALUE_BY_CLUSTER,
  BACKGROUND_COLOR_BY_CLUSTER,
  BORDER_COLOR_BY_CLUSTER,
  DEFAULT_RADAR_DATA,
  EXPECTED_HEIGHT,
  EXPECTED_RATIO,
  IMAGE_URL_TOUHI_CLASS,
} from "./constants";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

function HistoryOfOrderDetailByKitIdPage() {
  const customerNo = useParams();
  const scroll = React.useRef(null);
  const radarRef = React.useRef();

  const [fetchHistoryOrderDetail, setFetchHistoryOrderDetail] =
    useState({
      isLoading: false,
      data: null,
      error: null,
    });
  const [radarData, setRadarData] = useState(DEFAULT_RADAR_DATA);
  const [scalpData, setScalpData] = useState(false);
  const [products, setProducts] = useState([]);

  const touhiImageUrl =
    IMAGE_URL_TOUHI_CLASS[scalpData.factorCluster];

  const isFactorClusterInvalid = scalpData.factorCluster === 0;

  const { width } = useDimension();
  const isTablet = width > 768;

  const labelSize = width > 576 ? 16 : 12;
  const chartHeight =
    (width > EXPECTED_HEIGHT ? EXPECTED_HEIGHT : width) /
    EXPECTED_RATIO;
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
      },
    },
    scales: {
      r: {
        min: 0,
        max: 10,
        beginAtZero: true,
        padding: 0,
        backgroundColor: "rgba(255, 255, 255, 1)",
        ticks: {
          showLabelBackdrop: false,
          stepSize: 2,
        },
        pointLabels: {
          display: true,
          padding: ({ label }) => {
            if (label === "細胞面積") {
              return 6;
            }
            return 0;
          },
          color: "black",
          font: {
            size: labelSize,
          },
        },
      },
    },
  };

  const loadHistoryOrderDetail = () => {
    setFetchHistoryOrderDetail({
      isLoading: true,
      data: null,
      error: null,
    });
    getHistoryOfOrderingDetail(customerNo.customerNo)
      .then((res) => {
        const found = res.data.find(function (item) {
          return (
            item.scalpData.inspectionKitId ===
            customerNo.inspectionKitId
          );
        });
        setFetchHistoryOrderDetail({
          isLoading: false,
          data: found,
          error: null,
        });
      })
      .catch((error) => {
        setFetchHistoryOrderDetail({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  };

  const loadScalpProducts = async () => {
    const res = await getScalpProducts(scalpData?.factorCluster);
    const _products = res.result ?? [];
    setProducts(_products);
  };

  const scrollToBottom = () => {
    scroll.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (radarRef.current) {
      radarRef.current.scales.r.options.pointLabels._proxy.font.size =
        labelSize;
      radarRef.current.canvas.style.height = chartHeight + "px";
    }
  }, [chartHeight, labelSize]);

  useEffect(() => {
    loadHistoryOrderDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (scalpData?.factorCluster) {
      loadScalpProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scalpData]);

  useEffect(() => {
    // 在这里执行你想要的操作，可以访问 fetchHistoryOrderDetail 的最新值
    // console.log(
    //   "fetchHistoryOrderDetail changed:",
    //   fetchHistoryOrderDetail
    // );

    // 例如，检查 isLoading 是否为 true，然后执行相应的操作
    if (fetchHistoryOrderDetail.isLoading) {
      // 执行你的操作...
    } else {
      if (
        fetchHistoryOrderDetail.data?.scalpData?.factorCellArea ==
        null
      ) {
      } else if (
        fetchHistoryOrderDetail.data?.scalpData?.factorPeeling == null
      ) {
      } else if (
        fetchHistoryOrderDetail.data?.scalpData?.factorCP == null
      ) {
      } else {
        const cluster =
          fetchHistoryOrderDetail.data?.scalpData?.factorCluster;

        const backgroundColor =
          BACKGROUND_COLOR_BY_CLUSTER[cluster] ??
          "rgba(157, 193, 226, 0.7)";
        const borderColor = BORDER_COLOR_BY_CLUSTER[cluster] ?? "";
        const averageValue = AVERAGE_VALUE_BY_CLUSTER[cluster] ?? [];

        setRadarData({
          labels: ["細胞面積", "多重剥離", "カルボニル"],
          datasets: [
            {
              label: "average value",
              data: averageValue,

              order: 2,
              borderColor,
              backgroundColor: "transparent",
              borderWidth: 1,
              pointStyle: false,
            },
            {
              label: "# of Votes",
              data: [
                Math.round(
                  fetchHistoryOrderDetail.data?.scalpData
                    ?.factorCellArea
                ),
                Math.round(
                  fetchHistoryOrderDetail.data?.scalpData
                    ?.factorPeeling
                ),
                Math.round(
                  fetchHistoryOrderDetail.data?.scalpData?.factorCP
                ),
              ],

              order: 1,
              backgroundColor,
              borderColor: backgroundColor,
              borderWidth: 1,
              pointStyle: false,
            },
          ],
        });

        setScalpData({
          factorCellArea: Math.round(
            fetchHistoryOrderDetail.data?.scalpData?.factorCellArea
          ),
          factorPeeling: Math.round(
            fetchHistoryOrderDetail.data?.scalpData?.factorPeeling
          ),
          factorCP: Math.round(
            fetchHistoryOrderDetail.data?.scalpData?.factorCP
          ),
          factorScore: Math.round(
            fetchHistoryOrderDetail.data?.scalpData?.factorScore
          ),
          factorCluster: Math.round(
            fetchHistoryOrderDetail.data?.scalpData?.factorCluster
          ),
        });
      }

      if (
        fetchHistoryOrderDetail.data?.scalpData?.analyseStatus ===
          ANALYSE_STATUS.ANALYSE_NOT_STARTED ||
        fetchHistoryOrderDetail.data?.scalpData?.analyseStatus ===
          ANALYSE_STATUS.ANALYSE_IN_PROGRESS
      ) {
        setScalpData({});
      }
    }
  }, [fetchHistoryOrderDetail]);

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>ラボ</p>
          </>
        }
      >
        {isTablet ? (
          <div className="w-full h-full mx-auto relative max-h-full flex flex-wrap pt-8 pb-8 lg:py-12">
            <div className=" text-white font-semibold self-start mb-5">
              <h2 className="text-base lg:text-xl">
                頭皮ラボ判定結果
              </h2>
              <div className="text-xs lg:text-sm">
                Touhilab Determination
              </div>
            </div>
            <div className="w-full h-full py-3 px-2">
              {fetchHistoryOrderDetail.isLoading && (
                <LoadingProgress />
              )}
              {!fetchHistoryOrderDetail.isLoading &&
              fetchHistoryOrderDetail.error &&
              fetchHistoryOrderDetail.data?.inspectionKitId ? (
                <LoadInitError
                  error={fetchHistoryOrderDetail.error}
                />
              ) : null}
              {!fetchHistoryOrderDetail.isLoading &&
              !fetchHistoryOrderDetail.error ? (
                <div className="md:flex gap-6 h-full">
                  <div className="w-full flex flex-col h-1/2 md:h-full gap-6 items-center">
                    {!scalpData ? (
                      <LoadingProgress />
                    ) : (
                      <>
                        {isFactorClusterInvalid ? (
                          <>
                            <div className="mt-4 mb-10 w-full lg:w-[500px]">
                              <div className="bg-[#C8C5C2] p-8 rounded-md">
                                <div className="mb-8 py-2 bg-[#808080]">
                                  <h2 className="text-white text-center text-lg font-bold">
                                    判定不能
                                  </h2>
                                </div>
                                <div className="text-center font-bold">
                                  <p>頭皮判定ができませんでした。</p>
                                  <p>下記の理由が考えられます。</p>
                                  <p>
                                    再度、採取をお願いいたします。
                                  </p>
                                </div>
                              </div>
                              <div className="flex justify-center">
                                <div className="mt-16 grid grid-cols-[18px_1fr]">
                                  <div>・</div>
                                  <div>
                                    採取できている角層が全体的に少ない。
                                  </div>
                                  <div></div>
                                  <div>
                                    頭皮が油っぽい人は特にご注意ください。
                                  </div>
                                  <div>・</div>
                                  <div>
                                    テープの上半分にしか角層が採取できていない。
                                  </div>
                                  <div>・</div>
                                  <div>
                                    テープの真ん中に角層が採取できていない。
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="flex gap-5">
                              <div className="flex-1">
                                <TypesReferenceInfo
                                  type={convertClusterToClass(
                                    scalpData?.factorCluster
                                  )}
                                  factorCluster={
                                    scalpData?.factorCluster
                                  }
                                  isTablet
                                />
                                <div className="w-full flex flex-col items-center mt-5">
                                  <div>◼️判定結果の詳細</div>
                                  <div className="w-full h-px bg-black text-transparent mb-5"></div>
                                  <img
                                    className="object-fit mb-2"
                                    src={touhiImageUrl}
                                    alt="touhi"
                                  />
                                </div>
                              </div>
                              <div className="w-[45%] lg:w-[55%]">
                                <UserPoint
                                  scalpData={scalpData}
                                  isTablet
                                />
                                <div className="w-full h-auto flex flex-col sm:flex-row items-center justify-center">
                                  <div className="w-full h-auto mb-[-50px]">
                                    <Radar
                                      ref={radarRef}
                                      onClick={scrollToBottom}
                                      data={
                                        radarData ? radarData : {}
                                      }
                                      options={chartOptions}
                                      height={chartHeight}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                    <div
                      ref={scroll}
                      className="w-full h-8 flex items-center justify-end text-white text-base font-semibold "
                    >
                      <Link
                        to={-1}
                        className="button-size bg-gray-btn-secondary"
                      >
                        戻る
                      </Link>
                    </div>
                    {!isFactorClusterInvalid && (
                      <SelectItem products={products} isTablet />
                    )}
                  </div>
                </div>
              ) : (
                <NoData />
              )}
            </div>
          </div>
        ) : (
          <div className="w-full h-full mx-auto relative max-h-full flex flex-wrap pt-16 pb-8 lg:py-12">
            <div className="w-full h-16 lg:h-12 flex items-center justify-center absolute inset-x-0 top-0 bg-transparent">
              <div className="flex flex-col items-center justify-center text-white">
                <h2 className="text-base lg:text-xl font-semibold">
                  頭皮ラボ判定結果
                </h2>
                <div className="text-xs lg:text-sm font-semibold">
                  Touhilab Determination
                </div>
              </div>
            </div>
            <div className="w-full h-full py-3 px-2">
              {fetchHistoryOrderDetail.isLoading && (
                <LoadingProgress />
              )}
              {!fetchHistoryOrderDetail.isLoading &&
              fetchHistoryOrderDetail.error &&
              fetchHistoryOrderDetail.data?.inspectionKitId ? (
                <LoadInitError
                  error={fetchHistoryOrderDetail.error}
                />
              ) : null}

              {!fetchHistoryOrderDetail.isLoading &&
              !fetchHistoryOrderDetail.error ? (
                <div className="md:flex gap-6 h-full">
                  <div className="w-full flex flex-col h-1/2 md:h-full gap-6 items-center">
                    {/* <InputField
                        label="お客様No."
                        name="customerNo"
                        value={fetchHistoryOrderDetail.data?.customerNo}
                        disabled={true}
                        labelClasses="!w-24"
                      />
                      <InputField
                        label="お客様名"
                        name="customerName"
                        value={
                          fetchHistoryOrderDetail.data?.scalpData.customerName
                        }
                        disabled={true}
                        labelClasses="!w-24"
                      />
                      <InputField
                        label="キットID"
                        name="inspectionKitId"
                        value={
                          fetchHistoryOrderDetail.data?.scalpData
                            .inspectionKitId
                        }
                        disabled={true}
                        labelClasses="!w-24"
                      />
                      <InputField
                        label="発送時間"
                        name="shippingDateTime"
                        value={moment(
                          fetchHistoryOrderDetail.data?.scalpData?.deliverDate
                        ).format("YYYY-MM-DD HH:mm")}
                        disabled={true}
                        labelClasses="!w-24"
                      />
                      <InputField
                        label="状況"
                        name="situation"
                        value={
                          // fetchHistoryOrderDetail.data?.scalpData
                          //   ?.analyseStatus
                          //   ? fetchHistoryOrderDetail.data?.scalpData
                          //       ?.analyseStatus
                          //   : "pending"
                          fetchHistoryOrderDetail.data?.scalpData
                            .analyseStatus == "analyse_not_started"
                            ? "未分析"
                            : fetchHistoryOrderDetail.data?.scalpData
                                .analyseStatus == "analyse_in_progress"
                            ? "分析中"
                            : fetchHistoryOrderDetail.data?.scalpData
                                .analyseStatus == "analyse_completed"
                            ? "分析完了"
                            : ""
                        }
                        disabled={true}
                        labelClasses="!w-24"
                      /> */}

                    {!scalpData ? (
                      <LoadingProgress />
                    ) : (
                      <>
                        {isFactorClusterInvalid ? (
                          <>
                            <div className="mt-4 mb-10 w-full lg:w-[500px]">
                              <div className="bg-[#C8C5C2] p-8 rounded-md">
                                <div className="mb-8 py-2 bg-[#808080]">
                                  <h2 className="text-white text-center text-lg font-bold">
                                    判定不能
                                  </h2>
                                </div>
                                <div className="text-center font-bold">
                                  <p>頭皮判定ができませんでした。</p>
                                  <p>下記の理由が考えられます。</p>
                                  <p>
                                    再度、採取をお願いいたします。
                                  </p>
                                </div>
                              </div>
                              <div className="flex justify-center">
                                <div className="mt-16 grid grid-cols-[18px_1fr]">
                                  <div>・</div>
                                  <div>
                                    採取できている角層が全体的に少ない。
                                  </div>
                                  <div></div>
                                  <div>
                                    頭皮が油っぽい人は特にご注意ください。
                                  </div>
                                  <div>・</div>
                                  <div>
                                    テープの上半分にしか角層が採取できていない。
                                  </div>
                                  <div>・</div>
                                  <div>
                                    テープの真ん中に角層が採取できていない。
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <UserCluster
                              factorCluster={scalpData?.factorCluster}
                            />
                            <div className="w-full md:w-1/2 h-auto flex flex-col gap-6 items-start justify-center">
                              <div className="w-full h-auto flex flex-col items-center justify-center ">
                                <div>◼️判定結果の詳細</div>
                                <div className="w-full h-px bg-black text-transparent"></div>
                              </div>
                              <img
                                className="object-fit"
                                src={touhiImageUrl}
                                alt="touhi"
                              />
                              <TypesReferenceInfo
                                type={convertClusterToClass(
                                  scalpData?.factorCluster
                                )}
                              />
                              <UserPoint scalpData={scalpData} />
                              <div className="w-full h-auto flex flex-col sm:flex-row items-center justify-center">
                                <div className="w-full h-auto sm:mt-12 mb-[-50px]">
                                  <Radar
                                    ref={radarRef}
                                    onClick={scrollToBottom}
                                    data={radarData ? radarData : {}}
                                    options={chartOptions}
                                    height={chartHeight}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                    {/* <div className="w-full h-1/2 lg:h-full p-4">
                        {fetchHistoryOrderDetail.data?.scalpData
                          ?.analyseStatus === "analyse_completed" ? (
                          <>
                            <p className="whitespace-nowrap px-6 py-4">
                              クラスター　：　
                              {fetchHistoryOrderDetail.data?.scalpData.factorCluster.toFixed(
                                1
                              )}
                            </p>
                            <p className="whitespace-nowrap px-6 py-4">
                              細胞面積　：　
                              {fetchHistoryOrderDetail.data?.scalpData.factorCellArea.toFixed(
                                1
                              )}
                            </p>
                            <p className="whitespace-nowrap px-6 py-4">
                              多重剥離　：　
                              {fetchHistoryOrderDetail.data?.scalpData.factorPeeling.toFixed(
                                1
                              )}
                            </p>
                            <p className="whitespace-nowrap px-6 py-4">
                              CP　：　
                              {fetchHistoryOrderDetail.data?.scalpData.factorCP.toFixed(
                                1
                              )}
                            </p>
                            <p className="whitespace-nowrap px-6 py-4">
                              総合　：　
                              {fetchHistoryOrderDetail.data?.scalpData.factorScore.toFixed(
                                1
                              )}
                            </p>
                          </>
                        ) : (
                          "まだ分析終わってない"
                        )}
                      </div> */}

                    <div
                      ref={scroll}
                      className="w-full md:w-1/2 h-8 flex items-center justify-end text-white text-base font-semibold "
                    >
                      <Link
                        to={-1}
                        className="button-size bg-gray-btn-secondary"
                      >
                        戻る
                      </Link>
                    </div>
                    {!isFactorClusterInvalid && (
                      <SelectItem products={products} />
                    )}
                  </div>
                </div>
              ) : (
                <NoData />
              )}
            </div>
          </div>
        )}
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default HistoryOfOrderDetailByKitIdPage;
