import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Path from "../../../route/Path";
import {
  getAllFirstImpression,
  createNewFirstImpression,
} from "app/services/api";
import DefaultLayout from "../../../layouts/DefaultLayout";
import MainContentLayout from "../../../layouts/MainContentLayout";
import SelectingBlock from "../../../components/Customer1StImpressionAndFactCheckPage/SelectingBlock";
import CreateCompleteDialog from "../../../components/CommonDialog/CreateCompleteDialog";
import { questions } from "./constants";
import GA, { EVENT_NAMES } from "../../../services/googleAnalytics";

function Customer1StImpressionAndFactCheckPage() {
  const { customerId, stepId } = useParams();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState(1);
  const [inputData, setInputData] = useState({});
  const [selectedButtons, setSelectedButtons] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [loadFirstImpressionState, setLoadFirstImpressionState] =
    useState({
      isLoading: false,
      data: null,
      error: null,
    });

  const findQuestionById = (id) =>
    questions[stepId - 1].find((question) => question.id === id);

  const handleInputChange = (e, fieldName) => {
    const { value } = e.target;
    const question = findQuestionById(fieldName);
    setInputData((prevInputData) => ({
      ...prevInputData,
      [fieldName]: { question: question?.text, answer: value },
    }));
  };

  const handleCreateNewFirstImpression = (combinedData) => {
    // setCreatingCustomerState((oldState) => ({
    //   ...oldState,
    //   isCreating: true,
    //   error: null,
    //   res: null,
    // }));
    createNewFirstImpression({
      id: customerId,
      survey: combinedData,
      // customerId: customerId,
    })
      .then((res) => {
        console.log(res);
        // setCreatingCustomerState((oldState) => ({
        //   ...oldState,
        //   isCreating: false,
        //   error: null,
        //   res: res,
        // }));
      })
      .catch((error) => {
        console.log("error: ", error);
        setIsOpenDialog(false);
        // setCreatingCustomerState((oldState) => ({
        //   ...oldState,
        //   isCreating: false,
        //   error: null,
        //   res: error,
        // }));
      });
  };

  const loadAllFirstImpression = () => {
    setLoadFirstImpressionState({
      isLoading: true,
      data: null,
      error: null,
    });

    getAllFirstImpression(customerId)
      .then((res) => {
        console.log(res);
        setSelectedButtons(
          res.data[0].survey.selectedButtons
            ? res.data[0].survey.selectedButtons
            : {}
        );
        setInputData(
          res.data[0].survey.inputData
            ? res.data[0].survey.inputData
            : {}
        );
        setLoadFirstImpressionState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setLoadFirstImpressionState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  };

  const handleButtonClick = (buttonName, buttonIndex) => {
    const question = findQuestionById(buttonName);
    const currentAnswers = selectedButtons[buttonName]?.answers || [];
    const isMultiSelect = question?.isMultiSelect;

    if (isMultiSelect) {
      // 多选逻辑
      const indexInCurrentAnswers =
        currentAnswers.indexOf(buttonIndex);

      if (indexInCurrentAnswers !== -1) {
        const updatedAnswers = currentAnswers.filter(
          (answer) => answer !== buttonIndex
        );

        setSelectedButtons((prevSelectedButtons) => ({
          ...prevSelectedButtons,
          [buttonName]: {
            question: question?.text,
            answers: updatedAnswers,
          },
        }));
      } else {
        setSelectedButtons((prevSelectedButtons) => ({
          ...prevSelectedButtons,
          [buttonName]: {
            question: question?.text,
            answers: [...currentAnswers, buttonIndex],
          },
        }));
      }
    } else {
      // 单选逻辑
      const currentAnswer = selectedButtons[buttonName]?.answer;
      // 如果当前按钮已经是活动状态（已被选择）
      if (currentAnswer === buttonIndex) {
        // 取消选择（重置为null或空字符串，取决于你的数据结构）
        setSelectedButtons((prevSelectedButtons) => ({
          ...prevSelectedButtons,
          [buttonName]: { question: question?.text, answer: "" },
        }));
      } else {
        setSelectedButtons((prevSelectedButtons) => ({
          ...prevSelectedButtons,
          [buttonName]: {
            question: question?.text,
            answer: buttonIndex,
          },
        }));
      }
    }
  };

  const handleNextSection = () => {
    setSelectedValue(parseInt(stepId) + 1);
    GA.tracking(EVENT_NAMES.FIRST_IMPRESSION.CLICK_NEXT, {
      step: parseInt(stepId) + 1,
    });
    navigate(
      Path.customerManagementFactCheck(
        customerId,
        parseInt(stepId) + 1
      )
    );
  };

  const handleChange = (e) => {
    GA.tracking(EVENT_NAMES.FIRST_IMPRESSION.CLICK_NEXT, {
      step: e.target.value,
    });
    setSelectedValue(e.target.value);
    navigate(
      Path.customerManagementFactCheck(customerId, e.target.value)
    );
  };

  const handleCreate = () => {
    handleCreateNewFirstImpression({
      selectedButtons,
      inputData,
    });
    GA.tracking(EVENT_NAMES.FIRST_IMPRESSION.SUBMIT, {
      step: stepId,
    });
    navigate(Path.customerManagementDetail(customerId));
  };

  useEffect(() => {
    loadAllFirstImpression();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedValue(Number(stepId));
  }, [stepId]);

  return (
    <>
      <DefaultLayout>
        <MainContentLayout
          key={stepId}
          pageTitle={
            <>
              <p>1stインプレッション</p>
            </>
          }
        >
          <SelectingBlock
            stepId={stepId}
            selectedValue={selectedValue}
            selectedButtons={selectedButtons}
            inputData={inputData}
            handleChange={handleChange}
            handleInputChange={handleInputChange}
            handleButtonClick={handleButtonClick}
            handleNextSection={handleNextSection}
            handleSubmit={() => setIsOpenDialog(true)}
          />
        </MainContentLayout>
      </DefaultLayout>

      <CreateCompleteDialog
        open={isOpenDialog}
        createCompleteTitle="登録しました"
        createCompleteBtnLabel2="終了"
        createCompleteAction2={handleCreate}
      />
    </>
  );
}

export default Customer1StImpressionAndFactCheckPage;
