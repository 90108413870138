import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import DefaultLayout from "app/layouts/DefaultLayout";
import MainContentLayout from "app/layouts/MainContentLayout";
import CreateCustomerForm from "app/components/customerForms/CreateCustomerForm";
import ConfirmActionDialog from "app/components/CommonDialog/ConfirmActionDialog";
import Confirm from "app/components/commonUI/Confirm";

import { getCustomerBasicInfo, updateCustomerInfo } from "app/services/api";
import Path from "app/route/Path";
import LoadInitError from "../../../components/commonUI/LoadInitError";
import LoadingProgress from "../../../components/commonUI/LoadingProgress";

function EditCustomerBasicInfoPage(props) {
  const { customerBasicInfo, customerId } = props;

  const [showDialogConfirm, setShowDialogConfirm] = useState(false);
  const [creatingCustomerState, setCreatingCustomerState] = useState({
    confirmedData: null,
    isCreating: false,
    error: null,
    res: null,
  });

  const navigate = useNavigate();

  const handleCreateNewCustomer = async () => {
    setCreatingCustomerState((oldState) => ({
      ...oldState,
      isCreating: true,
      error: null,
      res: null,
    }));

    return await updateCustomerInfo(
      customerId,
      creatingCustomerState.confirmedData
    );
  };

  const handleCreateSuccess = (res) => {
    setCreatingCustomerState((oldState) => ({
      ...oldState,
      isCreating: false,
      error: null,
      res: res,
    }));
  };

  const handleCreateError = (error) => {
    console.log("error: ", error);

    setCreatingCustomerState((oldState) => ({
      ...oldState,
      isCreating: false,
      error: null,
      res: error,
    }));
  };

  const confirmCustomerData = (confirmedData) => {
    setCreatingCustomerState({
      confirmedData,
      isCreating: false,
      error: null,
      res: null,
    });
  };

  const hanldeBackClick = () => {
    if (!creatingCustomerState.confirmedData) return;

    setCreatingCustomerState({
      confirmedData: null,
      isCreating: false,
      error: null,
      res: null,
    });
  };

  return (
    <>
      <CreateCustomerForm
        customerInfo={customerBasicInfo}
        isReadMode={creatingCustomerState.confirmedData}
        handleCreate={
          creatingCustomerState.confirmedData
            ? () => setShowDialogConfirm(true)
            : null
        }
        confirmCustomerData={confirmCustomerData}
        hanldeBackClick={hanldeBackClick}
      />

      <ConfirmActionDialog
        open={showDialogConfirm}
        handleClose={() => setShowDialogConfirm(false)}
        confirmMessage="confirm create"
        confirmedAction={handleCreateNewCustomer}
        handleSuccess={handleCreateSuccess}
        handleError={handleCreateError}
      />

      <Confirm
        open={creatingCustomerState.res}
        message="登録を完了しました。"
        handleCancel={() => {
          const id = creatingCustomerState.res?.data?.id;
          if (id) {
            navigate(Path.customerManagementDetail(id));
          }
        }}
        cancelTitle="お客様情報へ戻る"
        okTitle="終了"
        handleOk={() => {
          navigate(-1);
        }}
      />
    </>
  );
}

function CustomerBasicInfoPage() {
  const [fetchCustomerBasicInfoState, setFetchCustomerBasicInfoState] =
    useState({
      isLoading: false,
      data: null,
      error: null,
    });

  const { customerId } = useParams();

  const loadCustomerBasicInfo = useCallback(() => {
    setFetchCustomerBasicInfoState({
      isLoading: true,
      data: null,
      error: null,
    });

    getCustomerBasicInfo(customerId)
      .then((res) => {
        setFetchCustomerBasicInfoState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchCustomerBasicInfoState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [customerId]);
  useEffect(() => {
    loadCustomerBasicInfo();
  }, [loadCustomerBasicInfo]);

  return (
    <DefaultLayout>
      <MainContentLayout pageTitle="お客様情報">
        {fetchCustomerBasicInfoState.isLoading ? <LoadingProgress /> : null}

        {!fetchCustomerBasicInfoState.isLoading &&
        fetchCustomerBasicInfoState.error ? (
          <LoadInitError error={fetchCustomerBasicInfoState.error} />
        ) : null}
        {!fetchCustomerBasicInfoState.isLoading &&
        !fetchCustomerBasicInfoState.error ? (
          <EditCustomerBasicInfoPage
            customerBasicInfo={fetchCustomerBasicInfoState.data}
          />
        ) : null}
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default CustomerBasicInfoPage;
