import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";
import DefaultLayout from "app/layouts/DefaultLayout";
import MainContentLayout from "app/layouts/MainContentLayout";
import LoadingProgress from "app/components/commonUI/LoadingProgress";
import LoadInitError from "app/components/commonUI/LoadInitError";
import defaultAvatar from "app/assets/svg/default_avatar.svg";
import Path from "app/route/Path";
import { defaultDateFormat } from "app/constants";
import MonthPickerPopover from "app/components/commonUI/MonthPickerPopover";
import NoData from "app/components/commonUI/NoData";
import { getAllSalonStaffs, getSalonSaleDetail } from "app/services/api";
import { getNameFromFirstLast } from "app/utils";
import { Tooltip } from "@mui/material";
import { statisticalDataByDay } from "app/components/CommonStatisticalChart/dataHandlers";

function SelectMonth(props) {
  const { month, setMonth } = props;

  const [showSelectMonthEl, setShowSelectMonthEl] = useState(null);
  return (
    <>
      <button onClick={(e) => setShowSelectMonthEl(e.currentTarget)}>
        {month ? month.format("YYYY年MM月") : ""}
      </button>
      {showSelectMonthEl ? (
        <MonthPickerPopover
          anchorEl={showSelectMonthEl}
          handleClose={() => setShowSelectMonthEl(null)}
          month={month}
          setMonth={setMonth}
        />
      ) : null}
    </>
  );
}

function StaffItem(props) {
  const { staffInfo, handleClick, active } = props;

  return (
    <button
      type="button"
      onClick={() => handleClick(staffInfo)}
      className={`!w-[100px] flex flex-col items-center py-2 hover:bg-slate-200 hover:shadow-md hover:rounded-md ${
        active === true ? "bg-slate-200 shadow-md rounded-md" : ""
      }`}
    >
      <img
        alt={getNameFromFirstLast(staffInfo) || ""}
        src={staffInfo?.imageUrl || defaultAvatar}
        className="!w-[48px] !h-[48px] mb-1"
      />
      <div className="w-full truncate text-sm">
        {getNameFromFirstLast(staffInfo) || ""}
      </div>
    </button>
  );
}

function SaleManagementTopPageContent(props) {
  const { staffSaleId, setStaffSaleId, staffList, showMonth } = props;

  const [loadSalonSales, setLoadSalonSales] = useState({
    isLoading: true,
    data: null,
    error: null,
  });

  const navigate = useNavigate();
  const handleLoadStaffSaleInfo = useCallback(() => {
    if (!showMonth) return;

    setLoadSalonSales({
      isLoading: true,
      data: null,
      error: null,
    });

    const start = showMonth.format(defaultDateFormat);
    const endDate = showMonth.clone().add(1, "M").format(defaultDateFormat);
    getSalonSaleDetail(start, endDate)
      .then((res) => {
        setLoadSalonSales({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setLoadSalonSales({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [showMonth]);

  useEffect(() => {
    handleLoadStaffSaleInfo();
  }, [handleLoadStaffSaleInfo]);

  const chartData = useMemo(() => {
    if (!Array.isArray(loadSalonSales.data) || !showMonth) return [];

    return statisticalDataByDay(
      loadSalonSales.data,
      showMonth,
      showMonth.clone().add(1, "M")
    );
  }, [loadSalonSales.data, showMonth]);

  return (
    <div className="w-full h-full flex flex-col justify-between">
      <div className="w-full h-[calc(100%-192px)] border border-gray-400 rounded px-6 pt-4 pb-12 relative">
        {loadSalonSales.error && !loadSalonSales.isLoading ? (
          <LoadInitError error={loadSalonSales.error} />
        ) : null}

        {loadSalonSales.isLoading ? <LoadingProgress /> : null}
        {!loadSalonSales.isLoading && !loadSalonSales.error ? (
          <>
            <div className="w-full h-full overflow-auto flex flex-col items-center justify-center">
              {
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={chartData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="label" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="amount" fill="#82ca9d" />
                  </BarChart>
                </ResponsiveContainer>
              }
            </div>

            <Link
              to={Path.detailSaleOfSalonPage}
              className="absolute right-6 bottom-2 px-6 text-white text-base font-semibold py-1 rounded bg-blue-btn-primary"
            >
              詳細
            </Link>
          </>
        ) : null}
      </div>

      <div className="w-full h-[168px] border border-gray-400 rounded pt-4 pb-12 relative">
        <h2 className="absolute -top-3 left-4 text-lg font-semibold bg-[#F0F1EF] bg-opacity-50 px-2">
          個人別売上を見る
        </h2>

        <div className="w-full h-full overflow-auto flex items-center flex-nowrap px-4 gap-4 pb-1 small-scrollbar">
          {staffList.map((item) => (
            <StaffItem
              key={item?.id}
              staffInfo={item}
              handleClick={() => {
                setStaffSaleId(item.id);
              }}
              active={staffSaleId === item.id}
            />
          ))}
        </div>

        <Tooltip title={staffSaleId ? "" : "販売員を選択してください"}>
          <button
            type="button"
            onClick={() => {
              if (staffSaleId)
                navigate(Path.staffManagementIndividualSales(staffSaleId));
            }}
            className={`absolute right-6 bottom-2 button-size ${
              staffSaleId
                ? "bg-blue-btn-primary cursor-pointer"
                : "bg-gray-btn-secondary cursor-default"
            } `}
          >
            選択
          </button>
        </Tooltip>
      </div>
    </div>
  );
}

function SaleManagementTopPage() {
  const [showMonth, setShowMonth] = useState(() =>
    moment(moment().set("date", 1).format(defaultDateFormat), defaultDateFormat)
  );
  const [loadInitStatus, setLoadInitStatus] = useState({
    isLoading: true,
    data: null,
    error: null,
  });
  const [staffSaleId, setStaffSaleId] = useState("");
  const navigate = useNavigate();

  const handleLoadInit = useCallback(() => {
    setLoadInitStatus({
      isLoading: true,
      data: null,
      error: null,
    });

    getAllSalonStaffs()
      .then((res) => {
        setLoadInitStatus({
          isLoading: false,
          data: {
            staffList: res.data,
          },
          error: null,
        });
        if (res.data?.[0]) {
        }
      })
      .catch((error) => {
        setLoadInitStatus({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, []);

  useEffect(() => {
    handleLoadInit();
  }, [handleLoadInit]);

  return (
    <DefaultLayout
      headerProps={{
        headerClassName: "",
      }}
    >
      <MainContentLayout pageTitle="売上">
        <div className="w-full h-full pt-16 lg:pt-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-between absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
            <h2 className="text-base lg:text-xl font-semibold">
              <SelectMonth month={showMonth} setMonth={setShowMonth} />
            </h2>

            <button
              onClick={() => navigate(Path.listStaffMember)}
              type="button"
              className="button-size bg-blue-btn-primary mr-3"
            >
              売上を入力する
            </button>
          </div>

          <div className="w-full h-full py-3 lg:py-0">
            <div className="w-full h-full overflow-auto">
              {loadInitStatus.isLoading ? <LoadingProgress /> : null}

              {!loadInitStatus.isLoading && loadInitStatus.error ? (
                <LoadInitError error={loadInitStatus.error} />
              ) : null}

              {!loadInitStatus.isLoading && !loadInitStatus.error ? (
                Array.isArray(loadInitStatus?.data?.staffList) &&
                loadInitStatus.data.staffList.length > 0 ? (
                  <SaleManagementTopPageContent
                    staffSaleId={staffSaleId}
                    setStaffSaleId={setStaffSaleId}
                    staffList={loadInitStatus.data.staffList}
                    showMonth={showMonth}
                  />
                ) : (
                  <NoData />
                )
              ) : null}
            </div>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default SaleManagementTopPage;
