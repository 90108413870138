import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { v4 } from "uuid";
import DefaultLayout from "app/layouts/DefaultLayout";
import MainContentLayout from "app/layouts/MainContentLayout";
import LoadInitError from "app/components/commonUI/LoadInitError";
import LoadingProgress from "app/components/commonUI/LoadingProgress";
import LoadingBackdrop from "app/components/commonUI/LoadingBackdrop";
import Alert from "app/components/commonUI/Alert";

import { defaultDateFormat } from "app/constants";
import Path from "app/route/Path";
import ReservationForm from "../components/ReservationForm";
import {
  getReservationDetail,
  updateReservationDetail,
} from "../../../services/api";
import { toast } from "react-toastify";
import { convertTimeToNumber } from "../../../utils";

function ReservationEditPageContent(props) {
  const { reservationInfo, reservationId } = props;

  const [editingData, setEditingData] = useState({
    visitDate: null,
    startTime: "",
    staff: null,
    reservationMenus: [],
  });
  const [confirmMode, setConfirmMode] = useState(false);
  const [updateStatus, setUpdateStatus] = useState("idle");

  const navigate = useNavigate();
  useEffect(() => {
    setEditingData({
      visitDate: moment(reservationInfo.visitDate, defaultDateFormat),
      startTime: reservationInfo.startTime,
      staff: reservationInfo.staff,
      customer: reservationInfo.customer,
      reservationMenus: Array.isArray(reservationInfo.reservationMenus)
        ? reservationInfo.reservationMenus.map((item) => ({
            ...item,
            id: item.salonMenuId,
            tempId: v4(),
          }))
        : [],
    });
  }, [reservationInfo]);

  const handleSelectService = (serviceInfo) => {
    setEditingData((oldState) => ({
      ...oldState,
      reservationMenus: [
        ...oldState.reservationMenus,
        { ...serviceInfo, tempId: v4() },
      ],
    }));
  };
  const handleDeleteMenuItem = (serviceInfo) => {
    setEditingData((oldState) => ({
      ...oldState,
      reservationMenus: oldState.reservationMenus.filter(
        (item) => item.tempId !== serviceInfo.tempId
      ),
    }));
  };

  const handleChangeVisitDate = (newVisitDate) => {
    setEditingData((oldState) => ({
      ...oldState,
      visitDate: newVisitDate,
    }));
  };

  const handleChangeStartTime = (newStartTime) => {
    setEditingData((oldState) => ({
      ...oldState,
      startTime: newStartTime,
    }));
  };

  const handleChangeStaff = (newStaff) => {
    setEditingData((oldState) => ({
      ...oldState,
      staff: newStaff,
    }));
  };

  const handleChangeCustomer = (newCustomer) => {
    setEditingData((oldState) => ({
      ...oldState,
      customer: newCustomer,
    }));
  };

  const handleCheckFormValid = () => {
    if (
      !editingData.visitDate ||
      !editingData.startTime ||
      !Array.isArray(editingData.reservationMenus) ||
      editingData.reservationMenus.length === 0
    )
      return;

    setConfirmMode(true);
  };

  const handleCallUpdate = () => {
    if (updateStatus === "success" || updateStatus === "loading") return;

    const durationNumber =
      editingData.reservationMenus.reduce((a, b) => a + b.durationNumber, 0) /
      60;
    const startTime = editingData.startTime;
    const startTimeNumber = convertTimeToNumber(startTime);
    const finishTimeNumber = startTimeNumber + durationNumber;
    const finishTime =
      `0${Math.floor(finishTimeNumber)}`.slice(-2) +
      ":" +
      `0${Math.floor((finishTimeNumber % 1) * 60)}`.slice(-2);

    const data = {
      visitDate: editingData.visitDate.format(defaultDateFormat),
      startTime: startTime,
      finishTime: finishTime,
      salonMenuIds: editingData.reservationMenus.map((item) => item.id),
      staffId: editingData.staff.id,
      customerId: editingData.customer.id,
    };

    setUpdateStatus("loading");
    updateReservationDetail(reservationId, data)
      .then((res) => {
        toast.success("更新に成功");
        setUpdateStatus("success");
      })
      .catch((error) => {
        setUpdateStatus("error");
        toast.error("アップデートに失敗しました");
      });
  };

  return (
    <>
      <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
        <h2 className="text-base lg:text-xl font-semibold"></h2>
      </div>

      <div className="w-full h-full py-3">
        <div className="w-full h-full overflow-auto">
          <div className="w-full h-auto max-w-[880px] mx-auto">
            <div className="w-full max-h-full overflow-auto pr-3">
              <div className="w-full h-full">
                <ReservationForm
                  editingData={editingData}
                  handleSelectService={handleSelectService}
                  handleDeleteMenuItem={handleDeleteMenuItem}
                  handleChangeVisitDate={handleChangeVisitDate}
                  handleChangeStartTime={handleChangeStartTime}
                  handleChangeStaff={handleChangeStaff}
                  handleChangeCustomer={handleChangeCustomer}
                  confirmMode={confirmMode}
                  reservationInfo={reservationInfo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
        {!confirmMode ? (
          <Link
            to={Path.reservationManagementDetail(reservationId)}
            className="button-size bg-gray-btn-secondary mr-4 lg:mr-6"
          >
            戻る
          </Link>
        ) : (
          <button
            type="button"
            onClick={() => setConfirmMode(false)}
            className="button-size bg-gray-btn-secondary mr-4 lg:mr-6"
          >
            戻る
          </button>
        )}

        <button
          type="button"
          onClick={() => {
            if (!confirmMode) handleCheckFormValid();
            else handleCallUpdate();
          }}
          className="button-size bg-blue-btn-primary"
        >
          修正確認
        </button>
      </div>

      <LoadingBackdrop isOpen={updateStatus === "loading"} />

      <Alert
        open={updateStatus === "success"}
        handleOk={() => {
          navigate(Path.reservationManagementDetail(reservationId));
        }}
        message="予約を修正しました"
        okLabel="終了"
      />
    </>
  );
}

function ReservationEditPage() {
  const [fetchDetailReservationState, setFetchDetailReservationState] =
    useState({
      isLoading: false,
      data: null,
      error: null,
    });

  const { reservationId } = useParams();

  const loadToDayReservation = useCallback(() => {
    if (!reservationId) return;
    setFetchDetailReservationState({
      isLoading: true,
      data: null,
      error: null,
    });

    getReservationDetail(reservationId)
      .then((res) => {
        setFetchDetailReservationState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchDetailReservationState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [reservationId]);

  useEffect(() => {
    loadToDayReservation();
  }, [loadToDayReservation]);

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>お客様の</p>
            <p>お帰り</p>
          </>
        }
      >
        <div className="w-full h-full pt-16 pb-8 lg:pt-12 relative">
          {fetchDetailReservationState.isLoading ? <LoadingProgress /> : null}

          {!fetchDetailReservationState.isLoading &&
          fetchDetailReservationState.error ? (
            <LoadInitError error={fetchDetailReservationState.error} />
          ) : null}

          {!fetchDetailReservationState.isLoading &&
          !fetchDetailReservationState.error &&
          fetchDetailReservationState.data ? (
            <>
              <ReservationEditPageContent
                reservationInfo={fetchDetailReservationState.data}
                reservationId={reservationId}
              />
            </>
          ) : null}
        </div>

        {/* <div className="w-full h-full mx-auto relative max-h-full flex flex-wrap py-12">
          <div className="w-full max-h-full">
            <div className="w-full h-full overflow-auto">
              {fetchDetailReservationState.isLoading ? (
                <LoadingProgress />
              ) : null}

              {!fetchDetailReservationState.isLoading &&
              fetchDetailReservationState.error ? (
                <LoadInitError error={fetchDetailReservationState.error} />
              ) : null}

              {!fetchDetailReservationState.isLoading &&
              !fetchDetailReservationState.error &&
              fetchDetailReservationState.data ? (
                <>
                  <ReservationEditPageContent
                    reservationInfo={fetchDetailReservationState.data}
                    reservationId={reservationId}
                  />
                </>
              ) : null}
            </div>
          </div>
        </div> */}
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default ReservationEditPage;
