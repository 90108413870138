import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DefaultLayout from "app/layouts/DefaultLayout";
import MainContentLayout from "app/layouts/MainContentLayout";
import Path from "app/route/Path";
import { toast } from "react-toastify";
import {
  createNewSalon,
  getSalon,
  updateSalon,
} from "../../../services/api/1D";
import CreateCompleteDialog from "../../../components/CommonDialog/CreateCompleteDialog";
import LoadingBackdrop from "../../../components/commonUI/LoadingBackdrop";
import LoadInitError from "../../../components/commonUI/LoadInitError";
import LoadingProgress from "../../../components/commonUI/LoadingProgress";
import CreateSalonForm from "../../../components/salonForm/CreateSalonForm";
import GA, { EVENT_NAMES } from "../../../services/googleAnalytics";

function RegisterSalonFormPage() {
  const { salonId: id = null } = useParams();
  const navigate = useNavigate();
  const [isOpenDialog, setIsOpenDialog] = useState();

  const [creatingSalonState, setCreatingSalonState] = useState({
    confirmedData: null,
    isCreating: false,
    error: null,
    res: null,
  });

  const [fetchSalonDetail, setFetchSalonDetail] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const loadSalonDetail = useCallback(() => {
    setFetchSalonDetail({
      isLoading: true,
      data: null,
      error: null,
    });
    getSalon(id)
      .then((res) => {
        setFetchSalonDetail({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchSalonDetail({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [id]);

  const handleSubmit = async (values) => {
    const handleError = (error) => {
      toast.error(
        "店舗情報の登録に失敗しました。もう一度お試しください。"
      );
      setCreatingSalonState((oldState) => ({
        ...oldState,
        isCreating: false,
        error: null,
        res: error,
      }));
    };

    const handleSuccess = (res) => {
      setCreatingSalonState((oldState) => ({
        ...oldState,
        isCreating: false,
        error: null,
        res: res,
      }));
      setIsOpenDialog(true);
    };

    setCreatingSalonState(async (oldState) => ({
      ...oldState,
      isCreating: true,
      error: null,
      res: null,
    }));
    if (id) {
      try {
        const res = await updateSalon(id, values);
        if (res) {
          GA.tracking(EVENT_NAMES.SALON_EDIT.EDIT_SALON, {
            salonId: id,
          });
          handleSuccess(res);
        }
      } catch (error) {
        handleError(error);
      }
    } else {
      try {
        const res = await createNewSalon(values);
        if (res) {
          handleSuccess(res);
        }
      } catch (error) {
        handleError(error);
      }
    }
  };

  const confirmSalonData = (confirmedData) => {
    setCreatingSalonState({
      confirmedData,
      isCreating: false,
      error: null,
      res: null,
    });
  };

  useEffect(() => {
    if (id) loadSalonDetail();
  }, [id, loadSalonDetail]);

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>システム</p>
          </>
        }
      >
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
            <h2 className="text-base lg:text-xl font-semibold">
              店舗情報
            </h2>
          </div>
          <div className="w-full h-full py-3">
            <div className="w-full h-full pr-3 ">
              <div className="w-full h-full overflow-auto">
                {fetchSalonDetail.isLoading ? (
                  <LoadingProgress />
                ) : null}

                {!fetchSalonDetail.isLoading &&
                fetchSalonDetail.error ? (
                  <LoadInitError error={fetchSalonDetail.error} />
                ) : null}
                {!fetchSalonDetail.isLoading &&
                !fetchSalonDetail.error &&
                id !== "" &&
                !!fetchSalonDetail.data ? (
                  <CreateSalonForm
                    salonId={id}
                    fetchSalonDetail={fetchSalonDetail.data}
                    handleCreate={handleSubmit}
                    confirmSalonData={confirmSalonData}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <CreateCompleteDialog
          open={isOpenDialog}
          createCompleteTitle={
            id ? "店舗情報を変更しました。" : "登録を完了しました。"
          }
          createCompleteBtnLabel1="システム設定へ戻る"
          createCompleteAction1={() =>
            navigate(Path.systemManagement)
          }
          createCompleteBtnLabel2="終了"
          createCompleteAction2={() =>
            navigate(Path.systemManagement)
          }
        />

        <LoadingBackdrop isOpen={creatingSalonState.isCreating} />
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default RegisterSalonFormPage;
