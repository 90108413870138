import React, { useState } from "react";
import CommonDialog from "../../../components/CommonDialog";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Path from "app/route/Path";

function PaymentSuccessDialog(props) {
  const { open } = props;
  const [isNextToSaveCustomerInfo, setIsNextToSaveCustomerInfo] = useState("");
  const navigate = useNavigate();

  const handleChange = (event) => {
    setIsNextToSaveCustomerInfo(event.target.value);
  };

  const handleNextClick = () => {
    if (isNextToSaveCustomerInfo === "yes") {
      navigate(Path.customerManagement);
    } else if (isNextToSaveCustomerInfo === "no") {
      navigate(Path.toDayVisitList);
    }
  };

  return (
    <CommonDialog
      open={open}
      handleClose={() => {}}
      title="Q.お客様情報を入力しますか？"
      actions={
        <div className="w-full flex items-center justify-end text-white text-base font-semibold">
          <button
            onClick={handleNextClick}
            type="button"
            className={`button-size ${
              isNextToSaveCustomerInfo
                ? "bg-blue-btn-primary"
                : "bg-gray-btn-secondary"
            }`}
            disabled={!isNextToSaveCustomerInfo}
          >
            進む
          </button>
        </div>
      }
    >
      <div className="">
        <FormControl>
          <RadioGroup value={isNextToSaveCustomerInfo} onChange={handleChange}>
            <FormControlLabel
              value="yes"
              control={<Radio />}
              label="入力する"
            />
            <FormControlLabel value="no" control={<Radio />} label="終了" />
          </RadioGroup>
        </FormControl>
      </div>
    </CommonDialog>
  );
}

export default PaymentSuccessDialog;
