import UserPointItem from "./Item";

const UserPoint = ({ scalpData, isTablet }) => {
  return (
    <div className="w-full h-auto flex-col">
      <UserPointItem
        point={scalpData?.factorCellArea}
        textLeft="細胞面積"
        textRight="ターンオーバーの指標"
        isTablet={isTablet}
      />
      <UserPointItem
        point={scalpData?.factorCP}
        textLeft="カルボニルタンパク"
        textRight="頭皮酸化ストレスの指標"
        isTablet={isTablet}
      />
      <UserPointItem
        point={scalpData?.factorPeeling}
        textLeft="多重剥離度"
        textRight="乾燥や肌荒れの指標"
        isTablet={isTablet}
      />
    </div>
  );
};

export default UserPoint;
