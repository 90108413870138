import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-regular-svg-icons";
import {
  faChartColumn,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { Avatar } from "@mui/material";

import DefaultLayout from "app/layouts/DefaultLayout";
import MainContentLayout from "app/layouts/MainContentLayout";
import InputField from "app/components/commonUI/InputField";
import LoadInitError from "app/components/commonUI/LoadInitError";
import InputDateField from "app/components/commonUI/InputDateField";
import LoadingProgress from "app/components/commonUI/LoadingProgress";
import ConfirmDeleteDialog from "app/components/CommonDialog/ConfirmDeleteDialog";

import { defaultDateFormat } from "app/constants";
import defaultAvatar from "app/assets/svg/default_avatar.svg";
import {
  getDetailSalonStaff,
  deleteSalonStaff,
} from "app/services/api";
import { getNameFromFirstLast } from "app/utils";
import Path from "app/route/Path";
import { CSVLink } from "react-csv";
import GA, { EVENT_NAMES } from "../../../services/googleAnalytics";

function StaffManagementDetailPageContent(props) {
  const { staffInfo, staffId } = props;
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const navigate = useNavigate();

  const handleDeleteSalonStaff = async () => {
    GA.tracking(EVENT_NAMES.STAFF_DETAIL.DELETE, { staffId });
    return await deleteSalonStaff(staffId);
  };

  return (
    <>
      <div className="w-full h-full max-w-[960px] mx-auto relative max-h-full flex flex-wrap py-10 border border-gray-400 rounded-xl px-3 lg:px-6">
        <div className="w-full h-10 px-3 lg:px-6 flex items-center justify-end absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50 rounded-t-xl">
          {/* <Link
            onClick={() => {
              GA.tracking(EVENT_NAMES.STAFF_DETAIL.CLICK_ATTENDANCE, {
                staffId,
              });
            }}
            to={Path.staffManagementIndividualAttendances(staffId)}
            className="button-size bg-blue-btn-primary mr-4 lg:mr-6"
          >
            <FontAwesomeIcon icon={faCalendarDays} />
          </Link>
          <Link
            onClick={() => {
              GA.tracking(EVENT_NAMES.STAFF_DETAIL.CLICK_SALES, {
                staffId,
              });
            }}
            to={Path.staffManagementIndividualSales(staffId)}
            className="button-size bg-blue-btn-primary"
          >
            <FontAwesomeIcon icon={faChartColumn} />
          </Link> */}
        </div>

        <div className="w-full h-full overflow-auto">
          <div className="w-full h-auto sm:h-full flex justify-between flex-wrap sm:flex-nowrap">
            <div className="w-full sm:w-[160px] h-full flex flex-col items-center sm:py-8">
              <div className="flex flex-col items-center">
                <div className="w-28 h-28 rounded-full">
                  <Avatar
                    alt=""
                    src={staffInfo.imageUrl || defaultAvatar}
                    sx={{
                      width: 112,
                      height: 112,
                      border: "2px solid",
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="w-full sm:w-[calc(100%-180px)] h-full flex flex-col overflow-auto">
              <div className="w-full h-auto flex mb-4 items-end flex-wrap">
                <div className="w-full xl:w-36 h-8 text-lg font-semibold">
                  お名前
                </div>
                <div className="w-full xl:w-[calc(100%-144px)] h-auto xl:pl-6 py-0">
                  <div className="w-full h-auto flex">
                    <div className="w-1/2 h-auto pr-3 mb-4">
                      <InputField
                        label="セイ"
                        name="lastNameKata"
                        value={staffInfo.lastNameKata}
                        type="text"
                        disabled
                      />
                    </div>

                    <div className="w-1/2 h-auto pl-3">
                      <InputField
                        label="メイ"
                        name="firstNameKata"
                        value={staffInfo.firstNameKata}
                        type="text"
                        disabled
                      />
                    </div>
                  </div>

                  <div className="w-full h-auto flex">
                    <div className="w-1/2 h-auto pr-3">
                      <InputField
                        label="姓"
                        name="lastNameKanji"
                        value={staffInfo.lastNameKanji}
                        type="text"
                        disabled
                      />
                    </div>

                    <div className="w-1/2 h-auto pl-3">
                      <InputField
                        label="名"
                        name="firstNameKanji"
                        value={staffInfo.firstNameKanji}
                        type="text"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full h-auto flex mb-4 flex-wrap">
                <div className="w-full xl:w-36 h-8 text-lg font-semibold">
                  生年月日
                </div>
                <div className="w-full xl:w-[calc(100%-144px)] h-auto xl:pl-6 py-0">
                  <div className="w-full h-auto flex gap-8">
                    <InputDateField
                      value={moment(staffInfo.dob, defaultDateFormat)}
                      setValue={() => {}}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="w-full h-auto flex mb-4 flex-wrap">
                <div className="w-full xl:w-36 h-8 text-lg font-semibold">
                  電話番号
                </div>
                <div className="w-full xl:w-[calc(100%-144px)] h-auto xl:pl-6 py-0">
                  <div className="w-full h-auto flex">
                    <div className="w-1/2 h-auto pr-3">
                      <InputField
                        name="phone"
                        value={staffInfo.phone}
                        type="text"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full h-auto flex mb-4 flex-wrap">
                <div className="w-full xl:w-36 h-8 text-lg font-semibold">
                  メールアドレス
                </div>
                <div className="w-full xl:w-[calc(100%-144px)] h-auto xl:pl-6 py-0">
                  <div className="w-full h-auto flex items-center">
                    <InputField
                      name="email"
                      value={staffInfo.email}
                      type="email"
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="w-full h-auto flex mb-4 flex-wrap">
                <div className="w-full xl:w-36 h-8 text-lg font-semibold">
                  住所
                </div>
                <div className="w-full xl:w-[calc(100%-144px)] h-auto xl:pl-6 py-0">
                  <div className="w-full h-auto flex flex-col">
                    <div className="w-full h-auto overflow-hidden mb-4">
                      <InputField
                        name="postalCode"
                        value={staffInfo.postalCode}
                        type="text"
                        disabled
                        inputClasses="!w-[144px]"
                      />
                    </div>
                    <div className="w-full h-auto overflow-hidden">
                      <InputField
                        name="address"
                        value={staffInfo.address}
                        type="text"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full h-auto flex mb-4 flex-wrap">
                <div className="w-full xl:w-36 h-8 text-lg font-semibold">
                  ログイン名
                </div>
                <div className="w-full xl:w-[calc(100%-144px)] h-auto xl:pl-6 py-0">
                  <div className="w-full h-auto flex items-center">
                    <InputField
                      name="email"
                      value={staffInfo.loginName}
                      type="email"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full h-10 px-3 lg:px-6 flex items-center justify-end absolute inset-x-0 bottom-0 bg-[#F0F1EF] bg-opacity-50 rounded-b-xl">
          <button
            onClick={() => setShowConfirmDelete(true)}
            type="button"
            className="button-size bg-red-500 mr-4 lg:mr-6"
          >
            削除
          </button>
          <button
            onClick={() => {
              GA.tracking(EVENT_NAMES.STAFF_DETAIL.CLICK_UPDATE, {
                staffId,
              });
              navigate(Path.staffManagementUpdate(staffId));
            }}
            type="button"
            className="button-size bg-blue-btn-primary"
          >
            変更
          </button>
        </div>
      </div>

      <ConfirmDeleteDialog
        open={showConfirmDelete}
        handleClose={() => setShowConfirmDelete(false)}
        confirmTitle="以下のスタッフ情報を削除してもよろしいですか"
        confirmMessage={getNameFromFirstLast(staffInfo)}
        confirmTitleAgain="本当に削除しますか？"
        confirmMessageAgain=""
        deleteCompleteTitle="スタッフ情報を削除しました"
        deleteCompleteBtnLabel="終了"
        deleteCompleteAction={() => {
          navigate(Path.staffManagementList);
        }}
        deleteAction={handleDeleteSalonStaff}
        confirmCancleLabel="キャンセル"
        confirmCancleAgainLabel="キャンセル"
        confirmLabel="削除"
        confirmAgainLabel="削除"
      />
    </>
  );
}

function StaffManagementDetailPage() {
  const [fetchInitState, setFetchInitState] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const { staffId } = useParams();

  const loadAllShiftPlan = useCallback(() => {
    setFetchInitState({
      isLoading: true,
      data: null,
      error: null,
    });

    getDetailSalonStaff(staffId)
      .then((res) => {
        setFetchInitState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchInitState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [staffId]);

  useEffect(() => {
    loadAllShiftPlan();
  }, [loadAllShiftPlan]);

  const csvData = useMemo(() => {
    const data = [["", ""]];
    if (fetchInitState.data) {
      data.push(["セイ", fetchInitState.data.lastNameKata]);
      data.push(["メイ", fetchInitState.data.firstNameKata]);
      data.push(["姓", fetchInitState.data.lastNameKanji]);
      data.push(["名", fetchInitState.data.firstNameKanji]);
      data.push(["生年月日", fetchInitState.data.dob]);
      data.push(["電話番号", fetchInitState.data.phone]);
      data.push(["メールアドレス", fetchInitState.data.email]);
      data.push(["住所", fetchInitState.data.postalCode]);
      data.push(["", fetchInitState.data.address]);
    }
    return data;
  }, [fetchInitState.data]);

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>予約を</p>
            <p>確認する</p>
          </>
        }
      >
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-between absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
            <h2 className="text-base lg:text-xl font-semibold">
              スタッフ個人情報
            </h2>

            <div className="w-auto flex items-center justify-center">
              <CSVLink
                onClick={() => {
                  GA.tracking(EVENT_NAMES.STAFF_DETAIL.DOWNLOAD_CSV, {
                    staffId,
                  });
                }}
                data={csvData}
                className="button-size bg-blue-btn-primary"
              >
                <FontAwesomeIcon icon={faDownload} />
                <span className="ml-2">エクスポート</span>
              </CSVLink>
            </div>
          </div>

          <div className="w-full h-full py-3">
            <div className="w-full h-full flex items-center justify-center">
              {fetchInitState.isLoading ? <LoadingProgress /> : null}

              {!fetchInitState.isLoading && fetchInitState.error ? (
                <LoadInitError error={fetchInitState.error} />
              ) : null}
              {!fetchInitState.isLoading &&
              !fetchInitState.error &&
              fetchInitState.data ? (
                <StaffManagementDetailPageContent
                  staffInfo={fetchInitState.data}
                  staffId={staffId}
                />
              ) : null}
            </div>
          </div>

          <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
            <Link
              to={Path.staffManagementList}
              className="button-size bg-gray-btn-secondary"
            >
              戻る
            </Link>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default StaffManagementDetailPage;
