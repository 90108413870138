const BottomButtons = ({ handleNextSection, handleSubmit, step }) => {
  if (Number(step) === 8) {
    return (
      <>
        <div className="w-full h-8 my-6 flex items-center justify-center text-white text-base font-semibold">
          <button
            onClick={handleSubmit}
            className="button-size text-white text-base font-semibold bg-opacity-50-btn-primary h-full bg-[#666FC8] shadow-md"
          >
            登録
          </button>
        </div>
        <div className="w-full h-8 my-6 flex items-center justify-center text-white text-base font-semibold"></div>
      </>
    );
  }

  return (
    <>
      <div className="w-full h-8 my-6 flex gap-4 items-center justify-center text-white text-base font-semibold">
        <button
          onClick={handleNextSection}
          className="button-size text-white text-base font-semibold bg-opacity-50-btn-primary h-full bg-[#666FC8] shadow-md"
        >
          次へ
        </button>
        <button
          onClick={handleSubmit}
          className="button-size text-white text-base font-semibold bg-opacity-50-btn-primary h-full bg-[#666FC8] shadow-md"
        >
          ここまでを登録
        </button>
      </div>
      <div className="w-full h-8 my-6 flex items-center justify-center text-white text-base font-semibold"></div>
    </>
  );
};

export default BottomButtons;
