import client from "../client";
import clientFormData from "../clientFormData";
export const getSalonDetail = () => {
  return client.get("/salon/salon-by-account-login").then((res) => {
    if (res.data.success) {
      return {
        data: res.data.result,
      };
    }
    return { data: {} };
  });
};

export const getAllInventory = () => {
  return client.get("/product-inventory/all").then((res) => {
    if (res.data.success) {
      return {
        data: res.data.result,
      };
    }
    return { data: {} };
  });
};

export const createNewInventory = (newInventoryData) => {
  return client.post("/product-inventory/create", newInventoryData);
};

export const updateInventory = (id, updateInventoryData) => {
  return client
    .put(`/product-inventory/update?id=${id}`, {
      inventory: updateInventoryData,
    })
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result,
        };
      }
      return { data: null };
    });
};

export const deleteInventory = (productId) => {
  return client.delete(`/product-inventory/delete?id=${productId}`);
};

export const getInventoryAll = (
  name,
  maker = "",
  series = "",
  productNumber = "",
  startDate = "",
  endDate = ""
) => {
  return client
    .get(
      `/product-inventory/all?name=${name}&maker=${maker}&series=${series}&productNumber=${productNumber}&startDate=${startDate}&endDate=${endDate}`
    )
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result,
        };
      }
      return { data: {} };
    });
};

export const uploadProductImage = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("type", "products");
  try {
    const res = await clientFormData.post("/upload-image", formData);
    return res.data;
  } catch (error) {
    return {
      success: false,
      error: error.response.data,
    };
  }
};

export const updateProduct = (id, productData) => {
  return client
    .put(`/product/update?id=${id}`, productData)
    .then((res) => {
      if (res.data.success) {
        return {
          data: res.data.result,
        };
      }
      return { data: null };
    });
};
