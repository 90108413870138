import { useMemo } from "react";
import {
  DEFAULT_INFO,
  IMAGE_URL_BY_CLUSTER,
  TEXT_BY_CLUSTER,
  THUMB_BACKGROUND_BY_CLUSTER,
} from "../constants";

const UserCluster = (props) => {
  const { factorCluster } = props;

  const { imageUrl, text, thumbBackground } = useMemo(() => {
    const imageUrl = IMAGE_URL_BY_CLUSTER[factorCluster];
    const text = TEXT_BY_CLUSTER[factorCluster];
    const thumbBackground =
      THUMB_BACKGROUND_BY_CLUSTER[factorCluster];
    return { imageUrl, text, thumbBackground };
  }, [factorCluster]);

  return (
    <div className="w-full h-auto md:w-1/2 ">
      <div className="w-full h-auto flex flex-col gap-6">
        {/* <div className="w-full aspect-[3.74/1] bg-[#D8EAFF] flex items-center justify-center gap-3"> */}

        <div
          className={`w-full aspect-[3.74/1] flex items-center justify-center gap-3`}
          style={{
            backgroundColor:
              thumbBackground ?? DEFAULT_INFO.THUMB_BACKGROUND,
          }}
        >
          <div className="py-2">
            <img
              alt="no data"
              className="self-center w-40 h-2/3"
              src={imageUrl ?? DEFAULT_INFO.IMAGE_URL}
            />
          </div>
          <div>
            <div className="h-full text-[16vw] text-[#959798] font-mono">
              {text}
            </div>
          </div>
          <div>
            <div className="h-full text-[5vw] md:text-[3vw] text-[#959798]">
              タイプ
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCluster;
