import React, { useEffect, useMemo, useState } from "react";
import { Avatar } from "@mui/material";
import ConfirmDeleteDialog from "app/components/CommonDialog/ConfirmDeleteDialog";
import ConfirmUpdateDialog from "app/components/CommonDialog/ConfirmUpdateDialog";
import ConfirmCreateDialog from "app/components/CommonDialog/ConfirmCreateDialog";
import {
  deleteStaffShiftPlan,
  registerStaffShiftPlans,
  updateStaffShiftPlan,
} from "app/services/api";
import { convertTimeToNumber, getNameFromFirstLast } from "app/utils";
import defaultAvatar from "app/assets/svg/default_avatar.svg";
import InputTimeFieldLarge from "../components/InputTimeFieldLarge";
import { defaultDateFormat } from "../../../constants";

function DetailStaffShiftPlan(props) {
  const { staffInfo, shiftPlanInfo, backToCalendar, loadAllStaffWithShiftPlan } =
    props;
  const [startTime, setStartTime] = useState(() => {
    return shiftPlanInfo?.startTime || "";
  });
  const [finishTime, setFinishTime] = useState(() => {
    return shiftPlanInfo?.finishTime || "";
  });

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [confirmedDateToUpdate, setConfirmedDateToUpdate] = useState(null);
  const [showConfirmUpdate, setShowConfirmUpdate] = useState(false);

  useEffect(() => {
    if (shiftPlanInfo) {
      setStartTime(shiftPlanInfo?.startTime || "");
      setFinishTime(shiftPlanInfo?.finishTime || "");
    }
  }, [shiftPlanInfo]);

  const isTimeRangeValid = useMemo(() => {
    if (!startTime || !finishTime) return true;
    return convertTimeToNumber(finishTime) >= convertTimeToNumber(startTime);
  }, [startTime, finishTime]);

  const handleDeleteShiftPlan = async () => {
    return await deleteStaffShiftPlan(shiftPlanInfo.id);
  };

  const handleUpdateShiftPlan = async () => {
    const shiftPlanId = shiftPlanInfo.id;
    const workDate = shiftPlanInfo.workDate;

    if (!shiftPlanId || !workDate || !startTime || !finishTime) throw Error();
    return await updateStaffShiftPlan(shiftPlanId, {
      workDate,
      startTime,
      finishTime,
    });
  };

  const handleClickUpdate = () => {
    if (!startTime || !finishTime || !isTimeRangeValid) return;

    setConfirmedDateToUpdate({
      startTime: startTime,
      finishTime: finishTime,
    });
  };

  return (
    <>
      <div className="w-full h-full flex items-center justify-center">
        <div className="w-[760px] h-[360px] border border-gray-400 rounded-2xl px-3 lg:px-8 py-4 lg:py-6 flex">
          <div className="w-1/3 h-full pt-12">
            <div className="w-full h-full flex flex-col items-center">
              <Avatar
                alt=""
                src={staffInfo.imageUrl || defaultAvatar}
                sx={{ width: 96, height: 96, marginBottom: "16px" }}
              />
              <p className="w-full truncate text-center">{getNameFromFirstLast(staffInfo)}</p>
            </div>
          </div>

          <div className="w-2/3 h-full flex flex-col justify-between pl-4 lg:pl-8">
            <div className="w-full h-10 text-3xl font-bold">18/09</div>

            <div className="w-full h-auto flex items-center justify-between relative">
              <div className="w-5/12 h-auto">
                <InputTimeFieldLarge
                  time={startTime}
                  setTime={setStartTime}
                  disabled={confirmedDateToUpdate}
                />
              </div>
              <span>~</span>
              <div className="w-5/12 h-auto">
                <InputTimeFieldLarge
                  time={finishTime}
                  setTime={setFinishTime}
                  disabled={confirmedDateToUpdate}
                />
              </div>

              {!isTimeRangeValid ? (
                <div className="w-full h-auto absolute -bottom-0 inset-x-0 translate-y-full flex items-center text-sm font-medium text-red-500">
                  <p className="">Time range not valid</p>
                </div>
              ) : null}
            </div>

            <div className="w-full h-8 flex justify-end">
              {confirmedDateToUpdate ? (
                <>
                  <button
                    type="button"
                    className="button-size bg-gray-btn-secondary mr-4 lg:mr-6"
                    onClick={() => setConfirmedDateToUpdate(null)}
                  >
                    キャンセル
                  </button>

                  <button
                    type="button"
                    className="button-size bg-blue-btn-primary"
                    onClick={() => setShowConfirmUpdate(true)}
                  >
                    変更
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="button-size bg-red-500 mr-4 lg:mr-6"
                    onClick={() => setShowConfirmDelete(true)}
                  >
                    削除
                  </button>

                  <button
                    type="button"
                    className={`button-size ${
                      isTimeRangeValid && startTime && finishTime
                        ? "bg-blue-btn-primary"
                        : "bg-gray-btn-secondary"
                    }`}
                    onClick={handleClickUpdate}
                  >
                    変更確認
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <ConfirmDeleteDialog
        open={showConfirmDelete}
        handleClose={() => setShowConfirmDelete(false)}
        confirmTitle="勤怠を削除してもよろしいですか？"
        confirmMessage=""
        confirmTitleAgain="本当に削除してもよろしいですか？"
        confirmMessageAgain=""
        deleteCompleteTitle="勤怠を削除しました"
        deleteCompleteBtnLabel="終了"
        deleteCompleteAction={() => {
          backToCalendar();
          loadAllStaffWithShiftPlan();
        }}
        deleteAction={handleDeleteShiftPlan}
        confirmCancleLabel="キャンセル"
        confirmCancleAgainLabel="キャンセル"
        confirmLabel="削除"
        confirmAgainLabel="削除"
      />

      <ConfirmUpdateDialog
        open={confirmedDateToUpdate && showConfirmUpdate}
        handleClose={() => setShowConfirmUpdate(false)}
        confirmTitle="勤務時間を変更したいですか?"
        confirmMessage=""
        updateCompleteTitle="勤怠時間を変更しました"
        updateCompleteBtnLabel="終了"
        updateCompleteAction={() => {
          backToCalendar();
          loadAllStaffWithShiftPlan();
        }}
        updateAction={handleUpdateShiftPlan}
        confirmCancleLabel="キャンセル"
        confirmLabel="変化"
      />
    </>
  );
}

function RegisterStaffShiftPlan(props) {
  const {
    registerStaffShiftPlan,
    backToCalendar,
    loadAllStaffWithShiftPlan,
    workDate,
  } = props;
  const [startTime, setStartTime] = useState("");
  const [finishTime, setFinishTime] = useState("");

  const [confirmedDateToCreate, setConfirmedDateToCreate] = useState(null);
  const [showConfirmCreate, setShowConfirmCreate] = useState(false);

  const isTimeRangeValid = useMemo(() => {
    if (!startTime || !finishTime) return true;
    return convertTimeToNumber(finishTime) >= convertTimeToNumber(startTime);
  }, [startTime, finishTime]);

  const handleRegisterShiftPlan = async () => {
    if (!startTime || !finishTime || !registerStaffShiftPlan.id || !workDate)
      return;

    const newShiftPlan = {
      workDate: workDate.format(defaultDateFormat),
      startTime,
      finishTime,
    };
    return await registerStaffShiftPlans(registerStaffShiftPlan.id, [
      newShiftPlan,
    ]);
  };

  const handleClickUpdate = () => {
    if (!startTime || !finishTime || !isTimeRangeValid) return;

    setConfirmedDateToCreate({
      startTime: startTime,
      finishTime: finishTime,
    });
  };

  return (
    <>
      <div className="w-full h-full flex items-center justify-center">
        <div className="w-[760px] h-[360px] border border-gray-400 rounded-2xl px-3 lg:px-8 py-4 lg:py-6 flex">
          <div className="w-1/3 h-full pt-12">
            <div className="w-full h-full flex flex-col items-center">
              <Avatar
                alt=""
                src={registerStaffShiftPlan.imageUrl || defaultAvatar}
                sx={{ width: 96, height: 96, marginBottom: "16px" }}
              />
              <p className="w-full truncate text-center">{getNameFromFirstLast(registerStaffShiftPlan)}</p>
            </div>
          </div>

          <div className="w-2/3 h-full flex flex-col justify-between pl-4 lg:pl-8">
            <div className="w-full h-10 text-3xl font-bold">18/09</div>

            <div className="w-full h-auto flex items-center justify-between relative">
              <div className="w-5/12 h-auto">
                <InputTimeFieldLarge
                  time={startTime}
                  setTime={setStartTime}
                  disabled={confirmedDateToCreate}
                />
              </div>
              <span>~</span>
              <div className="w-5/12 h-auto">
                <InputTimeFieldLarge
                  time={finishTime}
                  setTime={setFinishTime}
                  disabled={confirmedDateToCreate}
                />
              </div>

              {!isTimeRangeValid ? (
                <div className="w-full h-auto absolute -bottom-0 inset-x-0 translate-y-full flex items-center text-sm font-medium text-red-500">
                  <p className="">Time range not valid</p>
                </div>
              ) : null}
            </div>

            <div className="w-full h-8 flex justify-end">
              {confirmedDateToCreate ? (
                <>
                  <button
                    type="button"
                    className="button-size bg-gray-btn-secondary mr-4 lg:mr-6"
                    onClick={() => setConfirmedDateToCreate(null)}
                  >
                    戻る
                  </button>

                  <button
                    type="button"
                    className="button-size bg-blue-btn-primary"
                    onClick={() => setShowConfirmCreate(true)}
                  >
                    変更確認
                  </button>
                </>
              ) : (
                <button
                  type="button"
                  className={`button-size text-white ${
                    isTimeRangeValid && startTime && finishTime
                      ? "bg-blue-btn-primary"
                      : "bg-gray-btn-secondary"
                  }`}
                  onClick={handleClickUpdate}
                >
                  変更確認
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <ConfirmCreateDialog
        open={confirmedDateToCreate && showConfirmCreate}
        handleClose={() => setShowConfirmCreate(false)}
        confirmTitle="シフト登録をしてみませんか？"
        confirmMessage=""
        createCompleteTitle="勤怠を登録しました"
        createCompleteBtnLabel="終了"
        createCompleteAction={() => {
          backToCalendar();
          loadAllStaffWithShiftPlan();
        }}
        createAction={handleRegisterShiftPlan}
        confirmCancleLabel="キャンセル"
        confirmLabel="作成する"
      />
    </>
  );
}

function ShiftPlanDateForm(props) {
  const {
    detailStaffShiftPlan,
    registerStaffShiftPlan,
    workDate,
    loadAllStaffWithShiftPlan,
    backToCalendar,
  } = props;

  return (
    <div className="w-full h-full mx-auto relative max-h-full flex flex-wrap pt-16 pb-8 lg:py-14 ">
      <div className="w-full h-14 lg:h-8 flex items-center justify-start absolute inset-x-0 top-0 pl-20 lg:pl-0">
        <h2 className="text-base lg:text-xl font-semibold">出勤スタッフ</h2>
      </div>

      <div className="w-full h-full overflow-auto px-2 pb-2">
        {detailStaffShiftPlan ? (
          <DetailStaffShiftPlan
            staffInfo={detailStaffShiftPlan}
            shiftPlanInfo={detailStaffShiftPlan?.shiftplans?.[0]}
            backToCalendar={backToCalendar}
            loadAllStaffWithShiftPlan={loadAllStaffWithShiftPlan}
          />
        ) : null}
        {registerStaffShiftPlan && workDate ? (
          <RegisterStaffShiftPlan
            workDate={workDate}
            registerStaffShiftPlan={registerStaffShiftPlan}
            backToCalendar={backToCalendar}
            loadAllStaffWithShiftPlan={loadAllStaffWithShiftPlan}
          />
        ) : null}
      </div>

      <div className="w-full h-8 flex items-center justify-end absolute inset-x-0 bottom-0">
        <button
          type="button"
          className="button-size bg-gray-btn-secondary"
          onClick={backToCalendar}
        >
          戻る
        </button>
      </div>
    </div>
  );
}
export default ShiftPlanDateForm;
