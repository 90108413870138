import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DefaultLayout from "app/layouts/DefaultLayout";
import MainContentLayout from "app/layouts/MainContentLayout";
import Path from "app/route/Path";
import CreateCompleteDialog from "../../../components/CommonDialog/CreateCompleteDialog";
import LoadingBackdrop from "../../../components/commonUI/LoadingBackdrop";
import CreateMenuForm from "../../../components/salonForm/CreateMenuForm";
import LoadingProgress from "../../../components/commonUI/LoadingProgress";
import LoadInitError from "../../../components/commonUI/LoadInitError";
import {
  createNewMenuSalon,
  getSalonMenu,
  updateMenuSalon,
} from "../../../services/api/1D";
import { toast } from "react-toastify";
import GA, { EVENT_NAMES } from "../../../services/googleAnalytics";

function RegisterMenuFormPage(props) {
  const { menuId } = props;
  const [creatingMenuState, setCreatingMenuState] = useState({
    confirmedData: null,
    isCreating: false,
    error: null,
    res: null,
  });
  const [fetchMenuDetail, setFetchMenuDetail] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const loadMenuDetail = () => {
    setFetchMenuDetail({
      isLoading: true,
      data: null,
      error: null,
    });
    getSalonMenu(menuId)
      .then((res) => {
        setFetchMenuDetail({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchMenuDetail({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  };

  useEffect(() => {
    if (menuId) {
      loadMenuDetail();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();

  const handleCreateNewMenu = () => {
    setCreatingMenuState((oldState) => ({
      ...oldState,
      isCreating: true,
      error: null,
      res: null,
    }));
    if (menuId) {
      updateMenuSalon(menuId, creatingMenuState.confirmedData)
        .then((res) => {
          GA.tracking(EVENT_NAMES.MENU_DETAIL.UPDATE, {
            menuId,
          });
          setCreatingMenuState((oldState) => ({
            ...oldState,
            isCreating: false,
            error: null,
            res: res,
          }));
          toast.success("メニューの更新に成功しました");
        })
        .catch((error) => {
          setCreatingMenuState((oldState) => ({
            ...oldState,
            isCreating: false,
            error: null,
            res: error,
          }));
          toast.error("メニュー更新エラー");
        });
    } else {
      createNewMenuSalon(creatingMenuState.confirmedData)
        .then((res) => {
          GA.tracking(EVENT_NAMES.CREATE_MENU.CREATE);
          setCreatingMenuState((oldState) => ({
            ...oldState,
            isCreating: false,
            error: null,
            res: res,
          }));
          toast.success("メニューの作成が成功しました");
        })
        .catch((error) => {
          setCreatingMenuState((oldState) => ({
            ...oldState,
            isCreating: false,
            error: null,
            res: error,
          }));
          toast.error("メニュー作成エラー");
        });
    }
  };

  const confirmMenuData = (confirmedData) => {
    setCreatingMenuState({
      confirmedData,
      isCreating: false,
      error: null,
      res: null,
    });
  };

  const handleBackClick = () => {
    if (!creatingMenuState.confirmedData)
      return navigate(Path.systemManagementAllMenu);

    setCreatingMenuState({
      confirmedData: null,
      isCreating: false,
      error: null,
      res: null,
    });
  };

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>システム</p>
          </>
        }
      >
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
            <h2 className="text-base lg:text-xl font-semibold">
              新規メニューの登録
            </h2>
          </div>
          <div className="w-full h-full py-3">
            <div className="w-full h-full pr-3 ">
              <div className="w-full h-full overflow-auto">
                {fetchMenuDetail.isLoading ? (
                  <LoadingProgress />
                ) : null}

                {!fetchMenuDetail.isLoading &&
                fetchMenuDetail.error ? (
                  <LoadInitError error={fetchMenuDetail.error} />
                ) : null}
                {!fetchMenuDetail.isLoading &&
                !fetchMenuDetail.error &&
                menuId !== "" &&
                !!fetchMenuDetail.data ? (
                  <CreateMenuForm
                    menuId={menuId}
                    fetchMenuDetail={fetchMenuDetail.data}
                    isReadMode={creatingMenuState.confirmedData}
                    handleCreate={
                      creatingMenuState.confirmedData
                        ? handleCreateNewMenu
                        : null
                    }
                    confirmMenuData={confirmMenuData}
                    handleBackClick={handleBackClick}
                  />
                ) : (
                  <CreateMenuForm
                    fetchMenuDetail={{}}
                    isReadMode={creatingMenuState.confirmedData}
                    handleCreate={
                      creatingMenuState.confirmedData
                        ? handleCreateNewMenu
                        : null
                    }
                    confirmMenuData={confirmMenuData}
                    handleBackClick={handleBackClick}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <CreateCompleteDialog
          open={creatingMenuState.res}
          createCompleteTitle="メニューを登録しました。"
          createCompleteBtnLabel1="システム設定へ戻る"
          createCompleteAction1={() => {
            navigate(Path.systemManagementAllMenu);
          }}
          createCompleteBtnLabel2="終了"
          createCompleteAction2={() => {
            navigate(Path.systemManagement);
          }}
        />

        <LoadingBackdrop isOpen={creatingMenuState.isCreating} />
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default RegisterMenuFormPage;
