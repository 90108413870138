import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  token: {
    accessToken: null,
    refreshToken: null
  },
  showPolicy: true,
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthUser: (state, action) => {
      if (action.payload) {
        state.user = {
          ...state.user,
          ...action.payload,
        }
      } else {
        state.user = action.payload
        state.showPolicy = true
      }
    },
    setAuthToken: (state, action) => {
      state.token = action.payload
    },
    setPolicy: (state, action) => {
      state.showPolicy = action.payload
    },
  },
});

export const { setAuthUser, setAuthToken, setPolicy } = slice.actions;

export default slice.reducer;
