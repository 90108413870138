import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { gettFirstDayOfThisMonth } from "../../../utils";
import DefaultLayout from "app/layouts/DefaultLayout";
import MainContentLayout from "app/layouts/MainContentLayout";
import InputDateField from "app/components/commonUI/InputDateField";
import Path from "app/route/Path";
import CommonStatisticalChart from "../../../components/CommonStatisticalChart";
import { defaultDateFormat, jpDateFormat } from "../../../constants";
import { getSalonSaleDetail } from "../../../services/api";
import { CSVLink } from "react-csv";

function SaleOfStaffMemberPageContent(props) {
  const [rangeToAnalyze, setRangeToAnalyze] = useState(() => ({
    start: gettFirstDayOfThisMonth(),
    end: gettFirstDayOfThisMonth().add(1, "months"),
  }));

  const [loadDataState, setLoadDataState] = useState({
    isLoading: true,
    data: "",
    error: "",
  });

  const [currentChartData, setCurrentChartData] = useState([]);

  const handleChangeStart = (newStart) => {
    if (newStart.diff(rangeToAnalyze.end) >= 0) {
      setRangeToAnalyze({
        start: newStart,
        end: newStart.clone().add(1, "M"),
      });
    } else {
      setRangeToAnalyze((oldState) => ({
        ...oldState,
        start: newStart,
      }));
    }
  };

  const handleChangeEnd = (newEnd) => {
    if (newEnd.diff(rangeToAnalyze.start) <= 0) {
      setRangeToAnalyze({
        start: newEnd.clone().add(-1, "M"),
        end: newEnd,
      });
    } else {
      setRangeToAnalyze((oldState) => ({
        ...oldState,
        end: newEnd,
      }));
    }
  };

  const loadData = useCallback(() => {
    setLoadDataState((oldState) => ({ ...oldState, isLoading: true }));

    getSalonSaleDetail(
      rangeToAnalyze.start.format(defaultDateFormat),
      rangeToAnalyze.end.format(defaultDateFormat)
    )
      .then((res) => {
        setLoadDataState((oldState) => ({
          ...oldState,
          data: res.data,
          isLoading: false,
        }));
      })
      .catch((error) => {
        console.log("error", error);
        setLoadDataState((oldState) => ({
          ...oldState,
          error: error,
          isLoading: false,
        }));
      });
  }, [rangeToAnalyze]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const csvData = useMemo(() => {
    if (Array.isArray(currentChartData)) return currentChartData;
    return [];
  }, [currentChartData]);

  return (
    <>
      <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-between absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
        <h2 className="text-base lg:text-xl font-semibold ml-2">店舗売上</h2>

        <div className="mr-2">
          <CSVLink
            filename={`salon-sale-${rangeToAnalyze.start.format(
              jpDateFormat
            )}-${rangeToAnalyze.end.format(jpDateFormat)}.csv`}
            data={csvData}
            className="button-size bg-blue-btn-primary"
          >
            <FontAwesomeIcon icon={faDownload} />
            <span className="ml-2">エクスポート</span>
          </CSVLink>
        </div>
      </div>

      <div className="w-full h-full py-3 lg:pb-0">
        <div className="w-full h-full overflow-hidden">
          <div className="w-full h-10 flex items-start">
            <div className="w-full flex flex-nowrap">
              <InputDateField
                value={rangeToAnalyze.start}
                setValue={(newStart) => handleChangeStart(newStart)}
              />
              <span className="font-bold text-xl mx-2">~</span>
              <InputDateField
                value={rangeToAnalyze.end}
                setValue={(newEnd) => handleChangeEnd(newEnd)}
              />
            </div>
          </div>

          <div className="w-full h-[calc(100%-40px)]">
            <CommonStatisticalChart
              isLoading={loadDataState.isLoading}
              startTime={rangeToAnalyze.start}
              endTime={rangeToAnalyze.end}
              data={loadDataState.data}
              setCurrentChartData={setCurrentChartData}
            />
          </div>
        </div>
      </div>
    </>
  );
}

function SaleOfStaffMemberPage() {
  return (
    <DefaultLayout>
      <MainContentLayout pageTitle="売上">
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
          <SaleOfStaffMemberPageContent />

          <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
            <Link
              to={Path.saleManagement}
              className="button-size bg-gray-btn-secondary"
            >
              戻る
            </Link>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default SaleOfStaffMemberPage;
