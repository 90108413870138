import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Path from "../../../../route/Path";
import moment from "moment";
import GA, {
  EVENT_NAMES,
} from "../../../../services/googleAnalytics";

export default function TableScalpDataList(props) {
  const navigate = useNavigate();
  const { allHistoryOrdering } = props;

  const CLUSTER = {
    // A_CLASS : "CL1",
    // B_CLASS : "CL2",
    // C_CLASS : "CL3",
    // D_CLASS : "CL4",
    // E_CLASS : "CL5",
    // F_CLASS : "CL6",
    NO_RESULT: "0",
    A_CLASS: "1",
    B_CLASS: "2",
    C_CLASS: "3",
    D_CLASS: "4",
    E_CLASS: "5",
    F_CLASS: "6",
  };

  const getTextFromFactorFluster = (factorClusterString) => {
    let classText = "";
    switch (factorClusterString) {
      case CLUSTER.A_CLASS:
        classText = "A";
        break;
      case CLUSTER.B_CLASS:
        classText = "B";
        break;
      case CLUSTER.C_CLASS:
        classText = "C";
        break;
      case CLUSTER.D_CLASS:
        classText = "D";
        break;
      case CLUSTER.E_CLASS:
        classText = "E";
        break;
      case CLUSTER.F_CLASS:
        classText = "F";
        break;
      default:
        classText = "?";
    }
    return classText + "タイプ";
  };

  return (
    <div className="w-full lg:w-[80%] mx-auto h-full relative overflow-auto min-w-[550px]">
      {/* header */}
      <div className="sticky top-0 left-0 w-full h-14 flex bg-gray-100 border-b z-10">
        <div className="w-[90px] md:w-1/6 flex items-center justify-center shrink-0 truncate">
          登録日時
        </div>
        <div className="w-[90px] md:w-1/6 flex items-center justify-center shrink-0 truncate">
          分析状況
        </div>
        <div className="w-[90px] md:w-1/6 flex items-center justify-center shrink-0 truncate">
          クラスター
        </div>
        <div className="w-[90px] md:w-1/6 flex items-center justify-center shrink-0 truncate"></div>
        <div className="w-[150px] md:w-1/6 flex items-center justify-center shrink-0 truncate">
          検体No.
        </div>
      </div>

      {/* body */}
      {allHistoryOrdering.map((row) => {
        const {
          scalpData: {
            factorCluster = "",
            analyseStatus = "",
            customerNo = "",
            inspectionKitId = "",
          } = {},
        } = row;

        return (
          <div className="w-full h-14 flex bg-[#F0F1EF] bg-opacity-50 border-b">
            <div className="w-[90px] md:w-1/6 pl-1 flex items-center justify-center">
              {row.scalpData.createdAt !== undefined
                ? moment(row.scalpData.createdAt).format(
                    "YYYY-MM-DD HH:mm"
                  )
                : "-"}
            </div>
            <div className="w-[90px] md:w-1/6 flex items-center justify-center">
              {analyseStatus === "analyse_not_started"
                ? "未分析"
                : analyseStatus === "analyse_in_progress"
                ? "分析中"
                : analyseStatus === "analyse_completed"
                ? "分析完了"
                : ""}
            </div>
            <div className="w-[90px] md:w-1/6 flex items-center justify-center">
              {getTextFromFactorFluster(String(factorCluster))}
            </div>
            <div className="w-[90px] md:w-1/6 flex items-center justify-center">
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  GA.tracking(
                    EVENT_NAMES.CUSTOMER_SCALP_LIST.CLICK_ITEM,
                    {
                      customerNo,
                      inspectionKitId,
                    }
                  );
                  navigate(
                    Path.historyOfOrderingDetailByKitId(
                      customerNo,
                      inspectionKitId
                    )
                  );
                }}
              >
                詳細
              </Button>
            </div>
            <div className="w-[150px] md:w-1/6 flex items-center justify-center">
              {inspectionKitId === "" ? "-" : inspectionKitId}
            </div>
          </div>
        );
      })}
    </div>
  );
}
