import React, { useState } from "react";
import DefaultLayout from "../../../layouts/DefaultLayout";
import MainContentLayout from "../../../layouts/MainContentLayout";
import { useNavigate, useLocation } from "react-router-dom";
import Path from "../../../route/Path";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import AgreementContent from "../../../components/AgreementContent";

function ApplicationForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const values = location.state?.values;
  const [checked, setChecked] = useState(false);

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>頭皮ラボ</p>
          </>
        }
      >
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
            <h2 className="text-base lg:text-xl font-semibold">
              同意書
            </h2>
          </div>
          <div className="w-full h-full py-3">
            <div className="w-full flex justify-center h-full pr-3 border border-gray-400 rounded-2xl p-4">
              <div className="w-[1000px] h-full overflow-y-auto overflow-x-hidden pr-4"> 
                <AgreementContent />
              </div>
            </div>
          </div>
          <div className="w-5/6 h-8 flex items-center justify-start text-base font-semibold absolute left-4 bottom-2">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                  />
                }
                label="同意します"
              />
            </FormGroup>
          </div>

          <div className="w-full h-8 flex gap-6 items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
            <button
              onClick={() =>
                navigate(Path.scalpLabManagementIntroduction, {
                  state: { values },
                })
              }
              type="button"
              className="button-size bg-gray-btn-secondary"
            >
              戻る
            </button>
            <button
              disabled={!checked}
              type="button"
              className={`button-size ${
                checked
                  ? " bg-blue-btn-primary"
                  : "bg-gray-btn-secondary"
              } `}
              onClick={() =>
                navigate(
                  Path.scalpLabManagementConfirmApplicationForm,
                  { state: { values } }
                )
              }
            >
              内容確認へ
            </button>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default ApplicationForm;
