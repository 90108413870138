import { useEffect, useState } from "react";

const PER_PAGE = 10;
const DEFAULT_PAGE = 1;
const DEFAULT_TOTAL_DATA = [];

const usePagination = ({ initPage = DEFAULT_PAGE, ...props }) => {
  const totalData = props.totalData ?? DEFAULT_TOTAL_DATA;

  const [showData, setShowData] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);

  const [totalPage, setTotalPage] = useState(0);

  const calculateTotalPage = () => {
    const _totalPage = Math.ceil(totalData.length / PER_PAGE);
    setTotalPage(_totalPage);
  };

  const updateShowData = (page) => {
    const startIndex = (page - 1) * PER_PAGE;
    const endIndex = page * PER_PAGE;
    const _showData = totalData.slice(startIndex, endIndex);
    setShowData(_showData);
  };

  useEffect(() => {
    changeCurrentPage(initPage);
  }, [initPage]);

  useEffect(() => {
    calculateTotalPage();
  }, [totalData, initPage]);

  useEffect(() => {
    updateShowData(currentPage);
  }, [totalData, currentPage]);

  const changeCurrentPage = (page) => {
    setCurrentPage(page);
  };

  return {
    data: showData,
    currentPage,
    totalPage,
    changeCurrentPage,
  };
};

export default usePagination;
