import React, { useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import GoogleMapReact from "google-map-react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import InputField from "app/components/commonUI/InputField";
// import CommonDialog from "../../CommonDialog";
// import GA, { EVENT_NAMES } from "../../../services/googleAnalytics";
import validationSchema from "./validator";

// const MarkerGeoCode = () => (
//   <div className="w-6 h-6 flex items-center justify-center -translate-y-4">
//     <FontAwesomeIcon icon={faLocationDot} size="xl" color="red" />
//   </div>
// );

function CreateSalonForm({
  salonInfo = {},
  handleCreate,
  confirmSalonData,
  fetchSalonDetail,
}) {
  const navigate = useNavigate();
  // const mapRef = useRef();
  // const [openMap, setOpenMap] = useState();
  // const [mapLoaded, setMapLoaded] = useState(false);
  const [center, setCenter] = useState({
    lat:
      typeof fetchSalonDetail.latitude !== "undefined"
        ? Number(fetchSalonDetail.latitude)
        : 35.6801482,
    lng:
      typeof fetchSalonDetail.longitude !== "undefined"
        ? Number(fetchSalonDetail.longitude)
        : 139.7692938,
  });
  const [geoCode, setGeoCode] = useState({
    lat:
      typeof fetchSalonDetail.latitude !== "undefined"
        ? Number(fetchSalonDetail.latitude)
        : 35.6801482,
    lng:
      typeof fetchSalonDetail.longitude !== "undefined"
        ? Number(fetchSalonDetail.longitude)
        : 139.7692938,
  });
  // const [postCodeLocation, setPostCodeLocation] = useState(null);

  const onSubmit = async (values) => {
    const data = {
      ...values,
      latitude: geoCode.lat.toString(),
      longitude: geoCode.lng.toString(),
    };
    confirmSalonData(data);
    handleCreate(data);
  };

  const {
    handleSubmit,
    errors,
    values,
    setFieldValue,
    touched,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: !!fetchSalonDetail
        ? fetchSalonDetail.name
        : salonInfo.name || "",
      phone: !!fetchSalonDetail
        ? fetchSalonDetail.phone
        : salonInfo.phone || "",
      postalCode: !!fetchSalonDetail
        ? fetchSalonDetail.postalCode
        : salonInfo.postalCode || "",
      prefecture: !!fetchSalonDetail
        ? fetchSalonDetail.prefecture
        : salonInfo.prefecture || "",
      city: !!fetchSalonDetail
        ? fetchSalonDetail.city
        : salonInfo.city || "",
      street: !!fetchSalonDetail
        ? fetchSalonDetail.street
        : salonInfo.street || "",
      building: !!fetchSalonDetail
        ? fetchSalonDetail.building
        : salonInfo.building || "",
      workingHours: !!fetchSalonDetail
        ? fetchSalonDetail.workingHours
        : salonInfo.workingHours || "",
      latitude: !!fetchSalonDetail
        ? fetchSalonDetail.latitude
        : center.lat || "",
      longitude: !!fetchSalonDetail
        ? fetchSalonDetail.longitude
        : center.lng || "",
    },
    onSubmit,
    validationSchema,
  });

  const handleSearchByPostalCode = async (postalCode) => {
    try {
      if (window.google?.maps) {
        const geocoder = new window.google.maps.Geocoder();
        const response = await geocoder.geocode({
          address: postalCode,
        });

        if (
          Array.isArray(response.results) &&
          response.results.length > 0
        ) {
          const addressObj = response.results[0];
          const locationFunc = addressObj.geometry.location;
          const location = {
            lat: locationFunc.lat(),
            lng: locationFunc.lng(),
          };
          const addressComponents = addressObj.address_components;
          setCenter(location);
          setGeoCode(location);
          // setPostCodeLocation(location);
          if (addressComponents) {
            for (let i = 0; i < addressComponents.length; i += 1) {
              const addressTypes = addressComponents[i].types;
              if (
                addressTypes.includes("administrative_area_level_1")
              ) {
                setFieldValue(
                  "prefecture",
                  addressComponents[i].long_name
                );
              } else if (
                addressTypes.includes("locality") &&
                addressTypes.includes("political")
              ) {
                setFieldValue("city", addressComponents[i].long_name);
              } else if (
                addressTypes.includes("sublocality_level_2") &&
                addressTypes.includes("sublocality")
              ) {
                setFieldValue(
                  "street",
                  addressComponents[i].long_name
                );
              }
            }
          }
        } else {
          toast.error("郵便番号情報が見つかりません");
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("郵便番号情報が見つかりません");
    }
  };

  const handleSearchByFullAddressCode = async (query) => {
    try {
      if (window.google?.maps) {
        const geocoder = new window.google.maps.Geocoder();
        const response = await geocoder.geocode({
          address: query,
        });

        if (
          Array.isArray(response.results) &&
          response.results.length > 0
        ) {
          const addressObj = response.results[0];
          const locationFunc = addressObj.geometry.location;
          const location = {
            lat: locationFunc.lat(),
            lng: locationFunc.lng(),
          };
          const addressComponents = addressObj.address_components;
          setCenter(location);
          setGeoCode(location);
          // setPostCodeLocation(location);
          if (addressComponents) {
            for (let i = 0; i < addressComponents.length; i += 1) {
              const addressTypes = addressComponents[i].types;
              console.log("addressTypes: ", addressTypes);
              if (
                addressTypes.includes("administrative_area_level_1")
              ) {
                setFieldValue(
                  "prefecture",
                  addressComponents[i].long_name
                );
              } else if (
                addressTypes.includes("locality") &&
                addressTypes.includes("political")
              ) {
                setFieldValue("city", addressComponents[i].long_name);
              } else if (
                addressTypes.includes("sublocality_level_2") &&
                addressTypes.includes("sublocality")
              ) {
                setFieldValue(
                  "street",
                  addressComponents[i].long_name
                );
              }
            }
          }
        } else {
          toast.error("郵便番号情報が見つかりません");
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("郵便番号情報が見つかりません");
    }
  };

  const handlePostalCodeChange = (newValue) => {
    setFieldValue("postalCode", newValue);
    if (typeof newValue === "string" && newValue.match(/^(\d{7})$/)) {
      handleSearchByPostalCode(newValue);
    }
  };

  const handleBlurBuilding = (e) => {
    handleBlur(e);
    const buildingValue = e.target.value;
    if (buildingValue) {
      let query = "";
      if (values.postalCode) query += ` ${values.postalCode}`;
      if (values.prefecture) query += ` ${values.prefecture}`;
      if (values.city) query += ` ${values.city}`;
      if (values.street) query += ` ${values.street}`;
      if (values.building) query += ` ${values.building}`;
      handleSearchByFullAddressCode(query);
    }
  };

  // useEffect(() => {
  //   let timeoutId;
  //   const checkGoogleMapsLoaded = () => {
  //     if (window.google && window.google.maps) {
  //       setMapLoaded(true);
  //     } else {
  //       timeoutId = setTimeout(() => {
  //         clearInterval(intervalId);
  //       }, 5000);
  //     }
  //   };
  //   const intervalId = setInterval(checkGoogleMapsLoaded, 100);
  //   return () => {
  //     clearInterval(intervalId);
  //     clearTimeout(timeoutId);
  //   };
  // }, []);

  // useEffect(() => {
  //   const getLocationFromPostCode = async (postalCode) => {
  //     try {
  //       if (mapLoaded) {
  //         const geocoder = new window.google.maps.Geocoder();
  //         const response = await geocoder.geocode({
  //           address: postalCode,
  //         });
  //         if (
  //           Array.isArray(response.results) &&
  //           response.results.length > 0
  //         ) {
  //           const addressObj = response.results[0];
  //           const locationFunc = addressObj.geometry.location;
  //           const location = {
  //             lat: locationFunc.lat(),
  //             lng: locationFunc.lng(),
  //           };
  //           setPostCodeLocation(location);
  //         }
  //       }
  //     } catch (error) {
  //       console.error(error);
  //       toast.error("郵便番号情報が見つかりません");
  //     }
  //   };

  //   if (fetchSalonDetail?.postalCode) {
  //     getLocationFromPostCode(fetchSalonDetail?.postalCode);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [mapLoaded]);

  return (
    <form className="w-full h-full">
      <div className="w-full h-full flex flex-col justify-between border border-gray-400 rounded-2xl p-4 overflow-auto">
        <InputField
          label="店舗名"
          name="name"
          value={values.name}
          type="text"
          onChange={(newValue) => setFieldValue("name", newValue)}
          handleBlur={handleBlur}
          touched={touched.name}
          errors={errors.name}
          labelClasses="w-[90px]"
        />

        <InputField
          label="電話番号"
          name="phone"
          value={values.phone}
          type="text"
          onChange={(newValue) => setFieldValue("phone", newValue)}
          handleBlur={handleBlur}
          touched={touched.phone}
          errors={errors.phone}
          labelClasses="w-[90px]"
        />
        <InputField
          label="郵便番号"
          name="postalCode"
          value={values.postalCode}
          type="text"
          onChange={(newValue) => handlePostalCodeChange(newValue)}
          handleBlur={handleBlur}
          touched={touched.postalCode}
          errors={errors.postalCode}
          labelClasses="w-[90px]"
        />
        <InputField
          label="都道府県"
          name="prefecture"
          value={values.prefecture}
          type="text"
          onChange={(newValue) =>
            setFieldValue("prefecture", newValue)
          }
          handleBlur={handleBlur}
          touched={touched.prefecture}
          errors={errors.prefecture}
          labelClasses="w-[90px]"
        />
        <InputField
          label="市区町村群"
          name="city"
          value={values.city}
          type="text"
          onChange={(newValue) => setFieldValue("city", newValue)}
          handleBlur={handleBlur}
          touched={touched.city}
          errors={errors.city}
          labelClasses="w-[90px]"
        />
        <InputField
          label="町名"
          name="street"
          value={values.street}
          type="text"
          onChange={(newValue) => setFieldValue("street", newValue)}
          handleBlur={handleBlur}
          touched={touched.street}
          errors={errors.street}
          labelClasses="w-[90px]"
        />
        <InputField
          label="番地ビル名"
          name="building"
          value={values.building}
          type="text"
          onChange={(newValue) => setFieldValue("building", newValue)}
          handleBlur={handleBlurBuilding}
          touched={touched.building}
          errors={errors.building}
          labelClasses="w-[90px]"
        />
        {/* <div className="flex">
          <label className="block shrink-0 font-semibold leading-9 w-[90px]">
            地図設定
          </label>
          <button
            onClick={() => setOpenMap(true)}
            type="button"
            className="w-full px-8 py-1 text-white text-base font-semibold rounded bg-gray-btn-secondary hover:bg-blue-btn-primary"
          >
            設定する
          </button>
        </div>
        <InputField
          label="営業時間"
          name="workingHours"
          value={values.workingHours}
          type="text"
          onChange={(newValue) =>
            setFieldValue("workingHours", newValue)
          }
          handleBlur={handleBlur}
          touched={touched.workingHours}
          errors={errors.workingHours}
          labelClasses="w-[90px]"
        /> */}
      </div>

      <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
        <button
          onClick={() => navigate(-1)}
          type="button"
          className="button-size bg-gray-btn-secondary mr-4 lg:mr-6"
        >
          戻る
        </button>
        <button
          onClick={handleSubmit}
          type="button"
          className="button-size bg-blue-btn-primary"
        >
          登録
        </button>
      </div>

      {/* <CommonDialog
        open={openMap}
        handleClose={() => setOpenMap(false)}
        title={
          <div className="flex flex-col items-center">
            <div>座標指定</div>
            <div>指定したい場所へピンを移動してください。</div>
          </div>
        }
        actions={
          <div className="w-full flex items-center justify-end text-white text-base font-semibold">
            <button
              type="button"
              className="button-size bg-gray-btn-secondary mr-6"
              onClick={() => {
                if (postCodeLocation) {
                  GA.tracking(EVENT_NAMES.SALON_EDIT.CLICK_MAP_RESET);
                  setGeoCode({
                    lat: postCodeLocation.lat,
                    lng: postCodeLocation.lng,
                  });

                  setCenter({
                    lat: postCodeLocation.lat,
                    lng: postCodeLocation.lng,
                  });
                  mapRef.current.panTo({
                    lat: postCodeLocation.lat,
                    lng: postCodeLocation.lng,
                  });
                }
              }}
            >
              住所の住置へ戻す
            </button>
            <button
              onClick={() => {
                GA.tracking(
                  EVENT_NAMES.SALON_EDIT.CHANGE_MAP_LOCATION
                );
                setOpenMap(false);
              }}
              type="button"
              className={`button-size ${
                geoCode
                  ? "bg-blue-btn-primary"
                  : "bg-gray-btn-secondary"
              }`}
            >
              OK
            </button>
          </div>
        }
      >
        <div className="w-full h-80 pb-6">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
            }}
            defaultCenter={center}
            defaultZoom={15}
            onClick={(e) => {
              setGeoCode({
                lat: e.lat,
                lng: e.lng,
              });
              setCenter({
                lat: e.lat,
                lng: e.lng,
              });
              mapRef.current.panTo({
                lat: e.lat,
                lng: e.lng,
              });
            }}
            onGoogleApiLoaded={({ map, ...rest }) =>
              (mapRef.current = map)
            }
          >
            {geoCode ? (
              <MarkerGeoCode
                lat={geoCode.lat}
                lng={geoCode.lng}
                text="My Marker"
              />
            ) : null}
          </GoogleMapReact>
        </div>
      </CommonDialog> */}
    </form>
  );
}

export default CreateSalonForm;
