import { DEFAULT_INFO } from "../constants";

const TypesReferenceInfoItem = ({
  typeName = "",
  title = "",
  description = "",
  backgroundClass = "",
  isTablet,
  imageUrl = "",
  thumbBackground = "",
}) => {
  if (isTablet) {
    return (
      <div className="w-full h-auto flex gap-1">
        <div
          className={`w-[35%] px-2 aspect-[3.74/1] flex items-center justify-center`}
          style={{
            backgroundColor:
              thumbBackground ?? DEFAULT_INFO.THUMB_BACKGROUND,
          }}
        >
          <div className="py-2">
            <img
              alt="no data"
              className="self-center w-[80px] h-2/3"
              src={imageUrl ?? DEFAULT_INFO.IMAGE_URL}
            />
          </div>
          <div>
            <div className="h-full mr-1 text-[20px] text-[#959798] font-mono">
              {typeName}
            </div>
          </div>
          <div>
            <div className="h-full text-[12px] text-[#959798] whitespace-nowrap">
              タイプ
            </div>
          </div>
        </div>
        <div
          className={`text-xs min-[400px]:text-sm w-10/12 p-2 ${backgroundClass}`}
        >
          <p className="font-bold">{title}</p>
          <p className="whitespace-pre-line">{description}</p>
        </div>
      </div>
    );
  }
  return (
    <div className="w-full h-auto flex gap-1">
      <div
        className={`flex items-center justify-center w-2/12 min-w-[70px] min-[400px]:min-w-[80px] min-[500px]:min-w-[90px] min-[630px]:min-w-[100px] gap-1 text-center ${backgroundClass}`}
      >
        <span className="text-[24px] min-[400px]:text-[26px] min-[500px]:text-[32px] min-[630px]:text-[40px]">
          {typeName}
        </span>
        <span className="text-[12px] min-[400px]:text-[14px] min-[500px]:text-[18px] min-[630px]:text-[22px]">
          タイプ
        </span>
      </div>
      <div
        className={`text-xs min-[400px]:text-sm w-10/12 p-2 ${backgroundClass}`}
      >
        <p className="font-bold">{title}</p>
        <p className="whitespace-pre-line">{description}</p>
      </div>
    </div>
  );
};

export default TypesReferenceInfoItem;
