import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Avatar } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";

import DefaultLayout from "app/layouts/DefaultLayout";
import MainContentLayout from "app/layouts/MainContentLayout";
import LoadInitError from "app/components/commonUI/LoadInitError";
import LoadingProgress from "app/components/commonUI/LoadingProgress";
import defaultAvatar from "app/assets/svg/default_avatar.svg";
import { getAllSalonStaffs } from "app/services/api";
import { getNameFromFirstLast } from "app/utils";
import Path from "app/route/Path";
import GA, { EVENT_NAMES } from "../../../services/googleAnalytics";

function StaffManagementListPageContent(props) {
  const { allSalonStaffs } = props;

  useEffect(() => {
    const staffListEl = document.getElementById("staff-list");

    const handleStaffListWheel = (event) => {
      event.preventDefault();

      staffListEl.scrollBy({
        left: event.deltaY < 0 ? -30 : 30,
      });
    };

    if (staffListEl) {
      staffListEl.addEventListener("wheel", handleStaffListWheel);
    }

    return () => {
      if (staffListEl) {
        staffListEl.removeEventListener(
          "wheel",
          handleStaffListWheel
        );
      }
    };
  }, []);

  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="w-full h-auto relative flex items-center justify-center pt-14 pb-8 border border-gray-400 rounded-xl">
        <div className="w-full h-auto flex items-center justify-end absolute top-0 left-0">
          <Link
            onClick={() => {
              GA.tracking(
                EVENT_NAMES.STAFF_LIST.CLICK_CREATE_NEW_STAFF
              );
            }}
            to={Path.staffManagementRegister}
            className="absolute top-4 right-4 lg:right-8 button-size bg-blue-btn-primary"
          >
            <FontAwesomeIcon icon={faAdd} />
            <span className="ml-2">スタッフを登録</span>
          </Link>
        </div>
        <div className="w-auto max-w-full h-60 rounded-xl px-8">
          <div
            id="on-staff-list"
            className="w-auto max-w-full h-full overflow-auto small-scrollbar"
          >
            <div className="w-auto h-full flex items-center justify-start flex-nowrap gap-4">
              {allSalonStaffs.map((staff, index) => (
                <Link
                  key={staff.id}
                  onClick={() => {
                    GA.tracking(
                      EVENT_NAMES.STAFF_LIST.CLICK_STAFF_DETAIL,
                      {
                        staffId: staff.id,
                      }
                    );
                  }}
                  to={Path.staffManagementDetail(staff.id)}
                  className="w-32 h-full rounded-xl shrink-0 bg-slate-100 hover:bg-slate-200 px-2"
                >
                  <div className="w-full h-full flex flex-col items-center justify-center gap-6">
                    <Avatar
                      alt=""
                      src={staff.imageUrl || defaultAvatar}
                      sx={{
                        width: 80,
                        height: 80,
                        border: "solid 2px black",
                      }}
                    />
                    <h3 className="text-lg font-semibold text-center truncate w-full">
                      {getNameFromFirstLast(staff)}
                    </h3>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function StaffManagementListPage() {
  const navigate = useNavigate();
  const [fetchInitState, setFetchInitState] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const loadAllShiftPlan = useCallback(() => {
    setFetchInitState({
      isLoading: true,
      data: null,
      error: null,
    });

    getAllSalonStaffs()
      .then((res) => {
        setFetchInitState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchInitState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, []);

  useEffect(() => {
    loadAllShiftPlan();
  }, [loadAllShiftPlan]);

  return (
    <DefaultLayout>
      <MainContentLayout pageTitle={<p>勤怠</p>}>
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative">
          <div className="w-full h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 top-0 bg-[#F0F1EF] bg-opacity-50">
            <h2 className="text-base lg:text-xl font-semibold">
              スタッフ情報
            </h2>
          </div>

          <div className="w-full h-full py-3">
            <div className="w-full h-full overflow-auto">
              {fetchInitState.isLoading ? <LoadingProgress /> : null}

              {!fetchInitState.isLoading && fetchInitState.error ? (
                <LoadInitError error={fetchInitState.error} />
              ) : null}
              {!fetchInitState.isLoading &&
              !fetchInitState.error &&
              Array.isArray(fetchInitState.data) ? (
                <StaffManagementListPageContent
                  allSalonStaffs={fetchInitState.data}
                />
              ) : null}
            </div>
          </div>

          <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
            <button
              className="button-size bg-gray-btn-secondary"
              onClick={() => navigate(-1)}
            >
              戻る
            </button>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default StaffManagementListPage;
