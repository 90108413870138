import React from "react";

const DEFAULT_IMAGE =
  "https://data-service.pharmacity.io/pmc-upload-media/production/pmc-ecm-core/products/P26349_1.jpg";

export const Item = ({ data }) => {
  const [show, setShow] = React.useState(false);
  const onExpand = () => setShow((arg) => !arg);
  return (
    <div className="w-full bg-white shadow-2xl mb-3">
      <button
        onClick={onExpand}
        className="w-full p-5 bg-white items-center flex"
      >
        <div>
          <img
            src={data?.imageUrl ?? DEFAULT_IMAGE}
            className="w-32 h-32 object-cover"
            alt=""
          />
        </div>
        <div className="ml-5 flex-1">
          <p className="font-semibold text-left">{data?.series}</p>
          <p className="font-semibold text-left">{data?.name}</p>
        </div>
      </button>
      {show && (
        <div className="p-5 border-t-2">
          <div className="flex-col">
            <div className="flex">
              <p className="font-bold w-20">メーカー</p>
              <p className="flex-1">
                <p className="inline font-bold">:</p> {data?.maker}
              </p>
            </div>
            <div className="flex">
              <p className="font-bold w-20">製品名</p>
              <p className="flex-1">
                <p className="inline font-bold">:</p> {data?.series} /
                {data?.name}
              </p>
            </div>
          </div>
          <div className="flex-col mt-6">
            <p className="font-bold mb-2">お店からのコメント:</p>
            <p>{data?.description}</p>
          </div>
        </div>
      )}
    </div>
  );
};
