import { EVENT_NAMES } from "./constants";

class GA {
  static sendEvent({ eventName, propertyObject = {} }) {
    const propertyMap = {
      customerId: "customer_id",
      customerName: "customer_name",
      customerNo: "customer_no",
      inspectionKitId: "inspection_kit_id",
      step: "step",
      amount: "amount",
      salonId: "salon_id",
      productId: "product_id",
      staffId: "staff_id",
      menuId: "menu_id",
    };
    const filteredPropertyObject = {};
    Object.keys(propertyMap).forEach((key) => {
      if (propertyObject[key] && propertyObject[key] !== "") {
        filteredPropertyObject[propertyMap[key]] =
          propertyObject[key];
      }
    });
    // console.log("eventName:", eventName, filteredPropertyObject);
    window.gtag("event", eventName, filteredPropertyObject);
  }

  static login(userId) {
    this.sendEvent({
      eventName: EVENT_NAMES.LOGIN,
      propertyObject: { user_id: userId },
    });
  }

  static logout() {
    this.sendEvent({
      eventName: EVENT_NAMES.LOGOUT,
      propertyObject: { user_id: null },
    });
  }

  static tracking(eventName, propertyObject) {
    this.sendEvent({
      eventName,
      propertyObject,
    });
  }
}

export { EVENT_NAMES };
export default GA;
