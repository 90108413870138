import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DefaultLayout from "app/layouts/DefaultLayout";
import CreateCustomerForm from "app/components/customerForms/CreateCustomerForm";
import CreateCompleteDialog from "app/components/CommonDialog/CreateCompleteDialog";
import LoadingBackdrop from "app/components/commonUI/LoadingBackdrop";
import { createNewCustomer } from "app/services/api";
import Path from "app/route/Path";
import MainContentLayout from "app/layouts/MainContentLayout";

function CreateCustomerInfoPage() {
  const [creatingCustomerState, setCreatingCustomerState] = useState({
    confirmedData: null,
    isCreating: false,
    error: null,
    res: null,
  });

  const navigate = useNavigate();

  const handleCreateNewCustomer = () => {
    setCreatingCustomerState((oldState) => ({
      ...oldState,
      isCreating: true,
      error: null,
      res: null,
    }));

    createNewCustomer(creatingCustomerState.confirmedData)
      .then((res) => {
        setCreatingCustomerState((oldState) => ({
          ...oldState,
          isCreating: false,
          error: null,
          res: res,
        }));
      })
      .catch((error) => {
        console.log("error: ", error);

        setCreatingCustomerState((oldState) => ({
          ...oldState,
          isCreating: false,
          error: null,
          res: error,
        }));
      });
  };

  const confirmCustomerData = (confirmedData) => {
    setCreatingCustomerState({
      confirmedData,
      isCreating: false,
      error: null,
      res: null,
    });
  };

  const hanldeBackClick = () => {
    if (!creatingCustomerState.confirmedData) return navigate(Path.home);

    setCreatingCustomerState({
      confirmedData: null,
      isCreating: false,
      error: null,
      res: null,
    });
  };

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>お客様の</p>
            <p>ご来店</p>
          </>
        }
      >
        <CreateCustomerForm
          isReadMode={creatingCustomerState.confirmedData}
          handleCreate={
            creatingCustomerState.confirmedData ? handleCreateNewCustomer : null
          }
          confirmCustomerData={confirmCustomerData}
          hanldeBackClick={hanldeBackClick}
        />

        <CreateCompleteDialog
          open={creatingCustomerState.res}
          createCompleteTitle="登録を完了しました。"
          createCompleteBtnLabel2="終了"
          createCompleteAction2={() => {
            console.log("creatingCustomerState.res", creatingCustomerState.res);
            const id = creatingCustomerState.res?.data?.id;
            if (id) {
              navigate(Path.createNewReservationAndVisit(id));
            }
          }}
        />

        <LoadingBackdrop isOpen={creatingCustomerState.isCreating} />
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default CreateCustomerInfoPage;
