/**
  NOTICE:
  event name max length is 40 characters
**/
const PAGES = {
  HEADER: "header",
  HOME: "home",
  LOGIN: "login",
  LOGOUT: "logout",
  // customer
  CUSTOMER_MANAGEMENT: "customers",
  CUSTOMER_DETAIL: "customer",
  FIRST_IMPRESSION: "1st-impression",
  SCALP_LAB_MANAGEMENT: "scalplab",
  INVENTORY_MANAGEMENT: "inventory",
  CUSTOMER_SCALP_LIST: "scalp-list",
  // scalp list
  SELECT_CUSTOMER: "select-customer",
  ORDER_FORM: "order-form",
  BATCH_DELIVER: "batch-deliver",
  HISTORY_ORDER: "history-order",
  ORDER_KIT: "order-kit",
  CONTACT_US: "contact-us",
  ORDER_PRODUCTS: "product-order",
  PRODUCT_DETAIL: "product-detail",
  APPLICATION_FORM: "application-form",
  INVENTORY_REGISTER: "inventory-register",
  // system setting
  SYSTEM_MANAGEMENT: "system",
  SALON_EDIT: "system-edit",
  STAFF_LIST: "staff-list",
  MENU_LIST: "menu-list",
  MENU_REGISTER: "menu-register",
  ACCESS: "access-setting",
  // create staff
  CREATE_STAFF: "staff-create",
  // staff detail
  STAFF_DETAIL: "staff-detail",
  // menu detail
  MENU_DETAIL: "menu-detail",
  // create menu
  CREATE_MENU: "menu-create",
  // terms
  TERMS: "terms",
  // privacy policy
  PRIVACY_POLICY: "privacy-policy",
};

const ACTIONS = {
  CLICK: "click",
  SUBMIT: "submit",
  SEARCH: "search",
  CREATE: "create",
  UPDATE: "update",
  DELETE: "delete",
  ORDER: "order",
  DOWNLOAD: "download",
  BACK: "back",
};

const ELEMENTS = {
  DEFAULT: "item",
  LOGIN: "login",
  LOGOUT: "logout",
  HOME: "home",
  CREATE: "create",
  UPDATE: "update",
  DELETE: "delete",
  SEARCH: "search",
  NEXT: "next",
  FORM: "form",
};

export const EVENT_NAMES = {
  // login page
  LOGIN: "login",
  CLICK_LOGIN: `${PAGES.LOGIN}_${ACTIONS.CLICK}_${ELEMENTS.LOGIN}`,
  // header
  LOGOUT: "logout",
  HEADER: {
    CLICK_LOGOUT: `${PAGES.HEADER}_${ACTIONS.CLICK}_${ELEMENTS.LOGOUT}`,
    NAV_HOME: `${PAGES.HEADER}_${ACTIONS.CLICK}_${ELEMENTS.HOME}`,
  },
  // home page
  HOME: {
    CLICK_CUSTOMERS: `${PAGES.HOME}_${ACTIONS.CLICK}_${PAGES.CUSTOMER_MANAGEMENT}`,
    CLICK_SCALP_LAB: `${PAGES.HOME}_${ACTIONS.CLICK}_${PAGES.SCALP_LAB_MANAGEMENT}`,
    CLICK_INVENTORY: `${PAGES.HOME}_${ACTIONS.CLICK}_${PAGES.INVENTORY_MANAGEMENT}`,
    CLICK_SYSTEM: `${PAGES.HOME}_${ACTIONS.CLICK}_${PAGES.SYSTEM_MANAGEMENT}`,
    CLICK_TERMS: `${PAGES.HOME}_${ACTIONS.CLICK}_${PAGES.TERMS}`,
    CLICK_PRIVACY_POLICY: `${PAGES.HOME}_${ACTIONS.CLICK}_${PAGES.PRIVACY_POLICY}`,
  },
  // customers page
  CUSTOMERS: {
    CLICK_CREATE: `${PAGES.CUSTOMER_MANAGEMENT}_${ACTIONS.CLICK}_${ELEMENTS.CREATE}`,
    CREATE: `${PAGES.CUSTOMER_MANAGEMENT}_${ACTIONS.CREATE}_${ELEMENTS.CREATE}`,
    SEARCH: `${PAGES.CUSTOMER_MANAGEMENT}_${ACTIONS.SEARCH}_${ELEMENTS.SEARCH}`,
    CLICK_CUSTOMER_ITEM: `${PAGES.CUSTOMER_MANAGEMENT}_${ACTIONS.CLICK}_${ELEMENTS.DEFAULT}`,
  },
  // customer detail page
  CUSTOMER_DETAIL: {
    CLICK_QR: `${PAGES.CUSTOMER_DETAIL}_${ACTIONS.CLICK}_qrcode`,
    CLICK_UPDATE: `${PAGES.CUSTOMER_DETAIL}_${ACTIONS.CLICK}_${ELEMENTS.UPDATE}`,
    CLICK_CONTACT: `${PAGES.CUSTOMER_DETAIL}_${ACTIONS.CLICK}_contact`,
    CLICK_DELETE: `${PAGES.CUSTOMER_DETAIL}_${ACTIONS.CLICK}_${ELEMENTS.DELETE}`,
    CLICK_FIRST_IMPRESSION: `${PAGES.CUSTOMER_DETAIL}_${ACTIONS.CLICK}_${PAGES.FIRST_IMPRESSION}`,
    CLICK_SCALP_LIST: `${PAGES.CUSTOMER_DETAIL}_${ACTIONS.CLICK}_${PAGES.CUSTOMER_SCALP_LIST}`,
    UPDATE: `${PAGES.CUSTOMER_DETAIL}_${ACTIONS.UPDATE}_${ELEMENTS.DEFAULT}`,
    DELETE: `${PAGES.CUSTOMER_DETAIL}_${ACTIONS.DELETE}_${ELEMENTS.DEFAULT}`,
    CONTACT: `${PAGES.CUSTOMER_DETAIL}_${ACTIONS.SUBMIT}_contact`,
  },
  // first impression page
  FIRST_IMPRESSION: {
    CLICK_NEXT: `${PAGES.FIRST_IMPRESSION}_${ACTIONS.CLICK}_${ELEMENTS.NEXT}`,
    SUBMIT: `${PAGES.FIRST_IMPRESSION}_${ACTIONS.SUBMIT}_${ELEMENTS.FORM}`,
  },
  // customer scalp-list page
  CUSTOMER_SCALP_LIST: {
    CLICK_ITEM: `${PAGES.CUSTOMER_SCALP_LIST}_${ACTIONS.CLICK}_${ELEMENTS.DEFAULT}`,
  },
  // scalp lab management page
  SCALP_LAB_MANAGEMENT: {
    CLICK_SELECT_CUSTOMER: `${PAGES.SCALP_LAB_MANAGEMENT}_${ACTIONS.CLICK}_${PAGES.SELECT_CUSTOMER}`,
    CLICK_ORDER_FORM: `${PAGES.SCALP_LAB_MANAGEMENT}_${ACTIONS.CLICK}_${PAGES.ORDER_FORM}`,
    CLICK_BATCH_DELIVER: `${PAGES.SCALP_LAB_MANAGEMENT}_${ACTIONS.CLICK}_${PAGES.BATCH_DELIVER}`,
    CLICK_HISTORY_ORDER: `${PAGES.SCALP_LAB_MANAGEMENT}_${ACTIONS.CLICK}_${PAGES.HISTORY_ORDER}`,
    CLICK_ORDER_KIT: `${PAGES.SCALP_LAB_MANAGEMENT}_${ACTIONS.CLICK}_${PAGES.ORDER_KIT}`,
    CLICK_CONTACT_US: `${PAGES.SCALP_LAB_MANAGEMENT}_${ACTIONS.CLICK}_${PAGES.CONTACT_US}`,
    CLICK_PRODUCT_ORDERS: `${PAGES.SCALP_LAB_MANAGEMENT}_${ACTIONS.CLICK}_${PAGES.ORDER_PRODUCTS}`,
  },
  // application form page
  APPLICATION_FORM: {
    CONFIRM_AGREEMENT: `${PAGES.APPLICATION_FORM}_${ACTIONS.SUBMIT}_agreement`,
  },
  // order form page
  ORDER_FORM: {
    SUBMIT: `${PAGES.ORDER_FORM}_${ACTIONS.SUBMIT}_${ELEMENTS.FORM}`,
  },
  // batch deliver page
  BATCH_DELIVER: {
    CLICK_DETAIL: `${PAGES.BATCH_DELIVER}_${ACTIONS.CLICK}_${ELEMENTS.DEFAULT}`,
  },
  // history order page
  HISTORY_ORDER: {
    CLICK_DETAIL: `${PAGES.HISTORY_ORDER}_${ACTIONS.CLICK}_${ELEMENTS.DEFAULT}`,
  },
  // order kit page
  ORDER_KIT: {
    SUBMIT: `${PAGES.ORDER_KIT}_${ACTIONS.SUBMIT}_${ELEMENTS.DEFAULT}`,
  },
  // contact operator page
  CONTACT_US: {
    SUBMIT: `${PAGES.CONTACT_US}_${ACTIONS.SUBMIT}_${ELEMENTS.FORM}`,
  },
  // products page
  ORDER_PRODUCTS: {
    CLICK_DETAIL: `${PAGES.ORDER_PRODUCTS}_${ACTIONS.CLICK}_${ELEMENTS.DEFAULT}`,
  },
  // product detail page
  PRODUCT_DETAIL: {
    ORDER: `${PAGES.PRODUCT_DETAIL}_${ACTIONS.ORDER}_${ELEMENTS.DEFAULT}`,
  },
  // inventory register page
  INVENTORY_REGISTER: {
    SUBMIT: `${PAGES.INVENTORY_REGISTER}_${ACTIONS.SUBMIT}_${ELEMENTS.DEFAULT}`,
  },
  // system management page
  SYSTEM_MANAGEMENT: {
    CLICK_SYSTEM_EDIT: `${PAGES.SYSTEM_MANAGEMENT}_${ACTIONS.CLICK}_${PAGES.SALON_EDIT}`,
    CLICK_STAFF_LIST: `${PAGES.SYSTEM_MANAGEMENT}_${ACTIONS.CLICK}_${PAGES.STAFF_LIST}`,
    CLICK_MENU_LIST: `${PAGES.SYSTEM_MANAGEMENT}_${ACTIONS.CLICK}_${PAGES.MENU_LIST}`,
    CLICK_MENU_REGISTER: `${PAGES.SYSTEM_MANAGEMENT}_${ACTIONS.CLICK}_${PAGES.MENU_REGISTER}`,
    CLICK_ACCESS: `${PAGES.SYSTEM_MANAGEMENT}_${ACTIONS.CLICK}_${PAGES.ACCESS}`,
  },
  // salon edit page
  SALON_EDIT: {
    EDIT_SALON: `${PAGES.SALON_EDIT}_${ACTIONS.UPDATE}_${ELEMENTS.FORM}`,
    CLICK_MAP_SETTING: `${PAGES.SALON_EDIT}_${ACTIONS.CLICK}_map-setting`,
    CLICK_MAP_RESET: `${PAGES.SALON_EDIT}_${ACTIONS.CLICK}_map-reset`,
    CHANGE_MAP_LOCATION: `${PAGES.SALON_EDIT}_${ACTIONS.CLICK}_map-change`,
  },
  // staff list page
  STAFF_LIST: {
    CLICK_CREATE_NEW_STAFF: `${PAGES.STAFF_LIST}_${ACTIONS.CLICK}_${ELEMENTS.CREATE}`,
    CLICK_STAFF_DETAIL: `${PAGES.STAFF_LIST}_${ACTIONS.CLICK}_${ELEMENTS.DEFAULT}`,
  },
  // create staff page
  CREATE_STAFF: {
    CREATE: `${PAGES.CREATE_STAFF}_${ACTIONS.CREATE}_${ELEMENTS.FORM}`,
  },
  // staff detail page
  STAFF_DETAIL: {
    DOWNLOAD_CSV: `${PAGES.STAFF_DETAIL}_${ACTIONS.DOWNLOAD}_${ELEMENTS.FORM}`,
    CLICK_ATTENDANCE: `${PAGES.STAFF_DETAIL}_${ACTIONS.CLICK}_attendance`,
    CLICK_SALES: `${PAGES.STAFF_DETAIL}_${ACTIONS.CLICK}_sales`,
    DELETE: `${PAGES.STAFF_DETAIL}_${ACTIONS.DELETE}_${ELEMENTS.DEFAULT}`,
    CLICK_UPDATE: `${PAGES.STAFF_DETAIL}_${ACTIONS.CLICK}_${ELEMENTS.UPDATE}`,
    UPDATE: `${PAGES.STAFF_DETAIL}_${ACTIONS.UPDATE}_${ELEMENTS.DEFAULT}`,
  },
  // menu list page
  MENU_LIST: {
    CLICK_CREATE: `${PAGES.MENU_LIST}_${ACTIONS.CLICK}_${ELEMENTS.CREATE}`,
    CLICK_UPDATE: `${PAGES.MENU_LIST}_${ACTIONS.CLICK}_${ELEMENTS.UPDATE}`,
    DELETE: `${PAGES.MENU_LIST}_${ACTIONS.DELETE}_${ELEMENTS.DEFAULT}`,
  },
  // menu detail page
  MENU_DETAIL: {
    UPDATE: `${PAGES.MENU_DETAIL}_${ACTIONS.UPDATE}_${ELEMENTS.DEFAULT}`,
  },
  // create menu page
  CREATE_MENU: {
    CREATE: `${PAGES.CREATE_MENU}_${ACTIONS.CREATE}_${ELEMENTS.DEFAULT}`,
  },
  // access page
  ACCESS: {
    CLICK_DETAIL: `${PAGES.ACCESS}_${ACTIONS.CLICK}_${ELEMENTS.DEFAULT}`,
    CHANGE: `${PAGES.ACCESS}_${ACTIONS.UPDATE}_${ELEMENTS.DEFAULT}`,
  },
};
