import React, { useCallback, useEffect, useState } from "react";
import { v4 } from "uuid";
import { MenuItem, Tooltip } from "@mui/material";
import { faBars, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CommonPopoverMenu from "app/components/commonUI/CommonPopoverMenu";
import SelectServiceDialog from "app/components/SelectServiceDialog";
import { product_category_and_label } from "app/constants";
import { formatPrice } from "app/utils";
import DefaultLayout from "../../../layouts/DefaultLayout";
import MainContentLayout from "../../../layouts/MainContentLayout";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  getVisitDetailForPayment,
  updateVisitDetail,
} from "../../../services/api";
import LoadingProgress from "../../../components/commonUI/LoadingProgress";
import LoadInitError from "../../../components/commonUI/LoadInitError";

import Path from "app/route/Path";
import ConfirmUpdateDialog from "../../../components/CommonDialog/ConfirmUpdateDialog";

function SelectedServiceItem(props) {
  const { menuItem, handleDelete } = props;
  const [showEditOptionEl, setShowEditOptionEl] = useState(null);
  return (
    <>
      <div className="w-full h-12 rounded-md bg-slate-100 mb-2 pr-12 pl-2 relative">
        <div className="w-full h-full flex">
          <div className="w-5/12 flex items-center justify-start">
            <CategoryItem
              title={menuItem.name}
              onClick={() => {}}
              extraClasses="!h-10"
            />
          </div>
          <div className="w-4/12 flex items-center justify-end">
            <p>{menuItem.durationString}</p>
          </div>
          <div className="w-3/12 flex items-center justify-end">
            <p>{formatPrice(menuItem.priceTaxInc)}</p>
          </div>
        </div>

        <button
          type="button"
          onClick={(e) => setShowEditOptionEl(e.currentTarget)}
          className="absolute top-2 right-2 w-8 h-8 flex items-center justify-center rounded hover:bg-slate-300"
        >
          <FontAwesomeIcon icon={faBars} />
        </button>
      </div>

      <CommonPopoverMenu
        open={!!showEditOptionEl}
        anchorEl={showEditOptionEl}
        onClose={() => setShowEditOptionEl(null)}
      >
        <MenuItem
          onClick={() => {
            handleDelete();
            setShowEditOptionEl(null);
          }}
          disableRipple
        >
          <span className="text-red-500 mr-3">
            <FontAwesomeIcon icon={faTrashCan} />
          </span>
          <span>消去</span>
        </MenuItem>
      </CommonPopoverMenu>
    </>
  );
}

function CategoryItem(props) {
  const { title, onClick, extraClasses } = props;
  return (
    <button
      type="button"
      className={`w-36 h-12 flex items-center justify-center rounded-full bg-[#736966] hover:bg-[#5e5553] overflow-hidden text-base lg:text-lg text-white shrink-0 ${
        extraClasses || ""
      }`}
      onClick={() => onClick && onClick()}
    >
      {title}
    </button>
  );
}

function UpdateVisitMenuPageContent(props) {
  const { initVisitData, visitId } = props;

  const [menuList, setMenuList] = useState(() => {
    return Array.isArray(initVisitData.visitMenus)
      ? initVisitData.visitMenus.map((item) => ({ ...item, tempId: v4() }))
      : [];
  });
  const [showSelectMenu, setShowSelectMenu] = useState("");
  const [showConfirmUpdate, setShowConfirmUpdate] = useState(false);

  let [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleSelectService = (serviceInfo) => {
    setMenuList((oldList) => [
      ...oldList,
      { ...serviceInfo, salonMenuId: serviceInfo.id, tempId: v4() },
    ]);
  };
  const handleDeleteItem = (serviceInfo) => {
    setMenuList((oldList) =>
      oldList.filter((item) => item.tempId !== serviceInfo.tempId)
    );
  };

  const handleBack = () => {
    if (searchParams.get("backTo")) navigate(searchParams.get("backTo"));
    else navigate(Path.duringService(visitId));
  };

  const handleNext = () => {
    setShowConfirmUpdate(true);
  };

  const handleUpdateVisitMenu = async () => {
    const data = {
      discounts: Array.isArray(initVisitData.discounts)
        ? initVisitData.discounts.map((item) => ({
            discountType: item.discountType,
            discountAmount: item.discountAmount,
          }))
        : [],
      sales: Array.isArray(initVisitData.sales)
        ? initVisitData.sales.map((item) => ({
            amount: item.amount,
            productId: item.id,
          }))
        : [],
      salonMenuIds: menuList.map((item) => item.salonMenuId),
    };

    return updateVisitDetail(visitId, data);
  };

  return (
    <>
      <div className="w-full h-full pt-16 lg:pt-0 pb-8 relative">
        <div className="w-full h-full py-3">
          <div className="w-full h-full md:flex overflow-auto">
            <div className="w-full h-auto py-4 md:py-0 md:w-5/12 md:h-full md:pr-4">
              <div className="w-full h-full flex flex-col items-center justify-center gap-6">
                <h3 className="text-lg font-semibold text-center">
                  予約するメニューを選んでください
                </h3>

                <div className="flex flex-col items-center justify-center gap-6">
                  {product_category_and_label.map((category) => (
                    <CategoryItem
                      key={category.key}
                      title={category.label}
                      onClick={() => {
                        setShowSelectMenu(category.key);
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="w-full h-auto md:w-7/12 md:h-full md:overflow-auto">
              <div className="w-full h-full min-h-[200px] border border-gray-400 rounded-md px-2 pt-4 pb-16 relative">
                <div className="w-full h-full overflow-auto">
                  <div className="w-full">
                    {menuList.map((item) => (
                      <SelectedServiceItem
                        menuItem={item}
                        handleDelete={() => handleDeleteItem(item)}
                      />
                    ))}
                  </div>
                </div>

                <div className="w-full h-14 absolute inset-x-0 bottom-0 px-2">
                  <div className="w-full h-full border-t-2 border-gray-400">
                    <div className="w-full h-12 rounded-md mb-2 pr-12 pl-2 font-semibold">
                      <div className="w-full h-full flex">
                        <div className="w-5/12 flex items-center justify-end">
                          合計
                        </div>
                        <div className="w-1/3 flex items-center justify-end">
                          <p>
                            {menuList.reduce((a, b) => a + b.durationNumber, 0)}{" "}
                            分
                          </p>
                        </div>
                        <div className="w-1/4 flex items-center justify-end">
                          <p>
                            {formatPrice(
                              menuList.reduce((a, b) => a + b.priceTaxInc, 0)
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
          <button
            className="button-size bg-gray-btn-secondary mr-4"
            onClick={handleBack}
          >
            戻る
          </button>

          <Tooltip
            title={menuList.length > 0 ? "" : "サービスを選択してください"}
          >
            <button
              className={`button-size rounded ${
                menuList.length > 0
                  ? "bg-blue-btn-primary"
                  : "bg-gray-btn-secondary cursor-default"
              }  `}
              onClick={handleNext}
            >
              次へ
            </button>
          </Tooltip>
        </div>
      </div>

      <SelectServiceDialog
        menuType={showSelectMenu}
        handleClose={() => setShowSelectMenu("")}
        handleSelectService={handleSelectService}
      />

      <ConfirmUpdateDialog
        open={showConfirmUpdate}
        handleClose={() => setShowConfirmUpdate(false)}
        confirmTitle="勤務時間を変更したいですか?"
        confirmMessage=""
        updateCompleteTitle="勤怠時間を変更しました"
        updateCompleteBtnLabel="終了"
        updateCompleteAction={() => {
          handleBack();
        }}
        updateAction={handleUpdateVisitMenu}
        confirmCancleLabel="キャンセル"
        confirmLabel="変化"
      />
    </>
  );
}

function UpdateVisitMenuPage() {
  const [fetchVisitInfoState, setFetchVisitInfoState] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const { id } = useParams();

  const loadToDayVisit = useCallback(() => {
    if (!id) return;
    setFetchVisitInfoState({
      isLoading: true,
      data: null,
      error: null,
    });

    getVisitDetailForPayment(id)
      .then((res) => {
        setFetchVisitInfoState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchVisitInfoState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [id]);

  useEffect(() => {
    loadToDayVisit();
  }, [loadToDayVisit]);

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>お客様の</p>
            <p>お帰り</p>
          </>
        }
      >
        <div className="w-full h-full mx-auto relative max-h-full flex flex-wrap lg:py-12 pt-16 pb-8">
          <div className="w-full max-h-full p-4">
            <div className="w-full h-full overflow-auto">
              {fetchVisitInfoState.isLoading ? <LoadingProgress /> : null}

              {!fetchVisitInfoState.isLoading && fetchVisitInfoState.error ? (
                <LoadInitError error={fetchVisitInfoState.error} />
              ) : null}

              {!fetchVisitInfoState.isLoading &&
              !fetchVisitInfoState.error &&
              fetchVisitInfoState.data &&
              fetchVisitInfoState.data.payment ? (
                <div className="w-full h-full flex flex-col items-center justify-center">
                  <h3 className="text-lg font-semibold mb-6">
                    Visit has been paid
                  </h3>
                  <Link
                    to={Path.home}
                    className="button-size bg-blue-btn-primary text-white"
                  >
                    Back to Home
                  </Link>
                </div>
              ) : null}

              {!fetchVisitInfoState.isLoading &&
              !fetchVisitInfoState.error &&
              fetchVisitInfoState.data &&
              !fetchVisitInfoState.data.payment ? (
                <UpdateVisitMenuPageContent
                  initVisitData={fetchVisitInfoState.data}
                  visitId={id}
                />
              ) : null}
            </div>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default UpdateVisitMenuPage;
